var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var PropostaFilter = /** @class */ (function (_super) {
    __extends(PropostaFilter, _super);
    function PropostaFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.proposta = null;
        _this.tipusproposta = '-1';
        _this.expediente = '';
        _this.matricula = '';
        _this.agente = '';
        _this.dni = '';
        _this.dniSearchType = '';
        _this.infraccion = '';
        _this.numeroDenuncia = '';
        _this.carrer = '';
        _this.nombreConductor = '';
        _this.nombreSearchType = '';
        _this.esJuridica = null;
        _this.fechaInfraccionInicio = null;
        _this.fechaInfraccionFin = null;
        _this.pais = null;
        _this.aparell = null;
        _this.labels['proposta'] = _this.i18n('Proposta');
        _this.labels['tipusproposta'] = _this.i18n('Estat');
        _this.labels['expediente'] = _this.i18n('Expedient');
        _this.labels['matricula'] = _this.i18n('Matrícula');
        _this.labels['agente'] = _this.i18n('Agent');
        _this.labels['dni'] = _this.i18n('DNI');
        _this.labels['dniSearchType'] = _this.i18n('DNI Tipus');
        _this.labels['numeroDenuncia'] = _this.i18n('Número Denuncia');
        _this.labels['codigoAnulacion'] = _this.i18n('Codi Anul·lació');
        _this.labels['carrer'] = _this.i18n('Carrer');
        _this.labels['nombreConductor'] = _this.i18n('Conductor');
        _this.labels['nombreSearchType'] = _this.i18n('Tipus Cerca');
        _this.labels['esJuridica'] = _this.i18n('És Jurídica');
        _this.labels['fechaInfraccionInicio'] = _this.i18n('Data Infracció Inicial');
        _this.labels['fechaInfraccionFin'] = _this.i18n('Data Infracció Final');
        _this.labels['pais'] = _this.i18n('País');
        _this.labels['aparell'] = _this.i18n('Aparell');
        _this.formatOutput['fechaInfraccionInicio'] = 'shortDate';
        _this.formatOutput['fechaInfraccionFin'] = 'shortDate';
        return _this;
    }
    return PropostaFilter;
}(FilterData));
export { PropostaFilter };
