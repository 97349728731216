<app-dialog-header label="NotificacionesDenuncia" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Filtre" (click)="onFilterClick()" i18n-text color="has-color-orange" icon="fa-filter"></app-tool-button>
  <app-tool-button text="Continuar" (click)="continue()" i18n-text color="has-color-blue" icon="fa-arrow-right"></app-tool-button>
</app-toolbar>
<app-dialog-body>

<div class="row justify-content-between" >
  <div class="col">
    <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="this.filter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
</div>
<div class="table-sdw is-fixed">
  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">
    
    <ng-container matColumnDef="EXP">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Element</mat-header-cell>
      <mat-cell *matCellDef="let row"><a class="is-link cursor detalle">{{row.element}}</a></mat-cell>
    </ng-container>
    <ng-container matColumnDef="nom">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Nom</mat-header-cell>
      <mat-cell *matCellDef="let row"><p>{{row.nom}}</p></mat-cell>
    </ng-container>
    <ng-container matColumnDef="direccio">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Direccio</mat-header-cell>
      <mat-cell *matCellDef="let row"><p>{{row.direccio}}</p></mat-cell>
    </ng-container>

    <ng-container matColumnDef="poblacio">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Poblacio</mat-header-cell>
      <mat-cell *matCellDef="let row"><p>{{row.poblacio}}</p></mat-cell>
    </ng-container>

    <ng-container matColumnDef="cp">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>CP</mat-header-cell>
      <mat-cell *matCellDef="let row"><p>{{row.cp}}</p></mat-cell>
    </ng-container>

    <ng-container matColumnDef="provincia">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Provincia</mat-header-cell>
      <mat-cell *matCellDef="let row"><p>{{row.provincia}}</p></mat-cell>
    </ng-container>

    <ng-container matColumnDef="data">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.data | longDate}}</mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset"></mat-row>
  </mat-table>
</div>