<!-- <form [formGroup]="PlanificacioForm" (ngSubmit)="this.submit()"> -->
  <form #mainForm>
  <app-form-date-edit class="col-sm-4" i18n-label label="Fecha" type="calendar" [(value)]="this.SelectedDate"></app-form-date-edit>
  <app-form-combo-edit [items]="this.Rutes" i18n-label label="Ruta" valueField="SomId" textField="Descripcion" [(value)]="RutaSelected"></app-form-combo-edit>
  <app-form-combo-edit [items]="this.Horaris" i18n-label label="Horari"  valueField="SomId" textField="Descripcion"  [(value)]="HorariSelected"></app-form-combo-edit>
  <app-form-multi-select titulo="Agents" tituloMultiSelect="Selecciona una o més" [data]="Agents" [(selectedValues)]="this.SelectedAgents"></app-form-multi-select>
  <button type="submit" mat-button color="primary" (click)="save()">Guardar</button>
</form>

  <!-- <div style="display: flex; justify-content: center;">
    <button type='submit' mat-button color="primary">
      <mat-icon>save</mat-icon>
    </button>
  </div> -->
<!-- </form> -->