import { PropostaGruaDto } from './../../Models/proposta-grua.model';
import { PendentsComponent } from 'app/sections/grua/components/pendents/pendents.component';
import { VehicleDipositDto } from './../../Models/diposit-vehicle.model';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Dictionary } from 'path-shared/models/dictionary';
import { ComboBoxInputAutocompleteComponent } from 'path-shared/components/form/combo-box-input-autocomplete/combo-box-input-autocomplete.component';
import { DipositGruaService } from '../../services/diposit-grua-service';
import { VehiclesService } from 'app/sections/ciutat/vehicles/services/vehicles.service';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { GruaPendentsService } from '../../services/grua-pendents.service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { MatDialog } from '@angular/material';
import { VehiclesEditorComponent } from 'app/sections/ciutat/vehicles/components/vehicles-editor/vehicles-editor.component';
import { VehiclesComponent } from 'app/sections/ciutat/vehicles/pages/vehicles/vehicles.component';
import { TramitadesComponent } from '../tramitades/tramitades.component';
import { BehaviorSubject } from 'rxjs';
import { SOM_C60 } from 'path-models/SOM_C60';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-diposit-editor-general',
  templateUrl: './diposit-editor-general.component.html',
  styleUrls: ['./diposit-editor-general.component.scss']
})
export class DipositEditorGeneralComponent implements OnInit {
  val: VehicleDipositDto;
  refsC60: SOM_C60[];
  cobrarDesctivat: boolean = true;
  report;

  dictTipusCobro = [];
  valorAutoCompleteTipusCobro;
  @ViewChild('comboTipusCobro') comboTipusCobro: ComboBoxInputAutocompleteComponent;

  dictFormaPagament = [];
  valorAutoCompleteFormaPagament;
  @ViewChild('comboFormaPagament') comboFormaPagament: ComboBoxInputAutocompleteComponent;

  tipoVehiculoDictionary: Dictionary[];
  valorAutoCompleteTipoVehiculoDictionary;
  @ViewChild('comboTipoVehiculo') comboTipoVehiculo: ComboBoxInputAutocompleteComponent;

  motisEntradaDictionary: Dictionary[];
  valorAutoCompleteMotiuEntradaDictionary;
  @ViewChild('comboMotiuEntrada') comboMotiuEntrada: ComboBoxInputAutocompleteComponent;

  dipositsDictionary: Dictionary[];
  valorAutoCompleteDipositsDictionary;
  @ViewChild('comboDiposits') comboDiposits: ComboBoxInputAutocompleteComponent;

  tecnicsDictionary: Dictionary[];
  valorAutoCompleteTecnic;
  tecnicsList = [];
  @ViewChild('comboTecnics') comboTecnics: ComboBoxInputAutocompleteComponent;


  @Input() get value() {
    return this.val;
  } set value(val) {
    this.val = val;
    setTimeout(() => {
      this.valueChange.emit(this.val);
    }, 10);
  }
  @Output() valueChange = new EventEmitter();
  constructor(private i18n: I18n,
            private dipositService: DipositGruaService,
            private vehiculoService: VehiclesService,
            private confirmationDialogService: ConfirmationDialogService,
            private toastr: ToastrService,
            private dialog: MatDialog,
            private gruaSerevice: GruaPendentsService,
            public auth: AuthorizationService,) {
    this.valorAutoCompleteTipusCobro = new BehaviorSubject('');
    this.valorAutoCompleteFormaPagament = new BehaviorSubject('');
    this.valorAutoCompleteTipoVehiculoDictionary = new BehaviorSubject('');
    this.valorAutoCompleteMotiuEntradaDictionary = new BehaviorSubject('');
    this.valorAutoCompleteDipositsDictionary = new BehaviorSubject('');
    this.valorAutoCompleteTecnic = new BehaviorSubject('');

    this.dipositService.getReports(this.auth.Pages.DipositGrua).subscribe(res => {
      this.report = res.filter(x => x.nom == 'DIPOSITVEHICLE_CARTA_PAGAMENT_CASTELLDEFELS')[0];
    });
  }

  ngOnInit() {

    this.createDicTipusCobro();
    this.createDicFormaPagament();
    this.getTiposVehiculos();
    this.getMotivosEntrada();
    this.getDiposits();
    this.loadTecnics();
    this.refsC60 = this.value.somC60;
  }
  getDiposits() {
    this.dipositsDictionary = new Array<Dictionary>();
    this.dipositService.getDipositsCombo().subscribe(x => {
      x.forEach(element => {
        this.dipositsDictionary.push(new Dictionary(element.idDiposit.toString(), element.carrer));
      });
      this.setDefalutDiposit();
    });
  }

  getMotivosEntrada() {
    this.motisEntradaDictionary = new Array<Dictionary>();
    this.dipositService.getAllMotiusEntradaGrua().subscribe(x => {
      x.forEach(element => {
        this.motisEntradaDictionary.push(new Dictionary(element.somId.toString(), element.descripcio));
      });
      this.setDefalutValuesCombosMotivosEntrada();
    });
  }
  getTiposVehiculos() {
    this.tipoVehiculoDictionary = new Array<Dictionary>();
    this.vehiculoService.getAllTipusVehciles().subscribe(x => {
      x.forEach(element => {
        this.tipoVehiculoDictionary.push(new Dictionary(element.id.toString(), element.descripcio));
      });
      this.setDefalutValuesCombosTipusVehiculo();
    });
  }

  createDicTipusCobro() {
    this.dictTipusCobro = new Array();
    // this.dictTipusCobro.push(new Dictionary('1',  this.i18n('A cobrar tot')));
    // this.dictTipusCobro.push(new Dictionary('2',  this.i18n('Només enganxament')));
    // this.dictTipusCobro.push(new Dictionary('3',  this.i18n('Només custòdia')));
    // this.dictTipusCobro.push(new Dictionary('4',  this.i18n('Sense càrrec')));
    this.dipositService.getAllTiposCobro().subscribe(x => {
      x.forEach(element => {
        this.dictTipusCobro.push({'id': element.idTipoPago.toString(), 'descripcio': element.descripcion});
      });
      this.setDefalutValuesCombosTipusCobro();
    });
  }
  // tipusCobroSeleccionado(idTipusCobro: string) {
  //   const tipusSelected = this.dictTipusCobro.find(x => x.key === idTipusCobro);
  //   this.value.tipusCalcul =
  //   this.value.idTipoPago = tipusSelected.value;
  //   return this.value.idTipoPago = tipusSelected.key;
  // }
  tipoVehiculoSeleccionado(idTipoVehiculo: string) {
    const tipusSelected = this.tipoVehiculoDictionary.find(x => x.key === idTipoVehiculo);
    this.value.idtipusvehicleFK = tipusSelected.key;
    this.value.txtTipusVehicle = tipusSelected.value;
  }
  // formaPagamentSeleccionado(idFormaPagament: string) {
  //   const tipusSelected = this.dictFormaPagament.find(x => x.key === idFormaPagament);
  //   return this.value.formapagament = tipusSelected.value;
  // }

  motiuEntradaSeleccionado(idMotivoEntrada: string) {
    const motivoEntradaSelected = this.motisEntradaDictionary.find(x => x.key === idMotivoEntrada);
    this.value.idMotiuEntradaFK = motivoEntradaSelected.key;
  }

  dipositSeleccionado(idDiposit: string) {
    const dipositSelected = this.dipositsDictionary.find(x => x.key === idDiposit);
    this.value.idDipositFK = dipositSelected.key;
  }
  setDefalutValuesCombosTipusVehiculo() {
    if (this.value.idtipusvehicleFK !== undefined && this.value.idtipusvehicleFK !== null && this.value.idtipusvehicleFK !== ''
    && this.tipoVehiculoDictionary.length > 0 ) {
     const tipusVehicleSelcted = this.tipoVehiculoDictionary.find(x => x.key === this.value.idtipusvehicleFK.toString());
     this.comboTipoVehiculo.setForcedValueComb(tipusVehicleSelcted.value);
   }
  }
  setDefalutValuesCombosMotivosEntrada() {
    if (this.value.idMotiuEntradaFK !== undefined && this.value.idMotiuEntradaFK !== null && this.motisEntradaDictionary.length > 0 ) {
     const motiEntradaSelected = this.motisEntradaDictionary.find(x => x.key === this.value.idMotiuEntradaFK.toString());
     this.comboMotiuEntrada.setForcedValueComb(motiEntradaSelected.value);
   }
  }
  setDefalutValuesCombosTipusCobro() {
    if ( this.value.idTipoPago !== undefined && this.value.idTipoPago !== null && this.dictTipusCobro.length > 0 ) {
      const tipusCalculSelcted = this.dictTipusCobro.find(x => x.key === this.value.idTipoPago.toString());
      this.comboTipusCobro.setForcedValueComb(tipusCalculSelcted.value);
    }
  }

  setDefalutDiposit() {
    if ( this.value.idDipositFK !== undefined && this.value.idDipositFK !== null && this.value.idDipositFK !== ''
     && this.dipositsDictionary.length > 0 ) {
      const dipositSelcted = this.dipositsDictionary.find(x => x.key === this.value.idDipositFK.toString());
      this.comboDiposits.setForcedValueComb(dipositSelcted.value);
    }
  }

  createDicFormaPagament() {
    this.dictFormaPagament = new Array();
    this.dictFormaPagament.push({'id': '10', 'descripcio': this.i18n('Efectiu')});
    this.dictFormaPagament.push({'id': '20',  'descripcio': this.i18n('Targeta')});
    this.dictFormaPagament.push({'id': '40',  'descripcio': this.i18n('Carta de pago')});
    this.setDefalutValuesCombosFormaPagament();
  }


  setDefalutValuesCombosFormaPagament() {
    if ( this.value.formapagament !== undefined && this.value.formapagament !== null && this.value.formapagament !== ''
     && this.dictFormaPagament.length > 0 ) {
      const formaPagamentSelected = this.dictFormaPagament.find(x => x.value === this.value.formapagament);
      if (formaPagamentSelected !== undefined && formaPagamentSelected !== null) {
        this.comboFormaPagament.setForcedValueComb(formaPagamentSelected.key);
      }
    }
  }

  loadTecnics() {
    this.tecnicsDictionary = new Array<Dictionary>();
    this.gruaSerevice.getAllTecnicsCombo().subscribe( x => {
      this.tecnicsList = x;
      x.forEach(element => {
        this.tecnicsDictionary.push(new Dictionary(element.somId.toString(), element.somId.toString()));
      });
      this.setDefalutValuesCombosTecnic();
    });
  }

  tecnicSeleccionado(secureIdTecnic: string) {
    // const tecnicSelected = this.tecnicsList.find(x => x.somId === secureIdTecnic);
    this.value.tecnicGrua = secureIdTecnic;
  }

  setDefalutValuesCombosTecnic() {
    // agent
    if (this.value.tecnicGrua !== undefined && this.value.tecnicGrua !== null && this.value.tecnicGrua !== ''
     && this.tecnicsDictionary.length > 0 ) {
      this.comboTecnics.setForcedValueComb(this.value.tecnicGrua.toString());
    }
  }
  calcular() {
    if (this.value.importCobrat != null) {
      this.confirmationDialogService.confirm(this.i18n('Confirmi'),
      this.i18n('Si executa aquest càlcul es perdran les dades del cobrament generat el dia '
      + formatDate(this.value.dataCalcul, 'yyyy-MM-dd', 'es_ES')
       +' a les ' + this.value.horaCalcul +
      ', està segur que vol continuar?'))
      .then((confirmed) => {
        if (confirmed) {
          this.setTxtTipusVehiclePreventSave();
          this.dipositService.calculatePrice(this.value).subscribe(x => {
            if (x !== undefined && x !== null) {
              this.value = x;
            }
          });
          this.cobrarDesctivat = false;
          this.refsC60 = this.value.somC60;
          this.generateReportCastelldefels();
        }
      });
    }
    else {
      this.setTxtTipusVehiclePreventSave();
        this.dipositService.calculatePrice(this.value).subscribe(x => {
          if (x !== undefined && x !== null) {
            this.value = x;
          }
        });
        this.cobrarDesctivat = false;
        this.refsC60 = this.value.somC60;
        this.generateReportCastelldefels();
    }
    
  }
  
  generateReportCastelldefels() {
    if (this.value.formapagament == '40') {
      this.dipositService.generateReport(this.value.secureId, this.report.id).subscribe(file => {
        const name = this.report.nom;
        const contentType = file.headers.get('Content-Type');
        if (contentType !== null && contentType !== undefined) {
            FileDownloader.downLoadFile([file.body],  contentType + ';charset=utf-8', name.toString());
        } else {
          this.toastr.error(this.i18n('No s\'ha pogut generar el fitxer'));
        }
        // if (name === 'DIPOSITVEHICLE_CARTA_PAGAMENT_CASTELLDEFELS') {
        //   this.update.next(true);
        // }
      });
    }
  }

  isCalcularButtonDisabled() {
    return this.value.idtipusvehicleFK === undefined ||
      this.value.idtipusvehicleFK === '' ||
      this.value.idTipoPago === undefined ||
      this.value.idTipoPago === null||
      (this.value.numFactura !== undefined &&
        this.value.numFactura !== null &&
        this.value.numFactura !== '')
  }

  cobrarDesactivat() {
    if (this.value.formapagament == '40' && this.value.importCobrat !== null && this.value.importCobrat !== undefined)
      this.cobrarDesctivat = false
    if (this.cobrarDesctivat) return true;
    else if (this.value !== undefined && this.value !== null && this.value.titularnom !== undefined
      && this.value.titularnom !== null  && this.value.titularnom !== ''
      && this.value.titularnif !== undefined && this.value.titularnif !== null
      && this.value.titularnif !== ''
      && this.value.titulardireccio !== undefined && this.value.titulardireccio !== null
      && this.value.titulardireccio !== ''
      && this.value.titularpoblacio !== undefined && this.value.titularpoblacio !== null
      && this.value.titularpoblacio !== ''
      && this.value.titularCP !== undefined && this.value.titularCP !== null
      && this.value.titularCP !== '') {
        return false;
    }
    else return true;
  }

  cobrar() {
    this.dipositService.cobrarDepositoVehiculo(this.value).subscribe(x => {
      if (x !== undefined && x !== null) {
        this.toastr.success(this.i18n('COBRAMENT FET!'));
        this.value = x;
      }
    });
  }


  seleccionarVehicle() {
    const data: ComponentDialog = Builder.newComponentDialog(true);
    const ref = this.dialog.open(VehiclesComponent, {
      width: '80%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });

    ref.afterClosed().subscribe(res => {
      if (res.matricula !== undefined && res.matricula !== null && res.matricula !== '') {
        // this.denunciaDetail.secure_id_vehicle = res.secure_id;
        this.value.idVehicleFk = res.matricula;
      }
    });
  }

  seleccionarProposta() {
    const data: ComponentDialog = Builder.newComponentDialog(true);
    const ref = this.dialog.open(TramitadesComponent, {
      width: '80%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });

    ref.afterClosed().subscribe(res => {
      if (res !== undefined && res !== null) {
        const prop: PropostaGruaDto = res;
      this.value.iddenuncia = prop.codi;
      this.value.idVehicleFk = prop.idVehiclesFK;
      if ( prop.nombreCalle !== undefined && prop.nombreCalle !== null ) {
      this.value.llocRetirada = prop.nombreCalle;
      } else {
        this.value.llocRetirada = ' ';
      }
      if ( prop.numCarrer !== undefined && prop.numCarrer !== null ) {
        this.value.llocRetirada = this.value.llocRetirada + prop.numCarrer;
      }
      if ( prop.idTecnicFK !== undefined && prop.idTecnicFK !== null ) {
        this.value.tecnicGrua = prop.idTecnicFK;
      }
      if ( prop.sinCargo !== undefined && prop.sinCargo !== null ) {
        this.value.sinCargo = prop.sinCargo;
      }
      this.comboTecnics.setForcedValueComb(this.value.tecnicGrua.toString());

      if (prop.vehicle !== undefined && prop.vehicle !== null
        && prop.vehicle.tipus_vehicle !== undefined && prop.vehicle.tipus_vehicle !== null
        && prop.vehicle.tipus_vehicle !== '') {
        this.value.idtipusvehicleFK = prop.vehicle.tipus_vehicle;
        const tipusVehicleSelcted = this.tipoVehiculoDictionary.find(x => x.key === this.value.idtipusvehicleFK.toString());
        if (tipusVehicleSelcted !== undefined && tipusVehicleSelcted !== null ) {
          this.comboTipoVehiculo.setForcedValueComb(tipusVehicleSelcted.value);
          this.value.txtTipusVehicle = tipusVehicleSelcted.value;
        }

      }

      // volen que seleccionem el primer diposit quan es carrega una proposta...
      if ((this.value.idDipositFK === undefined || this.value.idDipositFK === null || this.value.idDipositFK === '')
      && this.dipositsDictionary !== undefined &&  this.dipositsDictionary !== null && this.dipositsDictionary.length > 0 ) {
        const dipositSelcted =  this.dipositsDictionary[0];
        this.value.idDipositFK = dipositSelcted.key;
        this.comboDiposits.setForcedValueComb(dipositSelcted.value);
      }


      // this.value.idtipusvehicleFK = prop
      }
    });
  }

  consultarVehicle() {
    if ( this.value.idVehicleFk !== undefined && this.value.idVehicleFk !== null && this.value.idVehicleFk !== '') {
      this.vehiculoService.checkExistByMatricula(this.value.idVehicleFk).subscribe(x => {
        if (x === undefined || x === null || x === false) {
          this.toastr.warning(this.i18n('ATENCIÓ: Vehicle no existent, primer s\' ha de donar d\'alta o seleccionar un existent!'));
          this.value.idVehicleFk = '';
          this.seleccionarVehicle();
        }
      });
    }
  }
setTxtTipusVehiclePreventSave() {
  if (this.value.idtipusvehicleFK !== undefined && this.value !== null
    && this.value.idtipusvehicleFK !== undefined && this.value.idtipusvehicleFK !== null
    && this.value.idtipusvehicleFK !== '') {
    const tipusVehicleSelcted = this.tipoVehiculoDictionary.find(x => x.key === this.value.idtipusvehicleFK.toString());
    if (tipusVehicleSelcted !== undefined && tipusVehicleSelcted !== null ) {
      this.value.txtTipusVehicle = tipusVehicleSelcted.value;
    }
  }
}
  getCanvi(): string {
    let canvi = '0';
    let entregat = 0;
     if (typeof this.value.importEntregat === 'string') {
       const importEntregat: string = this.value.importEntregat;
        entregat = Number( importEntregat.replace(',', '.'));
     } else {
      entregat = this.value.importEntregat;
     }
    if ( this.value !== undefined && this.value !== null &&
      this.value.importcalculat !== undefined && this.value.importcalculat !== null && this.value.importcalculat > 0  &&
      entregat !== undefined && entregat !== null && entregat > 0 ) {
        // let entregat: number = Number(this.value.importEntregat.toFixed(2));
        // let calculat: number = Number(this.value.importcalculat.toFixed(2));

        const canviNumber = (entregat - this.value.importcalculat);
        canvi = canviNumber.toFixed(2);
    }
    return canvi;
  }
}
