<app-toolbar>
  <!--div to fix margin of app-toolbar-->
  <div style="padding-top: 25px; padding-left: 25px;">
    <app-tool-button text="Nou" i18n-text color="has-color-green" icon="fa-plus-square" (click)="create()" ></app-tool-button>    
    <app-tool-button text="Actualizar" i18n-text color="has-color-blue" icon="fa-sync" (click)="refresh()"></app-tool-button>  
  </div>
</app-toolbar>



<app-zb-horarios-table></app-zb-horarios-table>