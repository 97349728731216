import { Router } from '@angular/router';
import { Component, OnInit, EventEmitter, Output, Inject, OnDestroy, ViewChild } from '@angular/core';
import { DenunciaFilter } from '../../models/denuncia.filter';
import { CustomListFill } from 'path-shared/models/customlistfill';
import { DenunciesService } from '../../services/denuncies.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LegislacioService } from 'app/sections/configuracio/legislacions/services/legislacions-service.service';
import { AgentsService } from 'app/sections/configuracio/agents/services/agents-service.service';
import { CarrersService } from 'app/sections/configuracio/carrers/services/carrers-service.service';
import { ZonesService } from 'app/sections/ciutat/zones/services/zones.service';
import { InfraccioService } from 'app/sections/configuracio/Infraccions/services/infraccions-service.service';
import { MotiusAnulacioService } from 'app/sections/configuracio/motius-anulacio/services/motius-anulacio.service';
import { Observable, Subscription } from 'rxjs';
import { LoaderService } from 'path-shared/components/loader/loader.service';
import { FormComboEditComponent } from 'path-shared/components/form/form-combo-edit/form-combo-edit.component';
import { StartupService } from 'path-shared/services/startup/startup.service';
import { AparellService } from 'app/sections/configuracio/aparells/services/aparells-service.service';


@Component({
  selector: 'app-denuncies-filter',
  templateUrl: './denuncies-filter.component.html'
})
export class DenunciesFilterComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  filter: DenunciaFilter;

  tipusLegislacions = [];
  agents = [];
  carrers = [];
  zones = [];
  articles = [];
  motius = [];
  operacions = [];
  usuarisOperacions = [];
  usuariAnulArray = [];
  tipusAnulArray = [];
  motiuAnulArray = [];
  aparatos = [];
  estatsGrua= [];
  simpleBooleanHelperJuridica: string[] = ['', 'SI', 'NO'];
  likeBooleanHelperDNI: String[] = ['Like', '='];
  likeBooleanHelperNombre: String[] = ['Like', '='];
  simpleBooleanHelperSCT: string[] = ['', 'SI', 'NO'];
  simpleBooleanHelperJPT: string[] = ['', 'SI', 'NO'];
  simpleBooleanHelperTancat: string[] = ['', 'SI', 'NO'];
  simpleBooleanHelperNotificada: string[] = ['', 'SI', 'NO'];
  simpleBooleanHelperJudicial: string[] = ['', 'SI', 'NO'];
  leyTextListHelper: string[] = ['LSV', 'OM', 'RGC'];
  articuloTextListHelper: CustomListFill;
  origens = [];
  simpleBooleanHelperCerrado: string[] = ['SI', 'NO', ''];
  habilitado = false;
  loadedCombos = 0;
  @Output() notify: EventEmitter<DenunciaFilter> = new EventEmitter<DenunciaFilter>();
  @ViewChild('comboOrigen') comboOrigen: FormComboEditComponent<any>;
  constructor(
    public thisDialogRef: MatDialogRef<DenunciesFilterComponent>,
    private router: Router,
    private legislacioService: LegislacioService,
    private agentsService: AgentsService,
    private carrersService: CarrersService,
    private zonesService: ZonesService,
    private infraccionsService: InfraccioService,
    private motiusService: MotiusAnulacioService,
    private denunciesService: DenunciesService,
    private loaderService: LoaderService,
    private startupService: StartupService,
    private aparellsService: AparellService,
    @Inject(MAT_DIALOG_DATA) public data: DenunciaFilter,
    ) {
    this.filter = data;
    this.articuloTextListHelper = new CustomListFill(['']);

   }

   selected ;
  ngOnInit() {
    this.loaderService.setBackgroundMode(true);
    this.selected = '';
    // Carreguem les legisalcions per a passar-les al dialog
    this.legislacioService.getAllForCombo().subscribe(result => {
      this.tipusLegislacions = [];
      if (result !== null) {
        let i: number;
        i = 0;
        result.forEach(element => {
          this.tipusLegislacions[i] = element;
          i++;
        });
      }
      this.tipusLegislacions.unshift({'codi': '', 'descripcio': ''});
      this.incrementLoadedCombos();
    });
    this.denunciesService.getEstasGruaForCombo().subscribe(result => {
      this.estatsGrua = [];
      if (result !== null) {
        let i: number;
        i = 0;
        result.forEach(element => {
          this.estatsGrua[i] = {'codi': element, 'descripcio': element}
          i++;
        });
      }
      this.estatsGrua.unshift({'codi': '', 'descripcio': ''});
      this.incrementLoadedCombos();
    });

    // Carreguem els agents per a passar-les al dialog
    this.agentsService.getAllCombo().subscribe(result => {
      this.agents = [];
      if (result !== null) {
        let i: number;
        i = 0;
        result.forEach(element => {
          this.agents[i] = element;
          i++;
        });
      }
      this.agents.unshift({'codi': ''});
      this.incrementLoadedCombos();

    });

    // Carreguem els carrers per a passar-les al dialog
    this.loadCalles();

    // Carreguem les zones per a passar-les al dialog
    this.zonesService.getZones().subscribe(result => {
      this.zones = [];
      if (result !== null) {
        let i: number;
        i = 0;
        result.forEach(element => {
          this.zones[i] = element;
          i++;
        });
      }
      this.zones.unshift({'descripcio': ''});
      this.incrementLoadedCombos();

    });

    // Carreguem els articles per a passar-les al dialog
    this.LoadInfraccionsDenunciesCombo(true);

    // Carreguem els motius per a passar-les al dialog
    this.motiusService.getAllForCombo().subscribe(result => {
      this.motius = [];
      if (result !== null) {
        let i: number;
        i = 0;
        result.forEach(element => {
          const newElement = {element, cheked : false};
          this.motius[i] = newElement;
          i++;
        });
      }
      this.incrementLoadedCombos();
    });

    // Carreguem els usuarisOperacions per a passar-les al dialog
    this.denunciesService.getUsuarisOperacionsForCombo().subscribe(result => {
      this.usuarisOperacions = [];
      if (result !== null) {
        let i: number;
        i = 0;
        result.forEach(element => {
          const newElement = {element, cheked : false};
          this.usuarisOperacions[i] = newElement;
          i++;
        });
      }
      this.incrementLoadedCombos();
    });

    // Carreguem les operacions per a passar-les al dialog
    this.denunciesService.getOperacionsForCombo().subscribe(result => {
      this.operacions = [];
      if (result !== null) {
        let i: number;
        i = 0;
        result.forEach(element => {
          const newElement = {element, cheked : false};
          this.operacions[i] = newElement;
          i++;
        });
      }
      this.incrementLoadedCombos();
    });

    // Carreguem els aparells per a passar-les al dialog
    this.aparellsService.getAllCombo().subscribe(result => {
      this.aparatos = result;
      this.incrementLoadedCombos();
      });


    const denunciesOrigensList = this.startupService.GetDenunciesOrigensForCombo();
    if (denunciesOrigensList !== null) {
      denunciesOrigensList.forEach(result => {
        this.origens = Object.assign([], result);
        this.origens.unshift({'codi': '', 'descripcio': ''});
        this.incrementLoadedCombos();
      });
    }



    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }

  incrementLoadedCombos() {
    this.loadedCombos++;
    if (this.loadedCombos === 10) {  // si añadimos un combo plis aumentar esto
      this.loaderService.setBackgroundMode(false);
    }
  }
  ngOnDestroy(): void {
    this.loaderService.setBackgroundMode(false);
    this.subscription.unsubscribe();
  }

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.checksTipoDenuncia();
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }
  checksTipoDenuncia() {
    if (this.filter.policia !== undefined && this.filter.policia !== null && this.filter.policia === false) {
      this.filter.policia = null;
    }

    if (this.filter.zonaAzul !== undefined && this.filter.zonaAzul !== null && this.filter.zonaAzul === false) {
      this.filter.zonaAzul = null;
    }

    if (this.filter.convivencia !== undefined && this.filter.convivencia !== null && this.filter.convivencia === false) {
      this.filter.convivencia = null;
    }

  }

  dismiss() {
    this.router.navigate(['/denuncies']);
  }


  si() {
    this.habilitado = true;
  }

  no() {
    this.habilitado = false;
  }

  OnZonaChanged(value) {
    const zona = value;

    if (zona !== null && zona !== '') {
      // Carreguem els carrers per a passar-les al dialog
      this.carrersService.getCarrersZona(zona).subscribe(result => {
        this.carrers = [];
        if (result !== null) {
          let i: number;
          i = 0;
          result.forEach(element => {
            this.carrers[i] = element;
            i++;
          });
        }
        this.carrers.unshift({'nomCarrer': ''});
      });
    } /* else {
      // Carreguem els carrers per a passar-les al dialog
      this.carrersService.getAllForCombo().subscribe(result => {
        this.carrers = [];
        if (result !== null) {
          let i: number;
          i = 0;
          result.forEach(element => {
            this.carrers[i] = element;
            i++;
          });
        }
        this.carrers.unshift({'nomCarrer': ''});
      });
    }*/

 }

  OnLegiChanged(value) {

    const legi = value;

    if (legi !== null && legi !== '') {
      // Carreguem els articles per a passar-les al dialog
      this.infraccionsService.getInfrLegi(legi).subscribe(result => {
        result.forEach(element => {
          element.codi = element.id;
          element.descripcio = element.descCombo;
        });
        this.articles = result;
        this.articles.unshift({'codi': '', 'descripcio': ''});
      });
    } else {
      // Carreguem els articles per a passar-les al dialog
      this.LoadInfraccionsDenunciesCombo(false);
    }
  }

  ToggleTipus(value) {

    if (value.cheked === false) {
      this.tipusAnulArray.push(value.element);
      this.filter.tipusAnul = this.tipusAnulArray.toString();
      value.cheked = true;
    } else {
      this.tipusAnulArray.splice(value.element);
      this.filter.tipusAnul = this.tipusAnulArray.toString();
      value.cheked = false;
    }
  }
  ToggleUsuari(value) {

    if (value.cheked === false) {
      this.usuariAnulArray.push(value.element);
      this.filter.usuariAnul = this.usuariAnulArray.toString();
      value.cheked = true;

    } else {
      this.usuariAnulArray.splice(value.element);
      this.filter.usuariAnul = this.usuariAnulArray.toString();
      value.cheked = false;
    }
  }
  ToggleMotiu(value) {

    if (value.cheked === false) {
      this.motiuAnulArray.push(value.element.descripcio);
      this.filter.motiuAnul = this.motiuAnulArray.toString();
      value.cheked = true;
    } else {
      this.motiuAnulArray.splice(value.element.descripcio);
      this.filter.motiuAnul = this.motiuAnulArray.toString();
      value.cheked = false;
    }
  }

  LoadInfraccionsDenunciesCombo(isIncrementLoadedCombos: boolean) {
    let json: string;
    let infraccionesDenuncies: any[];

    if (sessionStorage.getItem('infraccionsDenunciesCombo')) {
      json = sessionStorage.getItem('infraccionsDenunciesCombo');
      infraccionesDenuncies = JSON.parse(json.toString());
      this.SetInfraccionsDenunciesCombo(infraccionesDenuncies, isIncrementLoadedCombos);
    } else {
      this.infraccionsService.getInfraccionsDenunciesCombo().subscribe(result => {
        json = JSON.stringify(result);
        sessionStorage.setItem('infraccionsDenunciesCombo', json);
        this.SetInfraccionsDenunciesCombo(result, isIncrementLoadedCombos);
      });
    }
  }

  SetInfraccionsDenunciesCombo(infraccionesDenuncies: any[], isIncrementLoadedCombos: boolean) {
    this.articles = infraccionesDenuncies;
    this.articles.unshift({'codi': '', 'descripcio': ''});

    if (isIncrementLoadedCombos) {
      this.incrementLoadedCombos();
    }
  }

  loadCalles() {
    let json: string;
    let carrers: any[];

    if (sessionStorage.getItem('carrersForCombo')) {
      json = sessionStorage.getItem('carrersForCombo');
      carrers = JSON.parse(json.toString());
      this.setCalles(carrers);
    } else {
      this.carrersService.getAllForCombo().subscribe(carrersForCombo => {
        json = JSON.stringify(carrersForCombo);
        sessionStorage.setItem('carrersForCombo', json);
        this.setCalles(carrersForCombo);
      });
    }
  }

  setCalles(result: any[]) {
    this.carrers = [];
    if (result !== null) {
      let i: number;
      i = 0;
      result.forEach(element => {
        this.carrers[i] = element;
        i++;
      });
    }
    this.carrers.unshift({'nomCarrer': ''});
    this.incrementLoadedCombos();
  }

}
