import { PropostesViewerAutologinComponent } from './sections/propostes/components/propostes-list/components/propostes-viewer-autologin/propostes-viewer-autologin.component';
import { TextosPredefinidosComponent } from './sections/configuracio/textos-predefinidos/pages/textos-predefinidos/textos-predefinidos.component';
import { DipositStockComponent } from './sections/grua/components/diposit-stock/diposit-stock.component';
import { PropostesAnulacioComponent } from './sections/zona-blava/propostes-anulacions/pages/propostes-anulacio/propostes-anulacio.component';
import { ConfirmacionComponent } from './sections/tramitar/teu/pages/confirmacion/confirmacion.component';
import { EnvioBopComponent } from './sections/tramitar/teu/pages/envio-bop/envio-bop.component';
import { EnvioPuntosDgtComponent } from './sections/tramitar/dgt/pages/envio-puntos-dgt/envio-puntos-dgt.component';
import { RespuestasComponent } from './sections/tramitar/notificaciones/pages/gestion/respuestas/respuestas.component';
import { AutoLoginComponent } from './sections/login/pages/auto-login/auto-login.component';
import { EstadisticasDenunciasComponent } from './sections/estadistiques/pages/estadisticas-denuncias/estadisticas-denuncias.component';
import { CargaMatriculasComponent } from './sections/denuncies/components/cargar-matriculas/components/carga-matriculas/carga-matriculas.component';
import { PredefinidesPageComponent } from './sections/tramitar/predefinides/page/predefinides-page/predefinides-page.component';
import { TextoInformativoPageComponent } from './sections/aparcare/text-informatiu/pages/texto-informativo-page/texto-informativo-page.component';
import { TiposSenalesVerticalesPageComponent } from './sections/configuracio/tipos-senales-verticales/tipos-senales-verticales-page/tipos-senales-verticales-page.component';
import { IniciarTurnoComponent } from './sections/configuracio/grua/turnos/components/iniciar-turno/iniciar-turno.component';
import { TurnosComponent } from './sections/configuracio/grua/turnos/turnos.component';
import { LivemapPageComponent } from './sections/livemap/livemap-page/livemap-page.component';
import { PendentsViewerComponent } from './sections/grua/components/pendents-viewer/pendents-viewer.component';
import { TramitadesComponent } from './sections/grua/components/tramitades/tramitades.component';
import { EstadistiquesReservesAparcareComponent } from './sections/estadistiques/pages/estadistiques-reserves-aparcare/estadistiques-reserves-aparcare.component';
import { PaisosComponent } from './sections/configuracio/paisos/pages/paisos/paisos.component';
import { DeteccioMatriculesComponent } from './sections/ciutat/deteccio-matricules/pages/deteccio-matricules/deteccio-matricules.component';
import { CookiePolicyComponent } from './pages/cookie-policy/cookie-policy.component';
import { MotiusDesestimarComponent } from './sections/configuracio/motius-desestimar/pages/motius-desestimar/motius-desestimar.component';
import { AparellsComponent } from './sections/configuracio/aparells/pages/aparells.component';
import { CarrersComponent } from './sections/configuracio/carrers/pages/carrers/carrers.component';
import { AgentsComponent } from './sections/configuracio/agents/pages/agents/agents.component';
import { MatriculesComponent } from './sections/ciutat/matricules/pages/matricules/matricules.component';
import { TipusMatriculesEditorComponent } from './sections/ciutat/tipus-matricules/components/tipus-matricules-editor/tipus-matricules-editor.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardPageComponent } from './sections/dash/pages/dashboardpage.component';
import { DenunciesPageComponent } from './sections/denuncies/pages/denunciespage.component';
import { DenunciesNewComponent } from './sections/denuncies/components/denuncies-new/denuncies-new.component';
import { DenunciesFilterComponent } from './sections/denuncies/components/denuncies-filter/denuncies-filter.component';
import { DenunciesEditorComponent } from './sections/denuncies/components/denuncies-editor/denuncies-editor.component';
import { TramitadorComponent } from './sections/denuncies/components/tramitador/tramitador.component';
import { PropostesPageComponent } from './sections/propostes/pages/propostes-page.component';
import { AparcarePageComponent } from './sections/aparcare/pages/aparcare-page.component';
import { CiutatPageComponent } from './sections/ciutat/pages/ciutat-page.component';
import { NotificacionsPageComponent } from './sections/notificacions/pages/notificacions-page.component';
import { PermisosPageComponent } from './sections/permisos/pages/permisos-page.component';
import { UsuarisPageComponent } from './sections/usuaris/pages/usuaris-page.component';
import { UtilitatsPageComponent } from './sections/utilitats/pages/utilitats-page.component';
import { LoginPageComponent } from './sections/login/pages/login-page/login-page.component';
import { AuthGuardService as AuthGuard } from './shared/services/authentication/auth-guard.service';
import { TestComponent } from './shared/components/test/test.component';
import { NotFoundComponent } from 'path-shared/components/not-found/not-found.component';
import { PropostesListComponent } from './sections/propostes/components/propostes-list/propostes-list.component';
import { MatriculesEditorComponent } from './sections/ciutat/matricules/components/matricules-editor/matricules-editor.component';
import { TipusMatriculesComponent } from './sections/ciutat/tipus-matricules/pages/tipus-matricules.component';
import { AgentsEditorComponent } from './sections/configuracio/agents/components/agents-editor/agents-editor.component';
import { TramitadorHistoricComponent } from './sections/denuncies/components/tramitador-historic/tramitador-historic.component';
import { ImportacioDenunciesComponent } from './sections/denuncies/components/importacio/importacio-denuncies.component';
import { LegislacionsComponent } from './sections/configuracio/legislacions/pages/legislacions.component';
import { MotiusAnulacioComponent } from './sections/configuracio/motius-anulacio/pages/motius-anulacio/motius-anulacio.component';
import { MatriculesHistoricComponent } from './sections/ciutat/matricules/pages/matricules-historic/matricules-historic.component';
import { AlarmesComponent } from './sections/alarmes/pages/alarmes/alarmes.component';
import { SiglasComponent } from './sections/configuracio/siglas/pages/siglas/siglas.component';
import { TipusVehiclesComponent } from './sections/configuracio/tipus-vehicles/pages/tipus-vehicles/tipus-vehicles.component';
import { ColorsComponent } from './sections/configuracio/colors/pages/colors/colors.component';
import { MarquesVehiclesComponent } from './sections/configuracio/marques-vehicles/pages/marques-vehicles.component';
import { ZonesComponent } from './sections/ciutat/zones/pages/zones.component';
import { InfraccioComponent } from './sections/configuracio/Infraccions/pages/infraccio.component';
import { AnulacionZonaAzulManualComponent } from './sections/anulacion-zona-azul-manual/page/anulacion-zona-azul-manual.component';
import { AnulacionZonaAzulAutoComponent } from './sections/anulacion-zona-azul-auto/page/anulacion-zona-azul-auto.component';
import { EstadistiquesSetmanalsComponent } from './sections/estadistiques/pages/estadistiques-setmanals/estadistiques-setmanals.component';
import { EstadistiquesMensualsComponent } from './sections/estadistiques/pages/estadistiques-mensuals/estadistiques-mensuals.component';
import { ComunicacioPdaComponent } from './sections/comunicacio-pda/pages/comunicacio-pda-page.component';
import { VisorAlarmesComponent } from './sections/alarmes/components/visor-alarmes/visor-alarmes.component';
import { TrackingComponent } from './sections/utilitats/tracking/tracking.component';
import { SincronitzacioComponent } from './sections/configuracio/sincronitzacio/pages/sincronitzacio/sincronitzacio.component';
import { AnulacionZonaAzulAutoHistoricComponent } from './sections/anulacion-zona-azul-auto/components/anulacion-zona-azul-auto-historic/anulacion-zona-azul-auto-historic.component';
import { AvisosConfComponent } from './sections/configuracio/avisos/pages/avisos.component';
import { AvisosComponent } from './sections/utilitats/avisos/avisos.component';
import { TiquetsPageComponent } from './sections/tiquets/pages/tiquets-page.component';
import { PersonesComponent } from './sections/ciutat/persones/pages/persones/persones.component';
import { FestiusComponent } from './sections/tiquets/festius/festius.component';
import { TipusTiquetsComponent } from './sections/tiquets/tipus-tiquets/tipus-tiquets.component';
import { VehiclesComponent } from './sections/ciutat/vehicles/pages/vehicles/vehicles.component';
import { XmlTreeViewerComponent} from './sections/utilitats/dgt/components/xml-tree-viewer/xml-tree-viewer.component';
import { SeguretatUsuarisPageComponent } from './sections/seguretat/seguretat-usuaris/pages/seguretat-usuaris-page.component';
import { SeguretatGrupsPageComponent } from './sections/seguretat/seguretat-grups/pages/seguretat-grups-page.component';
import { ComprovacioTiquetsComponent } from './sections/zona-blava/comprovacio-tiquets/pages/comprovacio-tiquets.component';
import { RecaudacioPageComponent } from './sections/zona-blava/recaudacio/pages/recaudacio-page.component';
import { GruaTecnicsComponent } from './sections/configuracio/grua/grua-tecnics/grua-tecnics.component';
import { GruaMotiusDesestimarComponent } from './sections/configuracio/grua/grua-motius-desestimar/grua-motius-desestimar.component';
import { GruaMotiusEntradaComponent } from './sections/configuracio/grua/grua-motius-entrada/grua-motius-entrada.component';
import { GruaAliesComponent } from './sections/configuracio/grua/grua-alies/grua-alies.component';
import { DipositComponent } from './sections/grua/components/diposit/diposit.component';
import { TarifesComponent } from './sections/tiquets/tarifes/tarifes.component';
import { DashContadoresDuplicadosComponent } from './sections/dash/components/dash/dash-contadores-duplicados/dash-contadores-duplicados.component';
import { ReservesComponent } from './sections/tiquets/reserves/page/reserves.component';
import { NuevaPropuestaGruaComponent } from './sections/grua/components/nueva-propuesta-grua/nueva-propuesta-grua.component';
import { BancCarreguesC60Component } from './sections/bancs/carregues-c60/pages/banc-carregues-c60.component';
import { BancIncidenciesComponent } from './sections/bancs/incidencies/pages/banc-incidencies.component';
import { TarifasGruaComponent } from './sections/configuracio/grua/tarifas-grua/tarifas-grua/tarifas-grua.component';
import { SenalesVerticalesPageComponent } from './sections/configuracio/senales-verticales/senales-verticales-page-component/senales-verticales-page.component';
import { TramitarExpedientspageComponent } from './sections/tramitar/expedients/pages/tramitar-expedientspage.component';
import { TextoAvisoPageComponent } from './sections/aparcare/text-avis/pages/texto-aviso-page/texto-aviso-page.component';
import { ImportacioDenunciesHistoricComponent } from './sections/denuncies/components/importacio-denuncies-historic/importacio-denuncies-historic.component';
import { AutomatitzacioComponent } from './sections/configuracio/automatitzacio/pages/automatitzacio.component';
import { MotiusNoNotificacioComponent } from './sections/configuracio/motius-notificacio/pages/motius-notificacio/motius-notificacio.component';
import { TipusEnviamentsComponent } from './sections/tramitar/tipus-enviaments/pages/tipus-enviaments.component';
import { RespostesPageComponent } from './sections/tramitar/respostes/pages/respostes.component';
import { CitesResidentsComponent } from './sections/ciutat/gestio-residents/components/cites-residents/cites-residents.component';
import { GestioResidentsComponent } from './sections/ciutat/gestio-residents/components/gestio-residents/gestio-residents.component';
import { ComprovarPadroComponent } from './sections/ciutat/gestio-residents/components/comprovar-padro/comprovar-padro.component';
import { ObservacionsPdaComponent } from './sections/configuracio/observacions-pda/pages/observacions-pda/observacions-pda.component';
import { AjudaPageComponent } from './sections/ajuda/pages/ajuda-page.component';
import { ZonesAparcareComponent } from './sections/tiquets/zones-aparcare/zones-aparcare.component';
import { EstadistiquesReincidentsComponent } from './sections/estadistiques/pages/estadistiques-reincidents/estadistiques-reincidents.component';
import { EstadisitcasAparcareComponent } from './sections/estadistiques/pages/estadisitcas-aparcare/estadisitcas-aparcare.component';
import { CobrosComponent } from './sections/bancs/cobros/cobros.component';
import { EnviosComponent } from './sections/tramitar/notificaciones/pages/gestion/envios/envios.component';
import { BonosComponent } from './sections/tiquets/bonos/bonos.component';
import { ConfiguracionComponent } from './sections/tramitar/teu/pages/configuracion/configuracion.component';
import { DesplacatsGruaComponent } from './sections/grua/finalitzacio/desplacats/desplacats-page/desplacats.component';
import { SenseRetiradaGruaComponent } from './sections/grua/finalitzacio/sense-retirada/sense-retirada-page/sense-retirada.component';
import { PendentsListPageComponent } from './sections/grua/components/pendents-list-page/pendents-list-page.component';
import { DeltacarHistoricoComponent } from './sections/zona-blava/deltacar-historico/pages/deltacar-historico.component';
import { EstadisticasVariosComponent } from './sections/estadistiques/pages/estadisticas-varios/estadisticas-varios.component';
import { EstadisitcasAgenteComponent } from './sections/estadistiques/pages/estadisitcas-agente/estadisitcas-agente.component';
import { ImageDisplayerComponent } from './image-displayer/image-displayer.component';
import { AcusosComponent } from './sections/tramitar/acusos/acusos.component';
import { ReportDisplayerComponent } from './report-displayer/report-displayer.component';
import { AlegacionsComponent } from './sections/tramitar/alegacions/alegacions.component';
import { RouterModule, Routes } from '@angular/router';
import { RespondreAlegacionsComponent } from './sections/tramitar/alegacions/respondre-alegacions/respondre-alegacions.component';
import { EnvioPuntosDgtHistoricoComponent } from './sections/tramitar/dgt/components/envio-puntos-dgt-historico/envio-puntos-dgt-historico.component';
import { AlarmesHistoricComponent } from './sections/alarmes/components/alarma-historic/pages/alarmes-historic/alarmes-historic.component';
import { GestionarComponent } from './sections/configuracio/agents/pages/gestionar/gestionar.component';
import { TramosTiposComponent } from './sections/zona-blava/estacionament/tramos/tramos-tipos/tramos-tipos/tramos-tipos.component';
import { TramosComponent } from './sections/zona-blava/estacionament/tramos/tramos/tramos/tramos.component';
import { MaquinasComponent } from './sections/zona-blava/maquinas/maquinas/maquinas.component';
import { TemporadaPageComponent } from './sections/zona-blava/estacionament/temporada/pages/temporada-page/temporada-page.component';
import { EscalaOcupacionPageComponent } from './sections/zona-blava/estacionament/escala-ocupacio/pages/escala-ocupacion-page/escala-ocupacion-page.component';
import { IncidenciesComponent } from './sections/zona-blava/maquinas/components/incidencies/incidencies.component';
import { ActuacionsAgentsComponent } from './sections/agents/actuacions-agents/pages/actuacions-agents.component';


const routes: Routes = [
  { path: '', component: DashboardPageComponent, canActivate: [AuthGuard]},
  { path: 'livemap', component: LivemapPageComponent, canActivate: [AuthGuard]},
  { path: 'denuncies', component: DenunciesPageComponent, canActivate: [AuthGuard]},
  { path: 'estadistiques/setmanals', component: EstadistiquesSetmanalsComponent, canActivate: [AuthGuard]},
  { path: 'estadistiques/mensuals', component: EstadistiquesMensualsComponent, canActivate: [AuthGuard]},
  { path: 'estadistiques/aparcare/reserves', component: EstadistiquesReservesAparcareComponent, canActivate: [AuthGuard]},
  { path: 'estadistiques/reincidents', component: EstadistiquesReincidentsComponent, canActivate: [AuthGuard]},
  { path: 'estadistiques/aparcare', component: EstadisitcasAparcareComponent, canActivate: [AuthGuard]},
  { path: 'estadistiques/denuncias', component: EstadisticasDenunciasComponent, canActivate: [AuthGuard]},
  { path: 'estadistiques/varios', component: EstadisticasVariosComponent, canActivate: [AuthGuard]},
  { path: 'estadistiques/agents', component: EstadisitcasAgenteComponent, canActivate: [AuthGuard]},
  { path: 'anulacion-zona-azul-manual', component: AnulacionZonaAzulManualComponent, canActivate: [AuthGuard]},
  { path: 'anulacion-zona-azul-manual/getDenuncia/:nDenuncia', component: AnulacionZonaAzulManualComponent, canActivate: [AuthGuard]},
  { path: 'anulacion-zona-azul-auto', component: AnulacionZonaAzulAutoComponent, canActivate: [AuthGuard]},
  { path: 'anulacion-zona-azul-auto-historic', component: AnulacionZonaAzulAutoHistoricComponent, canActivate: [AuthGuard]},
  { path: 'denuncies/tramitador', component: TramitadorComponent, canActivate: [AuthGuard]},
  { path: 'denuncies/importar-matriculas', component: CargaMatriculasComponent, canActivate: [AuthGuard]},
  { path: 'propostes-list', component: PropostesListComponent, canActivate: [AuthGuard]},
  { path: 'denuncies/nova-denuncia', component: DenunciesNewComponent, canActivate: [AuthGuard]},
  { path: 'denuncies/filtres-denuncia', component: DenunciesFilterComponent, canActivate: [AuthGuard]},
  { path: 'denuncies/editar-denuncia/:id', component: DenunciesEditorComponent, canActivate: [AuthGuard]},
  { path: 'denuncies/tramitador-historic', component: TramitadorHistoricComponent, canActivate: [AuthGuard]},
  { path: 'denuncies/importacio', component: ImportacioDenunciesComponent, canActivate: [AuthGuard]},
  { path: 'tramitar/acusos', component: AcusosComponent, canActivate: [AuthGuard]},
  { path: 'tramitar/expedients', component: TramitarExpedientspageComponent, canActivate: [AuthGuard]},
  { path: 'tramitar/alegacions', component: AlegacionsComponent, canActivate: [AuthGuard]},
  { path: 'tramitar/respondre-alegacions', component: RespondreAlegacionsComponent, canActivate: [AuthGuard]},
  { path: 'tramitar/predefinides', component: PredefinidesPageComponent, canActivate: [AuthGuard]},
  { path: 'tramitar/tipusEnviaments', component: TipusEnviamentsComponent, canActivate: [AuthGuard]},
  { path: 'tramitar/respostes', component: RespostesPageComponent, canActivate: [AuthGuard]},
  { path: 'tramitar/gestionar/envios', component: EnviosComponent, canActivate: [AuthGuard]},
  { path: 'tramitar/gestionar/respuestas', component: RespuestasComponent, canActivate: [AuthGuard]},
  { path: 'tramitar/envioPuntosDGT', component: EnvioPuntosDgtComponent, canActivate: [AuthGuard]},
  { path: 'tramitar/envio-puntos-dgt-historico', component: EnvioPuntosDgtHistoricoComponent, canActivate: [AuthGuard]},
  { path: 'tramitar/boe-configuracion', component: ConfiguracionComponent, canActivate: [AuthGuard]},
  { path: 'tramitar/boe-envio', component: EnvioBopComponent, canActivate: [AuthGuard]},
  { path: 'tramitar/boe-confirmacion', component: ConfirmacionComponent, canActivate: [AuthGuard]},
  { path: 'denuncies/importacio-denuncies-historic', component: ImportacioDenunciesHistoricComponent, canActivate: [AuthGuard]},
  { path: 'denuncies/banc/carregues-c60', component: BancCarreguesC60Component, canActivate: [AuthGuard]},
  { path: 'denuncies/banc/incidencies', component: BancIncidenciesComponent, canActivate: [AuthGuard]},
  { path: 'denuncies/banc/cobraments', component: CobrosComponent, canActivate: [AuthGuard]},
  { path: 'propostes/:id', component: PropostesPageComponent, canActivate: [AuthGuard]},
  
  { path: 'agents/actuacions-agents' , component: ActuacionsAgentsComponent, canActivate: [AuthGuard]},
  { path: 'agents/agents', component: AgentsComponent, canActivate: [AuthGuard]},
  { path: 'agents/gestio', component: GestionarComponent, canActivate: [AuthGuard]},
  { path: 'agents/agent/:secure_id', component: AgentsEditorComponent, canActivate: [AuthGuard]},
  { path: 'agents/agent', component: AgentsEditorComponent, canActivate: [AuthGuard]},
  { path: 'configuracio/zones', component: ZonesComponent, canActivate: [AuthGuard]},
  { path: 'configuracio/tipus-senals-verticals', component: TiposSenalesVerticalesPageComponent, canActivate: [AuthGuard]},
  { path: 'configuracio/senals-verticals', component: SenalesVerticalesPageComponent, canActivate: [AuthGuard]},
  { path: 'ciutat/zones/detalleZona/:secure_id', component: ZonesComponent, canActivate: [AuthGuard]},
  { path: 'ciutat/zones/deleteZona/:secure_id', component: ZonesComponent, canActivate: [AuthGuard]},
  { path: 'ciutat/matricules', component: MatriculesComponent, canActivate: [AuthGuard]},
  { path: 'ciutat/matricules/matricula', component: MatriculesEditorComponent, canActivate: [AuthGuard]},
  { path: 'ciutat/matricules/matricula/:secure_id', component: MatriculesEditorComponent, canActivate: [AuthGuard]},
  { path: 'ciutat/tipus-matricules', component: TipusMatriculesComponent, canActivate: [AuthGuard]},
  { path: 'ciutat/residentes/citas', component: CitesResidentsComponent, canActivate: [AuthGuard]},
  { path: 'ciutat/residentes/bonos', component: GestioResidentsComponent, canActivate: [AuthGuard]},
  { path: 'ciutat/residentes/comprovar-padro', component: ComprovarPadroComponent, canActivate: [AuthGuard]},
  { path: 'ciutat/tipus-matricules/tipus-matricula', component: TipusMatriculesEditorComponent, canActivate: [AuthGuard]},
  { path: 'ciutat/tipus-matricules/tipus-matricula/:secure_id', component: TipusMatriculesEditorComponent, canActivate: [AuthGuard]},
  { path: 'ciutat/matricules-historic', component: MatriculesHistoricComponent, canActivate: [AuthGuard]},
  { path: 'ciutat/deteccio-matricules', component: DeteccioMatriculesComponent, canActivate: [AuthGuard]},
  { path: 'ciutat/deteccio-matricules/:matricula', component: DeteccioMatriculesComponent, canActivate: [AuthGuard], data: {pantalla: 73}},
  { path: 'ciutat/persones', component: PersonesComponent, canActivate: [AuthGuard]},
  { path: 'ciutat/vehicles', component: VehiclesComponent, canActivate: [AuthGuard]},
  { path: 'comunicacioPda', component: ComunicacioPdaComponent, canActivate: [AuthGuard]},
  { path: 'configuracio/paisos', component: PaisosComponent, canActivate: [AuthGuard]},
  { path: 'configuracio/observacions-pda', component: ObservacionsPdaComponent, canActivate: [AuthGuard]},
  { path: 'configuracio/avisosconf', component: AvisosConfComponent, canActivate: [AuthGuard]},
  { path: 'configuracio/aparells', component: AparellsComponent, canActivate: [AuthGuard]},
  { path: 'configuracio/automatitzacio', component: AutomatitzacioComponent, canActivate: [AuthGuard]},
  { path: 'configuracio/carrers', component: CarrersComponent, canActivate: [AuthGuard]},
  { path: 'configuracio/legislacions', component: LegislacionsComponent, canActivate: [AuthGuard]},
  { path: 'configuracio/motius-notificacio', component: MotiusNoNotificacioComponent, canActivate: [AuthGuard]},
  { path: 'configuracio/motius-anulacio', component: MotiusAnulacioComponent, canActivate: [AuthGuard]},
  { path: 'configuracio/motius-desestimar', component: MotiusDesestimarComponent, canActivate: [AuthGuard]},
  { path: 'configuracio/siglas', component: SiglasComponent, canActivate: [AuthGuard]},
  { path: 'configuracio/tipus-vehicles', component: TipusVehiclesComponent, canActivate: [AuthGuard]},
  { path: 'configuracio/colors', component: ColorsComponent, canActivate: [AuthGuard]},
  { path: 'configuracio/marques-vehicles', component: MarquesVehiclesComponent, canActivate: [AuthGuard]},
  { path: 'configuracio/infraccions', component: InfraccioComponent, canActivate: [AuthGuard]},
  { path: 'configuracio/textos-predefinits', component: TextosPredefinidosComponent, canActivate: [AuthGuard]},
  { path: 'configuracio/sincronitzacio', component: SincronitzacioComponent, canActivate: [AuthGuard]},
  { path: 'alarmes/visor', component: VisorAlarmesComponent, canActivate: [AuthGuard]},
  { path: 'alarmes/visor/:id', component: VisorAlarmesComponent, canActivate: [AuthGuard]},
  { path: 'alarmes', component: AlarmesComponent, canActivate: [AuthGuard]},
  { path: 'alarmes/alarmes-historic', component: AlarmesHistoricComponent, canActivate: [AuthGuard]},
  { path: 'alarmes/:id', component: AlarmesComponent, canActivate: [AuthGuard]},
  { path: 'tracking', component: TrackingComponent, canActivate: [AuthGuard]},
  { path: 'avisos', component: AvisosComponent, canActivate: [AuthGuard]},
  { path: 'tiquets', component: TiquetsPageComponent, canActivate: [AuthGuard]},
  { path: 'bonos', component: BonosComponent, canActivate: [AuthGuard]},
  { path: 'festius', component: FestiusComponent, canActivate: [AuthGuard]},
  { path: 'tipusTiquets', component: TipusTiquetsComponent, canActivate: [AuthGuard]},
  { path: 'dgt', component: XmlTreeViewerComponent, canActivate: [AuthGuard]},
  { path: 'grua/pedents-table' , component: PendentsViewerComponent, canActivate: [AuthGuard]},
  { path: 'grua/pedents-list' , component: PendentsListPageComponent, canActivate: [AuthGuard]},
  { path: 'grua/nova-solicitud' , component: NuevaPropuestaGruaComponent, canActivate: [AuthGuard]},
  { path: 'grua/tramitades' , component: TramitadesComponent, canActivate: [AuthGuard]},
  { path: 'grua/diposit' , component: DipositComponent, canActivate: [AuthGuard]},
  { path: 'grua/StockDiposit' , component: DipositStockComponent, canActivate: [AuthGuard]},
  { path: 'grua/desplacats' , component: DesplacatsGruaComponent, canActivate: [AuthGuard]},
  { path: 'grua/sense-retirada' , component: SenseRetiradaGruaComponent, canActivate: [AuthGuard]},
  { path: 'configuracio/grua/tecnics' , component: GruaTecnicsComponent, canActivate: [AuthGuard]},
  { path: 'configuracio/grua/motius-desestimacio' , component: GruaMotiusDesestimarComponent, canActivate: [AuthGuard]},
  { path: 'configuracio/grua/motius-entrada' , component: GruaMotiusEntradaComponent, canActivate: [AuthGuard]},
  { path: 'configuracio/grua/alies' , component: GruaAliesComponent, canActivate: [AuthGuard]},
  { path: 'configuracio/grua/turnos' , component: TurnosComponent, canActivate: [AuthGuard]},
  { path: 'configuracio/grua/tarifas' , component: TarifasGruaComponent, canActivate: [AuthGuard]},
  { path: 'configuracio/grua/InciarTurno' , component: IniciarTurnoComponent, canActivate: [AuthGuard]},
  { path: 'zonaBlava/estacionament/tramos-tipos' , component: TramosTiposComponent, canActivate: [AuthGuard]},
  { path: 'zonaBlava/estacionament/tramos' , component: TramosComponent, canActivate: [AuthGuard]},
  { path: 'zonaBlava/maquines-ZB' , component: MaquinasComponent, canActivate: [AuthGuard]},
  { path: 'zonaBlava/incidencies' , component: IncidenciesComponent, canActivate: [AuthGuard]},
  { path: 'zonaBlava/estacionament/temporadas', component: TemporadaPageComponent, canActivate: [AuthGuard]},
  { path: 'zonaBlava/estacionament/escala-ocupacion', component: EscalaOcupacionPageComponent, canActivate: [AuthGuard]},
  { path: 'tarifes', component: TarifesComponent, canActivate: [AuthGuard]},
  { path: 'contadoresDuplicados', component: DashContadoresDuplicadosComponent, canActivate: [AuthGuard], data : {tipoFichero : 'DUPLICADO'}},
  { path: 'ficherosErroneos', component: DashContadoresDuplicadosComponent, canActivate: [AuthGuard], data : {tipoFichero : 'ERRONEO'}},
  { path: 'ficherosPendienteImagen', component: DashContadoresDuplicadosComponent, canActivate: [AuthGuard], data : {tipoFichero : 'PENDIENTE_IMG'}},
  { path: 'reserves', component: ReservesComponent, canActivate: [AuthGuard]},
  { path: 'seguretatUsuaris', component: SeguretatUsuarisPageComponent, canActivate: [AuthGuard]},
  { path: 'seguretatGrups', component: SeguretatGrupsPageComponent, canActivate: [AuthGuard]},
  { path: 'zonaBlava/comprovacio-tiquets', component: ComprovacioTiquetsComponent, canActivate: [AuthGuard]},
  { path: 'zonaBlava/recaudacio', component: RecaudacioPageComponent, canActivate: [AuthGuard]},
  { path: 'zonaBlava/propuestasAnulacion', component: PropostesAnulacioComponent, canActivate: [AuthGuard]},
  { path: 'zonaBlava/deltacar-historico', component: DeltacarHistoricoComponent, canActivate: [AuthGuard]},
  { path: 'aparcare', component: AparcarePageComponent, canActivate: [AuthGuard]},
  { path: 'textos-informativos', component: TextoInformativoPageComponent, canActivate: [AuthGuard]},
  { path: 'textos-aviso', component: TextoAvisoPageComponent, canActivate: [AuthGuard]},
  { path: 'ciutat', component: CiutatPageComponent, canActivate: [AuthGuard]},
  { path: 'notificacions', component: NotificacionsPageComponent, canActivate: [AuthGuard]},
  { path: 'permisos', component: PermisosPageComponent, canActivate: [AuthGuard] },
  { path: 'usuaris', component: UsuarisPageComponent, canActivate: [AuthGuard] },
  { path: 'utilitats', component: UtilitatsPageComponent, canActivate: [AuthGuard]},
  { path: 'login/:id', component: LoginPageComponent},
  { path: 'login', component: LoginPageComponent},
  { path: 'logout', component: LoginPageComponent},
  { path: 'autoLogin/:token/token_type/:token_type/expires_in/:expires_in/instit/:instit/screen', component: AutoLoginComponent},
  { path: 'autoLogin/:token/token_type/:token_type/expires_in/:expires_in/instit/:instit/screen/:screenId', component: AutoLoginComponent},
  { path: 'autoLogin/:token/token_type/:token_type/expires_in/:expires_in/instit/:instit/screen/:screenId/:secondScreen', component: AutoLoginComponent},
  { path: 'autoLogin/:token/token_type/:token_type/expires_in/:expires_in/instit/:instit/screen/:screenId/:secondScreen/:thirdScreen', component: AutoLoginComponent},
  { path: 'autoLogin/:token/token_type/:token_type/expires_in/:expires_in/instit/:instit/tradesegurId/:tradesegurId', component: AutoLoginComponent},
  { path: 'visorPropostesLogin', component: PropostesViewerAutologinComponent},
  { path: 'test', component: TestComponent },
  { path: 'pages/cookie-policy', component: CookiePolicyComponent},
  { path: 'ajuda', component: AjudaPageComponent, canActivate: [AuthGuard]},
  { path: 'zones-aparcare' , component: ZonesAparcareComponent, canActivate: [AuthGuard]},
  { path: 'image/:id', component: ImageDisplayerComponent},
  { path: 'report', component:ReportDisplayerComponent},
  { path: '**', component: NotFoundComponent},
];

@NgModule({
  imports: [
    CommonModule,
    [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled',  onSameUrlNavigation: 'reload'})]
  ],
  exports: [
    [RouterModule]
  ],
  declarations: []
})
export class AppRoutingModule { }
