import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { NotificacioDenuncia } from 'app/sections/notificacions/models/NotificacionDenuncia/notificacio-denuncia.model';
import { NotificacionsDenunciaFilter } from 'app/sections/notificacions/models/NotificacionDenuncia/notificacions-denuncia-filter';
import { NotificacionsDenunciesFilterService } from 'app/sections/notificacions/services/notificacions-denuncies-filter.services';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotificacionesDenunciaFilterComponent } from './notificaciones-denuncia-filter/notificaciones-denuncia-filter.component';

@Component({
  selector: 'app-listado-notificaciones-denuncia',
  templateUrl: './listado-notificaciones-denuncia.component.html',
  styleUrls: ['./listado-notificaciones-denuncia.component.scss']
})
export class ListadoNotificacionesDenunciaComponent implements OnInit,AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Output() notify: EventEmitter<NotificacionsDenunciaFilter> = new EventEmitter<NotificacionsDenunciaFilter>();
  dataSource: CustomDataSource<NotificacioDenuncia, NotificacionsDenunciaFilter>;
  filter: NotificacionsDenunciaFilter= new NotificacionsDenunciaFilter('exp', 'asc');
  displayedColumns = ['EXP','nom','direccio','poblacio','cp','provincia','data'];
  constructor(
    private dialog:MatDialog,
    public auth:AuthorizationService,
  ) { }

  ngAfterViewInit(): void {
     // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();

    this.load();
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  ngOnInit() {
    this.sort.disableClear = true;
      this.dataSource = CustomDataSource.create<NotificacioDenuncia, NotificacionsDenunciaFilter>(this.paginator, new NotificacionsDenunciesFilterService(), 'urlGetNotificacionsDenuncies', 'urlGetNotificacionsDenunciesCount');
      this.filter.paginator.init(this.sort, this.paginator);

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
      }
    });
  }

  continue(){
    this.notify.emit(this.filter);
  }

  onFilterClick(){
    const DialogRef = this.dialog.open(NotificacionesDenunciaFilterComponent, {
      width:'50%',
      data: this.filter
    });
    DialogRef.componentInstance.notify.subscribe((filter:NotificacionsDenunciaFilter)=>{
      this.filter=filter;
      this.load();
    });
  }

}
