<app-dialog-header label="Descarregar excel" i18n-label icon="fa fa-file-excel" iconClass="has-color-green"></app-dialog-header>
<app-toolbar>
    <app-tool-button *ngIf="!this.isDownloading()" text="Descarregar" i18n-text (click)="this.download()" color="has-color-green" icon="fa-save"></app-tool-button>
    <app-tool-button *ngIf="this.isDownloading()" text="Descarregant" i18n-text (click)="this.download()" color="has-color-green" icon="fa-spinner fa-spin"></app-tool-button>
    <app-tool-button *ngIf="!this.isDownloading()" text="Tancar" i18n-text (click)="this.close(false)" color="has-color-red" icon="fa-times"></app-tool-button>
    <app-tool-button *ngIf="this.isDownloading()" text="Cancel·lar" i18n-text (click)="this.close(false)" color="has-color-red" icon="fa-times"></app-tool-button>
</app-toolbar>
<app-dialog-body>

    <div class="alert alert-danger">

        <p i18n>Per descarregar presioni el botó "Descarregar".</p>
        <p i18n>L'excel es generarà (pot tardar una mica) i es baixarà.</p>
        <p i18n>Si té configurada la descàrrega automàtica trobarà l'arxiu en la seva carpata de descàrregues, si no, se li demanarà on guardar l'arxiu.</p>
        <!-- <small><app-form-check-edit [(value)]="this.addDate" label="Afegir la data en el nom del fitxer (format: yyyyMMddHHmmss)" i18n-label></app-form-check-edit></small> -->
    </div>
</app-dialog-body>