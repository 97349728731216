import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Acuse } from '../../models/acus-de-rebut.model';
import { AcusosService } from '../../services/acusos.service';
import { ToastrService } from 'ngx-toastr';
import { InformarAcus } from '../../models/informar-acus.model';

@Component({
  selector: 'app-informar-acusos',
  templateUrl: './informar-acusos.component.html',
  styleUrls: ['./informar-acusos.component.scss']
})
export class InformarAcusosComponent implements OnInit {

  acusos: Acuse[];
  isAcceptat: boolean;
  date: Date;

  constructor(
    public thisDialogRef: MatDialogRef<InformarAcusosComponent>,
    public i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private acusosService: AcusosService,
    private toastr: ToastrService
    ) {
      this.acusos = data.acusos;
      this.isAcceptat = data.isAcceptat;
    }

  ngOnInit() {
  }

  removeChip(acus: Acuse) {
    this.acusos = this.acusos.filter(x => x.expedient != acus.expedient);
  }

  save() {
    let informarAcusos = new Array<InformarAcus>(this.acusos.length);
    if (this.date != undefined) {
      this.acusos.forEach((x, i) => {
        informarAcusos[i] = new InformarAcus()
        informarAcusos[i].expedient = x.expedient
        informarAcusos[i].tipus = x.tipus
        informarAcusos[i].data = this.date
        informarAcusos[i].esAcceptada = this.isAcceptat
      });
  
      this.acusosService.informarAcusos(informarAcusos).subscribe(res => {
        next: this.toastr.success(Number.parseInt(res) + this.i18n(' acusos informats correctament'))
        this.thisDialogRef.close(true);
      }, () => 
      {
        error: this.toastr.error(this.i18n('Error informant acusos'))
        this.thisDialogRef.close(false);
      });
    }
    else {
      this.toastr.warning(this.i18n('Es necesario introducir la fecha'))
    }
  }

  displayTitle() {
    if (this.isAcceptat) return this.i18n("Informar");
    else return this.i18n("Rebutjar");
  }
}
