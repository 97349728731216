<app-filter-header title="Filtrar notificación acuses" i18n-title (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">
    <mat-form-field class="col-sm-3">
      <mat-label>Tipo</mat-label>
      <mat-select [(value)]="tipo" (selectionChange)="onTipusSelectionChanged(tipo)">
        <mat-option *ngFor="let tipo of this.tipus" [value]="tipo">
          {{tipo}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <app-form-text-edit class="col-sm-3" i18n-label label="Resultado" [(value)]="filter.resultat" maxLenght="50" number="true"></app-form-text-edit>
    <app-form-date-edit id="envio-filter-dataIniEnvio" class="col-sm-3" i18n-label label="Fecha inicio notificación" [(value)]="filter.dataInici" type="calendar"></app-form-date-edit>
    <app-form-date-edit id="envio-filter-dataFinEnvio" class="col-sm-3" i18n-label label="Fecha fin notificación" [(value)]="filter.dataFinal" type="calendar"></app-form-date-edit>
  </div>
</div>