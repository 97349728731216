import { GoogleMapsService } from 'path-shared/services/startup/google-maps.service';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewChild, OnChanges } from '@angular/core';
import { Proposta } from '../../models/proposta.model';
import { AppComponent } from 'app/app.component';
import { PropostaFilter } from '../../models/proposta.filter';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Builder } from 'path-shared/models/builder';
import { PropostesListComponent } from '../propostes-list/propostes-list.component';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { ValidationService } from 'path-shared/services/validation/validation.service';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { PropostesEditorComponent } from '../propostes-editor/propostes-editor.component';
import { PropostesService } from 'path-propostes-services/propostes.service';
import { PropostesDesestimarComponent } from '../propostes-desestimar/propostes-desestimar.component';
import { ToastrService } from 'ngx-toastr';
import { PropostaEstats } from '../../models/proposta-estats.model';
import { MotiusDesestimarService } from 'app/sections/configuracio/motius-desestimar/services/motius-desestimar.service';
import { MotiusDesestimar } from 'app/sections/configuracio/motius-desestimar/models/motius-desestimar.model';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { Router } from '@angular/router';
import { UsuarisService } from 'app/sections/usuaris/services/usuaris.service';
import { PropostesLlocEditorComponent } from '../propostes-lloc-editor/propostes-lloc-editor.component';
import { Instituciones } from 'path-shared/models/instituciones';
import { PhotoBoxGalleryComponent } from 'path-shared/components/photo-box/components/photo-box-gallery/photo-box-gallery.component';
import { SOM_FICHEROS } from 'path-models/SOM_FICHEROS';
import { ParametersService } from 'path-shared/services/parameters/parameters.service';
import { XmlTreeViewerComponent } from 'app/sections/utilitats/dgt/components/xml-tree-viewer/xml-tree-viewer.component';
import { StartupService } from 'path-shared/services/startup/startup.service';
import { AlarmaFilter } from 'app/sections/alarmes/models/alarma-filter';
import { AlarmesComponent } from 'app/sections/alarmes/pages/alarmes/alarmes.component';
import { DeteccioMatriculaFilter } from 'app/sections/ciutat/deteccio-matricules/models/deteccio-matricula-filter';
import { DeteccioMatriculesComponent } from 'app/sections/ciutat/deteccio-matricules/pages/deteccio-matricules/deteccio-matricules.component';
import { SOM_CARAS } from 'path-models/SOM_CARAS';
import { ImagesService } from 'path-shared/services/images/images.service';
import { PhotoBoxService } from 'path-shared/components/photo-box/photo-box.service';
import { SugerenciaMatricula } from '../../models/SugerenciaMatricula';
import { of } from 'rxjs';
import { AgentsService } from 'app/sections/configuracio/agents/services/agents-service.service';
import { PropostaReject } from '../../models/proposta-reject.model';
import { Parametros } from 'path-shared/models/parametros';

declare const SVG: any;

@Component({
  selector: 'app-proposta-viewer',
  templateUrl: './proposta-viewer.component.html',
  styleUrls: ['./proposta-viewer.component.scss'],
})
export class PropostaViewerComponent implements OnInit, OnChanges {
  @Input() proposta: Proposta;
  @Output() update: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('pbg') pbg: PhotoBoxGalleryComponent;
  validacioError = [];
  modalPropostesDesestimarRef: MatDialogRef<PropostesDesestimarComponent>;
  motiuDesestimarCanviMatricula: MotiusDesestimar;
  longitudeInstitucio = false;
  latitudeInstitucio = false;
  validarFotosProposta = false;
  llocValidat = false;
  matriculaValidat = false;
  missatgeAcceptar = '';
  enviarFotosDenuncia = false;
  notificadaEnMa = false;
  vehicles: any[];
  matriculaAux: string;
  agents = [];
  showLupa=false;


  constructor(
    private authGuard: AuthGuardService,
    private googleMapsService: GoogleMapsService,
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private validation: ValidationService,
    private i18n: I18n,
    private propostesService: PropostesService,
    private toastr: ToastrService,
    private motiusDesestimarService: MotiusDesestimarService,
    private confirmationDialogService: ConfirmationDialogService,
    private router: Router,
    private usuarisService: UsuarisService,
    private parametersService: ParametersService,
    private startupService: StartupService,
    private imagesService: ImagesService,
    private photoBoxService: PhotoBoxService,
    private agentsService: AgentsService,
  ) {
    
    var paramLupa =this.parametersService.getParameter(Parametros.SHOWLUPA);
    if(paramLupa !== undefined && paramLupa !== null && paramLupa !=='' && (paramLupa=== 'true' || paramLupa ==='1')){
      this.showLupa =true;
    }
    this.motiuDesestimarCanviMatricula = undefined;
    const inst = sessionStorage.getItem('currentInst');
      if (inst !== '' && inst !== undefined && inst !== 'undefined') {
        const institution: Instituciones = JSON.parse(inst);
        this.validarFotosProposta =  institution.ValidarFotosProposta;
        this.enviarFotosDenuncia = this.parametersService.getParameter('PASAR_FOTOS_DENUNCIA') === 'Y';
        this.notificadaEnMa = this.parametersService.getParameter('PROPOSTES_NOTIFICADA') === 'Y';

        if (this.validarFotosProposta === true) {
          this.missatgeAcceptar += 'Per a poder denunciar has de validar totes les imatges, la matrícula i el lloc';
        }
        if (this.enviarFotosDenuncia === true ) {
          this.missatgeAcceptar += 'Per a poder denunciar has de seleccionar com a mínim una imatge';
        }
      }

      this.agentsService.getAllCombo().subscribe(result => {
        this.agents = result;
      });
  }



  ngOnInit() {
    this.motiusDesestimarService.getMotiusDesestimarCanviMatricula().subscribe(motiu => {
      if (motiu.id === 0) {
        this.motiuDesestimarCanviMatricula = undefined;
      } else {
        this.motiuDesestimarCanviMatricula = motiu;
      }
    });
    if (this.proposta.VEHICLE === null) {
      this.proposta.VEHICLE = 1;
    }
    this.vehicles = this.startupService.GetTipusVehicles();
    this.matriculaAux = this.proposta !== undefined ? this.proposta.MATRICULA : '-1';

  }

  disabled() {
    return (this.validarFotosProposta && (!this.allChecked() || !this.matriculaValidat /*|| !this.llocValidat*/));
  }
  disabled2() {
    return (this.validarFotosProposta && (!this.allChecked() || !this.matriculaValidat /*|| !this.llocValidat*/)) || (this.enviarFotosDenuncia && !this.minimumSelected());

  }

  minimumSelected() {
    const minimumSelected = this.pbg.selectedImages.filter(x => x.checked === true).length > 0;
    const buttons = document.getElementsByClassName('button-not-allowed') as HTMLCollectionOf<HTMLElement>;

    if (!minimumSelected) {

      buttons[1].style.cursor = 'not-allowed';
    } else {

      buttons[1].style.cursor = 'pointer';
    }
    return minimumSelected;
  }

  allChecked() {
    const allChecked = this.pbg.selectedImages.filter(x => x.checked === true).length === this.pbg.selectedImages.length;
    const buttons = document.getElementsByClassName('button-not-allowed') as HTMLCollectionOf<HTMLElement>;

    if (!allChecked) {
      buttons[0].style.cursor = 'not-allowed';
      buttons[1].style.cursor = 'not-allowed';
    } else {
      buttons[0].style.cursor = 'pointer';
      buttons[1].style.cursor = 'pointer';
    }
    return allChecked;
  }

  ngOnChanges (changes: SimpleChanges): void {
    if (changes.proposta !== undefined && changes.proposta !== null
      && changes.proposta.currentValue !== undefined && changes.proposta.currentValue !== null) {
        this.proposta.LONGITUD = this.getLongitude();
        this.proposta.LATITUD = this.getLatitude();
      }
  }

  getLongitude(): number {
    let long = null;
    if (this.proposta !== undefined && this.proposta !== null) {
      if (this.proposta.LONGITUD !== null && this.proposta.LONGITUD !== 0) {
      long = this.proposta.LONGITUD;
      }
    }
    if (long === null) {
      long = AppComponent.getInstitution().Longitud;
      this.longitudeInstitucio = true;
    }
    return long;
  }

  getLatitude(): number {
    let lat = null;
    if (this.proposta !== undefined && this.proposta !== null) {
      if (this.proposta.LATITUD !== null && this.proposta.LATITUD !== 0) {
        lat = this.proposta.LATITUD;
      }
    }
    if (lat === null) {
      lat = AppComponent.getInstitution().Latitud;
      this.latitudeInstitucio = true;
    }
    return lat;
  }

  veureReincidencies(matricula) {
    const filter: PropostaFilter = new PropostaFilter('', '');
    const dies = this.parametersService.getParameter('REINCIDENTS_DIES');
    filter.matricula = matricula;

    filter.fechaInfraccionInicio = new Date();
    filter.fechaInfraccionInicio.setDate(filter.fechaInfraccionInicio.getDate() - parseInt(dies))
    const data: ComponentDialog = Builder.newComponentDialog(true, false, '', filter);
    const dlg = this.dialog.open(PropostesListComponent, {
      closeOnNavigation: false,
      autoFocus: true,
      width: '90%',
      height: '90%',
      data: data,
    });
  }



  propostaValida() {
    let valid = true;
    this.validacioError.length = 0;
    if (!Reflection.isNull(this.proposta)) {
      if (this.validation.isEmpty(this.proposta.pais)) {
        valid = false;
        this.validacioError.push(this.i18n('Falta el país'));
      }
      if (this.validation.isEmpty(this.proposta.MATRICULA)) {
        valid = false;
        this.validacioError.push(this.i18n('Falta matrícula'));
      }
      if (this.validation.isEmpty(this.proposta.ID_LLOC)) {
        valid = false;
        this.validacioError.push(this.i18n('Falta el lloc'));
      }
    }
    return valid;
  }

  editProposta() {
    if (this.motiuDesestimarCanviMatricula === undefined) {
      this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('No s\ha definit cap motiu per desestimar per el canvi de matrícula. Vols definir-lo ara?'), this.i18n('Si'), this.i18n('No'))
      .then((confirmed) => {
          if (confirmed) {
            this.router.navigate(['/configuracio/motius-desestimar']);
            this.dialog.closeAll();
          }
        });
    } else {
      this.dialog.open(PropostesEditorComponent, {
        closeOnNavigation: false,
        autoFocus: true,
        width: '60%',
        height: '40%',
        data: this.proposta
      }).afterClosed().subscribe(value => {
        // Al tancar component en modal per editar país/matrícula/vehícle
        this.propostesService.getPropostaReincidencies(this.proposta).subscribe(result => {
          this.proposta.reincidents = result;
        });

        if (this.proposta !== undefined && this.matriculaAux === '') {
          this.matriculesRequadrarPixelar(this.proposta.MATRICULA, true, true);
          this.matriculaAux = this.proposta.MATRICULA;
        } else {
          this.marcarMatriculesTrobadesTotes();
        }
      });
    }
  }

  hasLocation() {
    if (this.proposta == null) {
      return false;
    }
    if (
      this.proposta.LATITUD == null ||
      this.proposta.LONGITUD == null
    ) {
      return false;
    }
    return true;
  }

  openModalRejectProposta() {
    this.propostesService.getRejectSubjects().subscribe(subjects => {
      this.modalPropostesDesestimarRef = this.dialog.open(PropostesDesestimarComponent, {
        width: '600px',
        closeOnNavigation: false,
        autoFocus: true,
        data: {
          subjects: subjects,
          numProposta: this.proposta.NUMDENUNCIA,
          secure_id: this.proposta.SECURE_ID
        }
      });
      this.modalPropostesDesestimarRef.afterClosed().subscribe(rejected => {
        // if (rejected) {
        //   this.proposta.proposta = 2;
        // }
      });
      this.modalPropostesDesestimarRef.componentInstance.update.subscribe(($e) => {
        this.update.emit(false);
      });
    });
  }

  acceptProposta(num) {
    if (this.proposta.notificadaEnMa && (this.proposta.agent === undefined || this.proposta.expExterno === undefined)) {
      this.toastr.warning(this.i18n('Faltan campos por completar'));
      return;
    }
    const userId = this.usuarisService.getCurrentUser().SECURE_ID;
    if (num != null) {

      if (this.proposta.notificadaEnMa) {
        this.propostesService.notificadaEnMa({'sid': num, 'expExterno': this.proposta.expExterno, 'agent': this.proposta.agent}).subscribe(() => {
          this.Accept(num, userId);
        });
      } else {
        this.Accept(num, userId);
      }
    }
  }

  Accept(num, userId) {
    this.propostesService.acceptProposta(num, userId).subscribe(response => {
      if (this.enviarFotosDenuncia) {
        this.propostesService.removeImages(num, this.pbg.selectedImages.filter(x => x.checked === false).map(function(v) {return v.imatge.SOM_ID; }))
                              .subscribe(result => { });
      }
      this.toastr.info(
        this.i18n(
          'La proposta {{numVar}} ha estat acceptada com a denúncia',
          { numVar: num }
        ),
        this.i18n('Proposta acceptada')
      );
      this.update.emit(false);
      if (this.proposta.proposta === PropostaEstats.PENDENT) {
        this.proposta.proposta = PropostaEstats.ACCEPTADA;
      } else if (this.proposta.proposta === PropostaEstats.PREPROPOSTA) {
        this.proposta.proposta = PropostaEstats.PENDENT;

      }
    });
  }

  pendent() {
    if (this.proposta === undefined || this.proposta === null) {
      return false;
    } else {
      return this.proposta.proposta === PropostaEstats.PENDENT || this.proposta.proposta === PropostaEstats.PREPROPOSTA;
    }
  }

  pendentValidacio() {
    if (this.proposta === undefined || this.proposta === null) {
      return false;
    } else {
      return this.proposta.proposta === PropostaEstats.PENDENT_VALIDACIO;
    }
  }

  esModificable() {
    if (Reflection.isNull(this.proposta)) {
      return false;
    } else {
      return this.proposta.proposta === PropostaEstats.PENDENT;
    }
  }

  desestimada() {
    if (this.proposta === undefined || this.proposta === null) {
      return false;
    } else {
      return this.proposta.proposta === PropostaEstats.DESESTIMADA;
    }
  }

  acceptada() {
    if (this.proposta === undefined || this.proposta === null) {
      return false;
    } else {
      return this.proposta.proposta === PropostaEstats.ACCEPTADA;
    }
  }

  setPais(pais) {
    this.proposta.pais = pais;     
    this.propostesService.saveProposta(this.proposta).subscribe(() => {
      this.toastr.info(this.i18n('Proposta modificada correctament'));
    });

  }

  setMatricula(matricula) {
    this.proposta.MATRICULA= matricula;
    this.propostesService.ValidarMatriculaAutorizada(matricula,this.proposta.SECURE_ID).subscribe(autorizada => {
      if (autorizada) {
        this.confirmationDialogService.confirm(this.i18n('Atenció'), this.i18n('La nova matricula pertany a una llista autoritzada. \nSi accepta el canvi es desestimarà la denúncia de forma automàtica'))
        .then((confirmed) => {
          if (confirmed) {
            const propostaData: PropostaReject = {
              ID_MOTIU_DESESTIMAR_PROPOSTA: 99,
              TEXT_DESESTIMAR_PROPOSTA: 'Matrícula desestimada automàticament (Està en llista autoritzada)',
              propostes: []
            };
            this.propostesService.saveProposta(this.proposta).subscribe(result => {
              this.propostesService.rejectProposta(this.proposta.SECURE_ID, propostaData).subscribe(() => {
                this.toastr.info(this.i18n('Proposta desestimada de forma automàtica'));
                this.proposta.proposta = 2;                

              });
            });

          }
        });
      } else {
        this.propostesService.saveProposta(this.proposta).subscribe(result => {
          this.toastr.info(this.i18n('Proposta modificada correctament'));     
          
           // Combo component matrícula
          if (this.proposta !== undefined && this.proposta.IMATGES !== undefined) {
            this.matriculesRequadrarPixelar(this.proposta.MATRICULA, true, true);
            }
        });
       
      }
    });

    //this.proposta.MATRICULA = matricula;

    
  }

  editPropostaLloc() {
    this.dialog.open(PropostesLlocEditorComponent, {
      closeOnNavigation: false,
      autoFocus: true,
      width: '60%',
      height: '40%',
      data: this.proposta

    }).afterClosed().pipe(value => value).subscribe(value => {

      if (value !== '' && value !== undefined) {
        this.proposta.LLOC = value.lloc;
        this.proposta.relacions = value.relacions;
      }
    });
  }

  updateImatges(imatges: SOM_FICHEROS[]) {
    this.pbg.updateImatges(imatges);
  }

  openDGT(secureId, matricula) {
    if ( matricula !== undefined && matricula !== null && matricula !== '') {
      const ref = this.dialog.open(XmlTreeViewerComponent, {
        width: '50%',
        height: '75%',
        closeOnNavigation: true,
        autoFocus: true,
        data: {
          'isDialog': true,
          'isReadOnly': false,
          'matricula': matricula,
          'persona': '',
          'propietario': '',
          'incorporaData': true,
          'consulta': false,
          'secureIdDenuncia': secureId,
          'fechaDenuncia': this.proposta.FECHA
        }
      });

      // changes in grua
      // ref.afterClosed().subscribe(data =>{
      //   this.update.emit(false);
      // changes in dev
      ref.afterClosed().subscribe(data => {
        // No actualitzem el pare al tancar
        // this.update.emit(true);
      });
      /*ref.afterClosed().subscribe(data => {
        if (data !== undefined && data !== null &&
          data.marca !== undefined &&  data.marca !== null &&  data.marca !== '' &&
          data.model !== undefined && data.model !== null && data.model !== '') {
            this.denunciaDetail.marca = data.marca;
            this.denunciaDetail.modelo = data.model;
            this.operaciones.addOperation(data.operacio);
            this.toastr.success(this.i18n('Denuncia actualitzada correctament'));
          }
      });*/
    }
  }

  veureAvisos(matricula: string, dataAlarma: Date) {
    const filter: AlarmaFilter = new AlarmaFilter('id', 'asc');
    // filter.tradesegur_id = TradesegurId;
    filter.matricula = matricula;
    filter.data = new Date(dataAlarma);
    // filter.data = dataAlarma;
    filter.data_ack = null;
    // this.filterService.saveFilter('persona-filter', filter);
    // Carraguem la taula filtrada per dni
    const data: ComponentDialog = Builder.newComponentDialog(true, true, '', filter);
    const ref = this.dialog.open(AlarmesComponent, {
      width: '80%',
      height: '90%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });
  }

  openDeteccio() {
    const filter = new DeteccioMatriculaFilter('matricula', 'asc');
    filter.matricula = this.proposta.MATRICULA;
    filter.dataInici = new Date(this.proposta.FECHA);
    filter.dataFi = new Date(this.proposta.FECHA);
    const data: ComponentDialog = Builder.newComponentDialog(true, true, '', filter);
    const ref = this.dialog.open(DeteccioMatriculesComponent, {
      width: '80%',
      height: '90%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });
  }

  imageUrl(somId, suffix = ''): string {
    let url;
    const myObservable = of(this.imagesService.getImageUrl(somId, suffix) + '?' + new Date().getTime());
    const Observer = myObservable.subscribe( data => {
      url = data;
    });

    return url;
  }
  vehicle() {
    return this.vehicles.find(x => x.id === this.proposta.VEHICLE).descripcio;
  }

  //#region Tractar matrícules trobades per l'OCR

    /*
      Mètode que a partir de la informació guardada per l'OCR, ens envia al back on estàn les matrícules:
        - isPixelate = true
            Ens pixela aquelles matrícula que no coincideixin amb la que tenim informada.
        - isCrop = true
            Fa una nova imatge retallant la matrícula que tenim informada
    */
  matriculesRequadrarPixelar(matricula: string, isPixelate: boolean = false, isCrop: boolean = false) {
    const matriculasImageData: SOM_CARAS[] = [];
    let top;
    let left;
    let width;
    let height;
    let auxMatriculaImage;

    this.proposta.MATRICULA = matricula;

    // Només pixelem en cas de tenir més d'una matrícula i només el 1er cop que escollim
    if (isPixelate && this.proposta.sugMatriculesCoordenades.length > 1) {
      this.proposta.sugMatriculesCoordenades.forEach(sugMatriculaCoordenada => {
        top = sugMatriculaCoordenada.coordenadas[0];
        left = sugMatriculaCoordenada.coordenadas[1];
        width = sugMatriculaCoordenada.coordenadas[2];
        height = sugMatriculaCoordenada.coordenadas[3];

        auxMatriculaImage = new SOM_CARAS();
        auxMatriculaImage.TOP_IMAGE = top;
        auxMatriculaImage.LEFT_IMAGE = left;
        auxMatriculaImage.WIDTH_IMAGE = width - left;
        auxMatriculaImage.HEIGHT_IMAGE = height - top;
        auxMatriculaImage.brightness = 1;
        auxMatriculaImage.matricula = sugMatriculaCoordenada.matricula;

        matriculasImageData.push(auxMatriculaImage);
      });

      if (matricula === '' || matricula === null || matricula === undefined) {
        matricula = '-1';
      }

      const OCRImages = this.proposta.IMATGES.filter(x => x.SUGMATRICULA !== null);
      if (OCRImages.length === 1) {
        const somId = OCRImages[0].SOM_ID.toString();
        this.auxMatriculaRequadrarPixelar(somId, isCrop, matricula, matriculasImageData);
      } else if (OCRImages.length > 1) {
        
        this.auxMatriculaRequadrarPixelarTodas(isCrop, matricula, matriculasImageData);
      } else {
        this.proposta.IMATGES.forEach(imatge => {
          const somId = imatge.SOM_ID.toString();
          this.auxMatriculaRequadrarPixelar(somId, isCrop, matricula, matriculasImageData);
        });
      }

    }

    // this.marcarMatriculesTrobadesTotes();
  }
  auxMatriculaRequadrarPixelarTodas(isCrop, matricula, matriculasImageData) {
    this.imagesService.setTodasMatriculasImage(this.proposta.NUMDENUNCIA, matricula).subscribe(() => {
      let indexImagen=0;
      this.proposta.IMATGES.forEach(imatge => {
        const somId = imatge.SOM_ID.toString();
         // Refresquem la imatge
          const imageSource = this.imageUrl(somId, 'M');
          const drawAux = SVG.get('#draw_imageDiv_' + somId);
          drawAux.image(imageSource);
          //this.marcarMatriculesTrobades(drawAux);
          this.marcarMatriculesTrobadesNormal(drawAux, imatge.SOM_ID);

          if (isCrop && indexImagen===0) {
            let matriculasCrop: SOM_CARAS[]=[];
   
            let auxMatriculaImage;
            let currentSugIndex = imatge.sugMatriculesCoordenades.findIndex(x => x.matricula === matricula);
            if(currentSugIndex !== -1){
              let currentSug =imatge.sugMatriculesCoordenades[currentSugIndex];
      
              auxMatriculaImage = new SOM_CARAS();
              auxMatriculaImage.TOP_IMAGE = currentSug.coordenadas[0];//top;
              auxMatriculaImage.LEFT_IMAGE = currentSug.coordenadas[1];//left;
              auxMatriculaImage.WIDTH_IMAGE = currentSug.coordenadas[2] - currentSug.coordenadas[1]; //width - left;
              auxMatriculaImage.HEIGHT_IMAGE = currentSug.coordenadas[3] - currentSug.coordenadas[0]; //  height - top;
              auxMatriculaImage.brightness = 1;
              auxMatriculaImage.matricula = currentSug.matricula;
      
              matriculasCrop.push(auxMatriculaImage);
              // Tornem a posar brillantor a 1 perquè ja ens l'haurà aplicat al pixelat i així no queda tan clara la imatge.
              matriculasCrop[0].brightness = 1;
              if (matriculasCrop.length === 1) {
                this.cropMatriculaSeleccionada(matriculasCrop, somId);
                indexImagen=indexImagen+1;//solo augmentamos indicie si se hace crop
              }
            }
          }
      });
    });
  }
  auxMatriculaRequadrarPixelar(somId, isCrop, matricula, matriculasImageData) {
    this.imagesService.setMatriculasImage(this.proposta.NUMDENUNCIA, somId, matricula, matriculasImageData).subscribe(() => {
      // Refresquem la imatge
      const imageSource = this.imageUrl(somId, 'M');
      const drawAux = SVG.get('#draw_imageDiv_' + somId);
      drawAux.image(imageSource);
      this.marcarMatriculesTrobades(drawAux);

          if (isCrop) {
            const matriculasCrop: SOM_CARAS[] = matriculasImageData.filter(x => x.matricula === matricula);
            // Tornem a posar brillantor a 1 perquè ja ens l'haurà aplicat al pixelat i així no queda tan clara la imatge.
            matriculasCrop[0].brightness = 1;
            if (matriculasCrop.length === 1) {
              this.cropMatriculaSeleccionada(matriculasCrop, somId);
            }
          }
    });
  }

  marcarMatriculesTrobadesTotes() {
    const drawList = SVG.select('.draw_imageDiv');
    drawList.members.forEach(drawImage => {
      this.marcarMatriculesTrobades(drawImage);
    });
  }

  /*
    A partir de la informació que ens ha tornat l'OCR, enquadrem les matricules:
      - Vermell: totes les matrícules si encara no en tenim cap d'informada
      - Taronja: aquelles matrícules que no coincideixen amb la que tenim informada
      - Verd: aquella matrícula que coincideix amb la que tenim informada
  */
  marcarMatriculesTrobades(drawImage: any) {
    let colorMatricula = 'red';
    let strokeWidth: number;

    this.proposta.sugMatriculesCoordenades.forEach((sugMatriculaCoordenada: SugerenciaMatricula) => {
      const top = sugMatriculaCoordenada.coordenadas[0];
      const left = sugMatriculaCoordenada.coordenadas[1];
      const width = sugMatriculaCoordenada.coordenadas[2] - sugMatriculaCoordenada.coordenadas[1];
      const height = sugMatriculaCoordenada.coordenadas[3] - sugMatriculaCoordenada.coordenadas[0];

      if (this.proposta.MATRICULA !== undefined && this.proposta.MATRICULA !== null && this.proposta.MATRICULA !== '') {
        if (sugMatriculaCoordenada.matricula === this.proposta.MATRICULA) {
          colorMatricula = 'green';
        } else {
          colorMatricula = 'orange';
        }
      } else {
        colorMatricula = 'red';
      }

      // Si tenim un rectangle d'area petita, li baixem el gruix de la linia
      if ((width * height) < 1500) {
        strokeWidth = 3;
      } else {
        strokeWidth = 4;
      }

      const group = drawImage.group();
      const polyline = group.polyline([[0, 0], [0, height], [width, height], [width, 0], [0, 0]])
                            .fill('none').stroke({ color: colorMatricula, width: strokeWidth });


      group.move(left, top);
      polyline.scale(1.5); // Afegim marges a les coordenades inicials que ens passa l'OCR
    });
  }
  marcarMatriculesTrobadesImagen(drawImage: any, imagen:SOM_FICHEROS) {
    let colorMatricula = 'red';
    let strokeWidth: number;

    imagen.sugMatriculesCoordenades.forEach((sugMatriculaCoordenada: SugerenciaMatricula) => {
      const top = sugMatriculaCoordenada.coordenadas[0];
      const left = sugMatriculaCoordenada.coordenadas[1];
      const width = sugMatriculaCoordenada.coordenadas[2] - sugMatriculaCoordenada.coordenadas[1];
      const height = sugMatriculaCoordenada.coordenadas[3] - sugMatriculaCoordenada.coordenadas[0];

      if (this.proposta.MATRICULA !== undefined && this.proposta.MATRICULA !== null && this.proposta.MATRICULA !== '') {
        if (sugMatriculaCoordenada.matricula === this.proposta.MATRICULA) {
          colorMatricula = 'green';
        } else {
          colorMatricula = 'orange';
        }
      } else {
        colorMatricula = 'red';
      }

      // Si tenim un rectangle d'area petita, li baixem el gruix de la linia
      if ((width * height) < 1500) {
        strokeWidth = 3;
      } else {
        strokeWidth = 4;
      }

      const group = drawImage.group();
      const polyline = group.polyline([[0, 0], [0, height], [width, height], [width, 0], [0, 0]])
                            .fill('none').stroke({ color: colorMatricula, width: strokeWidth });


      group.move(left, top);
      polyline.scale(1.5); // Afegim marges a les coordenades inicials que ens passa l'OCR
    });
  }
  marcarMatriculesTrobadesNormal(drawImage: any, somIdImg: number) {
    
   let indxImg = this.proposta.IMATGES.findIndex(x =>x.SOM_ID === somIdImg );
    if(indxImg !== -1  && 
      this.proposta.IMATGES[indxImg].SUGMATRICULA !== undefined &&
      this.proposta.IMATGES[indxImg].SUGMATRICULA !== null &&
      this.proposta.IMATGES[indxImg].SUGMATRICULA !== ''){
        this.marcarMatriculesTrobadesImagen(drawImage, this.proposta.IMATGES[indxImg] );
      }else{
        //cojemos las sugerencias de la propuesta
        this.marcarMatriculesTrobades(drawImage);
      }
    
  }
  /*
    Mètode que fa la crida al back per fer el crop d'una matrícula
  */
  cropMatriculaSeleccionada(matriculasCrop: SOM_CARAS[], somId: string) {
    matriculasCrop = this.photoBoxService.margenExtra(matriculasCrop, 10);
    this.imagesService.cropImage(somId, matriculasCrop).subscribe( data => {
      this.proposta.IMATGES.push(data);
      this.pbg.selectedImages.push({imatge: data, checked: false});
    });
  }


  //#endregion

  UpdateProposta($event: Boolean) {
    if ($event) {
      this.update.emit(false);
    }
  }
}
