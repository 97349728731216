import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { ZbHorariosFilter } from './zb-horarios-table/zb-horarios-filter';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { ZbHorarioEditorComponent } from './zb-horario-editor/zb-horario-editor.component';
import { ZbHorariosTableComponent } from './zb-horarios-table/zb-horarios-table.component';

@Component({
  selector: 'app-zb-horarios',
  templateUrl: './zb-horarios.component.html',
  styleUrls: ['./zb-horarios.component.scss']
})
export class ZbHorariosComponent implements OnInit {

  @ViewChild(ZbHorariosTableComponent) ZbHorariosTable: ZbHorariosTableComponent;
  constructor(
    private dialog: MatDialog,
  ) {
   
  }
  ngOnInit(): void {
   
  }

  create(){
    const data: ComponentDialog= Builder.newComponentDialog(true, false,null);
    const ref= this.dialog.open(ZbHorarioEditorComponent, {
      width: '60%',
      maxHeight: '60vh',
      data: data,
      closeOnNavigation: true,
      autoFocus: true,
    }).afterClosed().subscribe((result) => {
      if(result){
        this.refresh();
      }
    });
  }
  refresh(){
    this.ZbHorariosTable.load();
  }
  onFilterClicked(){
    
  }

}
