import { OnInit, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { CurrentService } from 'path-denuncies-services/current-details.sevice';
import { Router } from '@angular/router';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { PropostesTableComponent } from './components/propostes-table/propostes-table.component';
import { PropostesFilterComponent } from './components/propostes-filter/propostes-filter.component';
import { PropostesService } from 'path-propostes-services/propostes.service';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Builder } from 'path-shared/models/builder';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { NovaOperacioPropostesComponent } from './components/nova-operacio-propostes/nova-operacio-propostes.component';
var PropostesListComponent = /** @class */ (function () {
    function PropostesListComponent(currentService, router, propostesService, dialog, auth, i18n, not, thisDialogRef, data) {
        this.currentService = currentService;
        this.router = router;
        this.propostesService = propostesService;
        this.dialog = dialog;
        this.auth = auth;
        this.i18n = i18n;
        this.not = not;
        this.thisDialogRef = thisDialogRef;
        this.data = data;
        this.details = null;
        this.dataDialog = Builder.newComponentDialog();
        this.hideFilter = false;
        this.autoInit = false;
        this.autoInit30Dias = false;
        this.autoInit60Dias = false;
        if (data !== undefined) {
            this.dataDialog = data;
            if (data.autoInit !== undefined && data.autoInit === true) {
                this.autoInit = true;
                if (data.autoInit30Dias !== undefined && data.autoInit30Dias === true) {
                    this.autoInit30Dias = true;
                }
                if (data.autoInit60Dias !== undefined && data.autoInit60Dias === true) {
                    this.autoInit60Dias = true;
                }
            }
        }
    }
    PropostesListComponent.prototype.ngOnInit = function () {
        if (this.dataDialog.filter !== null && this.dataDialog.filter !== undefined) {
            this.propostesTable.userReincidentsDataSource = false;
            this.propostesTable.filter.set(this.dataDialog.filter);
            this.hideFilter = true;
        }
        if (this.autoInit) {
            var fechaIni = new Date();
            var fechaFin = new Date();
            if (this.autoInit30Dias) {
                fechaFin.setDate(fechaFin.getDate() - 30);
                fechaIni.setDate(fechaIni.getDate() - 365);
            }
            if (this.autoInit60Dias) {
                fechaFin.setDate(fechaFin.getDate() - 30);
                fechaIni.setDate(fechaIni.getDate() - 60);
            }
            var newFilter = this.propostesTable.filter;
            newFilter.proposta = '1'; //estado pendiente
            newFilter.fechaInfraccionInicio = fechaIni;
            newFilter.fechaInfraccionFin = fechaFin;
            this.propostesTable.filter.set(newFilter);
            this.propostesTable.loadPropostesPage(true);
        }
    };
    PropostesListComponent.prototype.ngAfterViewInit = function () {
        if (!this.dataDialog.isDialog) {
            this.not.titleChange(this.i18n('Propostes'));
        }
    };
    PropostesListComponent.prototype.onRowSelected = function (somid) {
    };
    PropostesListComponent.prototype.onFilterClicked = function () {
        var _this = this;
        this.propostesFilterDialogRef = this.dialog.open(PropostesFilterComponent, {
            width: '50%',
            closeOnNavigation: false,
            autoFocus: true,
            data: this.propostesTable.filter
        });
        this.propostesFilterDialogRef.componentInstance.notify.subscribe(function (filter) {
            _this.propostesTable.filter.set(filter);
            _this.propostesTable.loadPropostesPage(true);
        });
    };
    PropostesListComponent.prototype.generateExcel = function () {
        this.propostesTable.generateExcel();
    };
    PropostesListComponent.prototype.updatePropostes = function () {
        this.propostesTable.loadPropostesPage();
    };
    PropostesListComponent.prototype.onPermissionClick = function () {
        this.permissionDialogRef = this.dialog.open(PermissionDialogComponent, {
            width: '90%',
            height: '99%',
            closeOnNavigation: false,
            autoFocus: true,
            data: this.auth.Pages.Propostes,
        });
    };
    PropostesListComponent.prototype.classificarPropostes = function () {
        this.router.navigateByUrl('/propostes/1');
    };
    PropostesListComponent.prototype.NovaOperacio = function () {
        var _this = this;
        var selectedItems = [];
        var _loop_1 = function (item) {
            if (this_1.propostesService.displayedItems[item].checked) {
                selectedItems.push(this_1.propostesTable.dataSource.getData().find(function (x) { return x.SECURE_ID === item; }));
            }
        };
        var this_1 = this;
        for (var item in this.propostesService.displayedItems) {
            _loop_1(item);
        }
        this.propostesService.getRejectSubjects().subscribe(function (motius) {
            _this.dialog.open(NovaOperacioPropostesComponent, {
                width: '50%',
                closeOnNavigation: false,
                autoFocus: true,
                data: {
                    'motius': motius,
                    'data': selectedItems,
                },
            }).afterClosed().subscribe(function (actualitzat) {
                if (actualitzat) {
                    _this.updatePropostes();
                }
            });
        });
    };
    PropostesListComponent.prototype.selectCheckedItems = function () {
        return this.propostesService.displayedItems['checkedValues'];
    };
    return PropostesListComponent;
}());
export { PropostesListComponent };
