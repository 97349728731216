import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { PlanificacioFilter } from './planificacio-filter';
import { Planificacio } from '../planificacio';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { PlanificacioService } from '../services/planificacio.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PlanificacioFilterService } from './planificacio-filter-service';
import { PlanificacioEditorComponent } from '../planificacio-editor/planificacio-editor.component';

@Component({
  selector: 'app-planificacio-table',
  templateUrl: './planificacio-table.component.html',
  styleUrls: ['./planificacio-table.component.scss']
})
export class PlanificacioTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort:MatSort;

  dataSource: CustomDataSource<Planificacio,PlanificacioFilter>;
  filter: PlanificacioFilter= new PlanificacioFilter('SOM_ID','asc');
  displayedColumns = ['Dia','NombreRuta','NombreHorario','NombreAgentes','actions'];
  SelectedDate: Date;
  constructor(
    private dialog:MatDialog,
    private auth: AuthorizationService,
    private planificacioService:PlanificacioService
  ) { 

    this.SelectedDate = new Date();
  }

  ngAfterViewInit():void{
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort,this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();

    this.load();
  }

  load():void{
    this.filter.paginator.set(this.sort,this.paginator);
    this.dataSource.loadFilter(this.filter);
    console.log(this.dataSource);
  }

  ngOnInit() {
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<Planificacio, PlanificacioFilter>(this.paginator,new PlanificacioFilterService(), 'urlGetPlanificacions', 'urlGetPlanificacionsCount');
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        console.log(item);
      });
    });
    
    this.filter.paginator.init(this.sort, this.paginator);

  this.filter.filterUpdated.subscribe(updated => {
    if (updated) {
      this.load();
    }
  });
  this.onDateChange();
  }
  onDateChange(): void {
    this.filter.Dia = this.SelectedDate;
    this.filter.updated();
  }

  editPlanificacio(row){
    const planificacio:Planificacio = row;
    const dialogRef = this.dialog.open(PlanificacioEditorComponent, {
      data:planificacio,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.load();
      }
    });
  }

}
