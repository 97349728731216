import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { HttpSecureClient } from './../../../shared/services/http/httpSecureClient';
import { Injectable } from '@angular/core';
import { PropostaFilter } from '../models/proposta.filter';
import { HttpParams } from '@angular/common/http';
import { PaginationFilter } from 'path-shared/models/pagination.filter';
import { api } from '../../../shared/configuration/urls';
import { config } from 'path-shared/configuration/config';
import { Observable } from 'rxjs';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { DateUtils } from 'path-shared/utils/date-utils';

const defaultSortParam = 'NUMDENUNCIA';
const defaultSortDirection = 'desc';


@Injectable()
export class PropostesFilterService extends CustomFilterService {

  dateUtils = new DateUtils();

  public createFilterParams(filter: PropostaFilter, start: number, pageSize: number, sortField: string, sortDir: string ): HttpParams {
    return new HttpParams()
    .append('proposta', Reflection.hasValue(filter.proposta) ? filter.proposta : '')
    .append('tipusproposta', Reflection.hasValue(filter.tipusproposta) ? filter.tipusproposta : '')
    .append('matricula', Reflection.hasValue(filter.matricula) ? filter.matricula : '')
    .append('numDenuncia', Reflection.hasValue(filter.numeroDenuncia) ? filter.numeroDenuncia : '')
    .append('fechaInfraccionInicio', Reflection.empty(filter.fechaInfraccionInicio) ? null : this.dateUtils.stringToDate(filter.fechaInfraccionInicio).toJSON())
    .append('fechaInfraccionFin', Reflection.empty(filter.fechaInfraccionFin) ? null : this.dateUtils.stringToDate(filter.fechaInfraccionFin).toJSON())
    .append('carrer', Reflection.hasValue(filter.carrer) ? filter.carrer : '')
    .append('dni', Reflection.hasValue(filter.dni) ? filter.dni : '')
    .append('infraccion', Reflection.hasValue(filter.infraccion) ? filter.infraccion : '')
    .append('agent', Reflection.hasValue(filter.agente) ? filter.agente : '')
    .append('aparell', Reflection.hasValue(filter.aparell) ? filter.aparell : '')
    .append('expedient', Reflection.hasValue(filter.expediente) ? filter.expediente : '')
    .append('nombreConductor', Reflection.hasValue(filter.nombreConductor) ? filter.nombreConductor : '')
    .append('Pais', Reflection.hasValue(filter.pais) ? filter.pais : '')
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir)
    ;
  }
}
