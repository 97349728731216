import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { TramitadorHistoricFilter } from '../models/tramitador-historic.filter';
import { ImportacioDenunciesHistoricFilter } from '../models/importacio-denuncies-historic.filter';

const defaultSortParam = '';
const defaultSortDirection = '';

@Injectable()
export class ImportacioDenunciesHistoricFilterService extends CustomFilterService {

  public createFilterParams(filter: ImportacioDenunciesHistoricFilter, start: number, pageSize: number, sortField: string, sortDir: string): HttpParams {
    return new HttpParams()
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir)
    ;
  }
}
