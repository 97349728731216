import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { AuthorizationService } from './../../../../../shared/services/authorization/authorization-service';
import { TipusMatricula } from 'app/sections/ciutat/tipus-matricules/models/tipus-matricula.model';
import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { TipusMatriculesService } from '../../services/tipus-matricules.service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { FormService } from 'path-shared/services/form/form.service';
import { Builder } from 'path-shared/models/builder';
import { ZonesService } from 'app/sections/ciutat/zones/services/zones.service';
import { FormMultiSelect } from 'path-shared/components/form/form-multi-select/model/form-multi-select.model';


@Component({
  selector: 'app-tipus-matricules-editor',
  templateUrl: './tipus-matricules-editor.component.html',
  styleUrls: ['./tipus-matricules-editor.component.scss']
})
export class TipusMatriculesEditorComponent implements OnInit {
  secure_id: string;
  tipusMatricula: TipusMatricula;
  dialogData: ComponentDialog;
  closeButtonText: string;
  zones = [];
  allZonas = Array<FormMultiSelect>();

  @ViewChild('mainForm') private mainForm: ElementRef;

  constructor(
  public auth: AuthorizationService,
  private tipusMatriculesService: TipusMatriculesService,
  private i18n: I18n,
  private toastr: ToastrService,
  private dialog: MatDialog,
  private confirmationDialogService: ConfirmationDialogService,
  public thisDialogRef: MatDialogRef<TipusMatriculesEditorComponent>,
  private formService: FormService,
  private zonesService: ZonesService,
  @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
  ) {
    this.tipusMatricula = Builder.newTipusMatricula();
    this.dialogData = data;
    this.secure_id = this.dialogData.id;
    if (this.dialogData.isReadOnly) {
      this.closeButtonText = this.i18n('Tancar');
    } else {
      this.closeButtonText = this.i18n('Llista');
    }
  }


  ngOnInit() {
    // Carreguem les zones 
    // this.zonesService.getZones().subscribe(result => {
    //   this.zones = [];
    //   if (result !== null) {
    //     result.forEach(element => {
    //       this.zones.push(element);
    //     });
    //   }
    //   this.zones.unshift({'descripcio': ''})
    // });
    if (this.secure_id !== null) {
      this.tipusMatriculesService.getTipusMatricula(this.secure_id).subscribe(data => {
        this.tipusMatricula = data;
        this.getAllZonas();
      });
    }else{
      this.getAllZonas();
    }
    


  }

  save() {
    if (this.formService.allFieldsValid(this.mainForm)) {
      this.tipusMatriculesService.setTipusMatricula(this.tipusMatricula).subscribe(result => {
        this.toastr.info(this.i18n('Tipus de matricula guardat correctament'));
        this.thisDialogRef.close(true);
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  cancel() {
    this.thisDialogRef.close(false);
  }


  remove() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar el tipus de matrícula?'))
    .then((confirmed) => {
      if (confirmed) {
        this.tipusMatriculesService.deleteTipusMatricula(this.secure_id).subscribe(result => {
          this.toastr.success(this.i18n('Tipus de matricula esborrat correctament.'));
          this.dialog.closeAll();
          this.thisDialogRef.close(true);
        });
      }
    });
  }

  getAllZonas() {

    this.zonesService.getZones().subscribe(result => {
      this.allZonas = [];
      result.forEach(element => {

        if (this.tipusMatricula.tiposMatriculas !== undefined && this.tipusMatricula.tiposMatriculas !== null && this.tipusMatricula.tiposMatriculas.find(x => x.item_id === element.SOM_ID.toString())) {
          this.allZonas.unshift(new FormMultiSelect(element.SOM_ID.toString(), element.descripcio));
        } else {
          this.allZonas.push(new FormMultiSelect(element.SOM_ID.toString(), element.descripcio));
        }

        //this.tipusMatricules.push({'codi': element.codi, 'descripcio': element.descripcio});
      });
    });
  }
}
