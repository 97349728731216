/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./proposta-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../proposta-viewer/proposta-viewer.component.ngfactory";
import * as i3 from "../../../proposta-viewer/proposta-viewer.component";
import * as i4 from "../../../../../../shared/services/authentication/auth-guard.service";
import * as i5 from "../../../../../../shared/services/startup/google-maps.service";
import * as i6 from "@angular/material/dialog";
import * as i7 from "../../../../../../shared/services/authorization/authorization-service";
import * as i8 from "../../../../../../shared/services/validation/validation.service";
import * as i9 from "@ngx-translate/i18n-polyfill";
import * as i10 from "../../../../services/propostes.service";
import * as i11 from "ngx-toastr";
import * as i12 from "../../../../../configuracio/motius-desestimar/services/motius-desestimar.service";
import * as i13 from "../../../../../../shared/components/confirmation-dialog/confirmation-dialog.service";
import * as i14 from "@angular/router";
import * as i15 from "../../../../../usuaris/services/usuaris.service";
import * as i16 from "../../../../../../shared/services/parameters/parameters.service";
import * as i17 from "../../../../../../shared/services/startup/startup.service";
import * as i18 from "../../../../../../shared/services/images/images.service";
import * as i19 from "../../../../../../shared/components/photo-box/photo-box.service";
import * as i20 from "../../../../../configuracio/agents/services/agents-service.service";
import * as i21 from "../../../../../../shared/components/dialog/dialog-header/dialog-header.component.ngfactory";
import * as i22 from "../../../../../../shared/components/dialog/dialog-header/dialog-header.component";
import * as i23 from "../../../../../../shared/components/dialog/dialog-body/dialog-body.component.ngfactory";
import * as i24 from "../../../../../../shared/components/dialog/dialog-body/dialog-body.component";
import * as i25 from "@angular/common";
import * as i26 from "./proposta-dialog.component";
var styles_PropostaDialogComponent = [i0.styles];
var RenderType_PropostaDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PropostaDialogComponent, data: {} });
export { RenderType_PropostaDialogComponent as RenderType_PropostaDialogComponent };
function View_PropostaDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-proposta-viewer", [], null, [[null, "update"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("update" === en)) {
        var pd_0 = (_co.update($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PropostaViewerComponent_0, i2.RenderType_PropostaViewerComponent)), i1.ɵdid(1, 638976, null, 0, i3.PropostaViewerComponent, [i4.AuthGuardService, i5.GoogleMapsService, i6.MatDialog, i7.AuthorizationService, i8.ValidationService, i9.I18n, i10.PropostesService, i11.ToastrService, i12.MotiusDesestimarService, i13.ConfirmationDialogService, i14.Router, i15.UsuarisService, i16.ParametersService, i17.StartupService, i18.ImagesService, i19.PhotoBoxService, i20.AgentsService], { proposta: [0, "proposta"] }, { update: "update" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.proposta; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PropostaDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog-header", [], null, null, null, i21.View_DialogHeaderComponent_0, i21.RenderType_DialogHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i22.DialogHeaderComponent, [], { label: [0, "label"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "app-dialog-body", [], null, null, null, i23.View_DialogBodyComponent_0, i23.RenderType_DialogBodyComponent)), i1.ɵdid(3, 114688, null, 0, i24.DialogBodyComponent, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 2, null, View_PropostaDialogComponent_1)), i1.ɵdid(5, 16384, null, 0, i25.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i25.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getTitle(); _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.imagesLoaded)); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_PropostaDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-proposta-dialog", [], null, null, null, View_PropostaDialogComponent_0, RenderType_PropostaDialogComponent)), i1.ɵdid(1, 245760, null, 0, i26.PropostaDialogComponent, [i10.PropostesService, i6.MatDialogRef, i6.MAT_DIALOG_DATA, i9.I18n], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PropostaDialogComponentNgFactory = i1.ɵccf("app-proposta-dialog", i26.PropostaDialogComponent, View_PropostaDialogComponent_Host_0, {}, {}, []);
export { PropostaDialogComponentNgFactory as PropostaDialogComponentNgFactory };
