import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { OperacionAlegacionDto } from 'app/sections/denuncies/models/operacionAlegacionDto';
import { OperacionesModel } from 'app/sections/denuncies/models/operaciones.model';
import { ToastrService } from 'ngx-toastr';
import { Instituciones } from 'path-shared/models/instituciones';
import { FilesService } from 'path-shared/services/files/files.service';
import { FormService } from 'path-shared/services/form/form.service';
import { InstitucionsService } from 'path-shared/services/institucions/institucions.service';

@Component({
  selector: 'app-segundas-alegaciones',
  templateUrl: './segundas-alegaciones.component.html',
  styleUrls: ['./segundas-alegaciones.component.scss']
})
export class SegundasAlegacionesComponent implements OnInit {

  @Output() operationResult: EventEmitter<OperacionesModel> = new EventEmitter<OperacionesModel>();

  dto = new OperacionAlegacionDto();
  @ViewChild('mainForm') private mainForm: ElementRef;
  @ViewChild("fileUpload") fileUpload: ElementRef;
  private file;
  institucions: Instituciones[];
  registroEntrada: string;
  private data: FormData;
  private instit: string;
  @Input() exp: number;

  constructor(
    private i18n: I18n,
    private toastr: ToastrService,
    private formService: FormService,
    private dialog: MatDialog,
    private institucionsService: InstitucionsService,
    private filesService: FilesService
  ) {
  }

  ngOnInit() {
    this.institucionsService.getInstitucions().subscribe(data => {
      this.institucions = data;
      this.instit = this.institucionsService.getCurrentInstitution();
    });
  }

  private fillFormData() {
    const formData = new FormData();
    formData.append('file', this.file);
    formData.append('exp', JSON.stringify(this.exp));
    if (this.registroEntrada != undefined || this.registroEntrada != null)
      formData.append('regentrada_aleg', this.registroEntrada);
    if (this.dto.fechaAlegacion != null || this.dto.fechaAlegacion != undefined)
      formData.append('dataplec', this.dto.fechaAlegacion.toISOString())
    if (this.file != null || this.file != undefined)
      this.file.inProgress = true;
    this.data = formData;
  }

  onFileSelected(file) {
    if (file[0].name.endsWith('.pdf') || file[0].name.endsWith('.pdf')) {
      this.file = file[0];
      
    }
    else {
      this.toastr.warning(this.i18n('El fichero debe ser un PDF'));
    }
  }

  subirFichero(isPrimeras: boolean) {
    this.fillFormData();
    if (this.file === undefined || this.file === null) {
      this.toastr.warning(this.i18n('Hace falta un fichero para subir!'));
    } 
    else {
      if (this.formService.allFieldsValid(this.mainForm)) {
        this.filesService.uploadFile(this.data, isPrimeras).subscribe(
          (result: any) => {
            this.operationResult.emit(result);
          }
        );
      }
      else {
        this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
        if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
          this.mainForm.nativeElement.classList.add('displayErrors');
        }
      }
    }
  }

  fileReady(file) {
    this.file = file;
  }
}
