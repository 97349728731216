import { Proposta } from 'app/sections/propostes/models/proposta.model';
import {Component, OnInit, ViewChild, Inject, AfterViewInit} from '@angular/core';
import {XDENUNCIES} from 'path-models/XDENUNCIES';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {CurrentService} from 'path-denuncies-services/current-details.sevice';
import {Router} from '@angular/router';
import {AuthorizationService} from 'path-shared/services/authorization/authorization-service';
import {PropostesTableComponent} from './components/propostes-table/propostes-table.component';
import {PropostesFilterComponent} from './components/propostes-filter/propostes-filter.component';
import {PropostesService} from 'path-propostes-services/propostes.service';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { PropostaFilter } from '../../models/proposta.filter';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AppComponent } from 'app/app.component';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { NovaOperacioPropostesComponent } from './components/nova-operacio-propostes/nova-operacio-propostes.component';

@Component({
  selector: 'app-propostes-list',
  templateUrl: './propostes-list.component.html',
  styleUrls: ['./propostes-list.component.scss']
})
export class PropostesListComponent implements OnInit, AfterViewInit  {

  details: XDENUNCIES = null;
  propostesFilterDialogRef: MatDialogRef<PropostesFilterComponent>;
  somIdFoo: number;
  filterData: PropostesFilterComponent;
  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  dataDialog: ComponentDialog = Builder.newComponentDialog();
  hideFilter = false;

  @ViewChild(PropostesTableComponent) propostesTable: PropostesTableComponent;
  public autoInit:boolean=false;
  public autoInit30Dias:boolean=false;
  public autoInit60Dias:boolean=false;
  constructor(
    private currentService: CurrentService,
    private router: Router,
    private propostesService: PropostesService,
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<PropostesListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      if (data !== undefined) {
        this.dataDialog = data;
        if (data.autoInit !== undefined && data.autoInit === true) {
          this.autoInit =true;
          if(data.autoInit30Dias !== undefined && data.autoInit30Dias === true) {
            this.autoInit30Dias =true;
          }
          if(data.autoInit60Dias !== undefined && data.autoInit60Dias === true) {
            this.autoInit60Dias =true;
          }
        }
      }
  }

  ngOnInit() {
    if (this.dataDialog.filter !== null && this.dataDialog.filter !== undefined) {
      this.propostesTable.userReincidentsDataSource = false;
      this.propostesTable.filter.set(this.dataDialog.filter);
      this.hideFilter = true;
    }
    if(this.autoInit){
      let fechaIni= new Date();
      let fechaFin= new Date();
      if(this.autoInit30Dias){
        fechaFin.setDate(fechaFin.getDate() - 30)
        fechaIni.setDate(fechaIni.getDate() - 365)
      }
      if(this.autoInit60Dias){
        fechaFin.setDate(fechaFin.getDate() - 30)
        fechaIni.setDate(fechaIni.getDate() - 60)
      }
      let newFilter = this.propostesTable.filter;
      newFilter.proposta ='1';//estado pendiente
      newFilter.fechaInfraccionInicio = fechaIni;
      newFilter.fechaInfraccionFin = fechaFin;
      this.propostesTable.filter.set(newFilter);
      this.propostesTable.loadPropostesPage(true);
    }
}

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Propostes'));
    }
  }

  onRowSelected(somid: number) {

  }

  onFilterClicked() {
    this.propostesFilterDialogRef = this.dialog.open(PropostesFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: this.propostesTable.filter
    });

    this.propostesFilterDialogRef.componentInstance.notify.subscribe((filter: PropostaFilter) => {
      this.propostesTable.filter.set(filter);
      this.propostesTable.loadPropostesPage(true);
    });
  }

  generateExcel() {
    this.propostesTable.generateExcel();
  }

  updatePropostes() {
    this.propostesTable.loadPropostesPage();
  }

  onPermissionClick() {
    this.permissionDialogRef = this.dialog.open(PermissionDialogComponent,
      {
        width: '90%',
        height: '99%',
        closeOnNavigation: false,
        autoFocus: true,
        data: this.auth.Pages.Propostes,
      });
  }

  classificarPropostes() {
    this.router.navigateByUrl('/propostes/1');
  }

  NovaOperacio() {

    const selectedItems = [];
    for (const item in this.propostesService.displayedItems) {
      if (this.propostesService.displayedItems[item].checked) {
        selectedItems.push(this.propostesTable.dataSource.getData().find(x => x.SECURE_ID === item));
      }
    }
    this.propostesService.getRejectSubjects().subscribe( motius => {
      this.dialog.open(NovaOperacioPropostesComponent,
        {
          width: '50%',
          closeOnNavigation: false,
          autoFocus: true,
          data: {
            'motius': motius,
            'data': selectedItems,
          },
        }).afterClosed().subscribe(actualitzat => {
          if (actualitzat) {
            this.updatePropostes();
          }
        });
    });
  }

  selectCheckedItems() {
    return this.propostesService.displayedItems['checkedValues'];
  }
}
