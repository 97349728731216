import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA, MatSort } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Builder } from 'path-shared/models/builder';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { NotificacionTramitarResponseDto } from '../../../../models/notificar/notificacion-tramitar-response.dto';
import { ReportsComponent } from 'path-shared/components/reports/reports.component';
import { ReportService } from 'path-shared/services/report/report.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { ToastrService } from 'ngx-toastr';
import { ResumenNotificacionDto } from '../../../../models/notificar/resumen-notificacion.dto';
import { NotificarService } from 'app/sections/tramitar/expedients/services/notificar/notificar.service';
import { NotificacionsFilter } from 'app/sections/notificacions/models/notificacions-filter';
import { TramitadorReportPDFDto } from 'app/sections/tramitar/expedients/models/notificar/TramitadorReportPDFDto';
import { NotificarComponent } from '../../notificar-page-component/notificar.component';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-listado-notificaciones',
  templateUrl: './listado-notificaciones.component.html',
  styleUrls: ['./listado-notificaciones.component.scss']
})
export class ListadoNotificacionesComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  dataDialog: ComponentDialog = Builder.newComponentDialog();
  dataSource: MatTableDataSource<NotificacionTramitarResponseDto>;
  filter: NotificacionsFilter = new NotificacionsFilter('', '');

  isSicer: boolean;

  reports = [];
  URL_REPORT = 'urlGenerateReportsPDF';

  displayedColumns = ['Elemento', 'Nombre y Apellidos', 'Número Notificación', 'direccion', 'poblacion','cp', 'provincia', 'juridica'];

  resumen: ResumenNotificacionDto;
  URL_REPORT_Generico = 'urlGenerateReport';
  enabledGenerarInforme: boolean = false;
  constructor(
    private dialog: MatDialog,
    private i18n: I18n,
    public auth: AuthorizationService,
    private toastr: ToastrService,
    public thisDialogRef: MatDialogRef<NotificarComponent>,
    private reportService: ReportService,
    private notificarService: NotificarService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data !== undefined) {
      this.dataDialog = data;
      this.dataDialog.isDialog = true;
      this.dataSource = new MatTableDataSource(data.result.notificacionesPendientes);
      this.resumen = data.result.resumen;
      this.reports = data.result.informesPDF;
      this.enabledGenerarInforme = true;
      this.isSicer = data.isSicer;
    }
  }

  ngOnInit() { }

  mostrarInformes(): void {
    this.impressioReport();
  }

  finalizarEnvio(): void {
    this.notify.emit(true);
    this.thisDialogRef.close();
  }

  impressioReport() {
    const data = this.reports;

    const filteredData = [];

    data.forEach((item) => {
      if (item.tipoNoti === this.data.tipoNoti) {
        filteredData.push(item);
      }
    });  
   this.dialog.open(ReportsComponent, {
      width: '30%',
      height: '30%',
      closeOnNavigation: false,
      autoFocus: true,
      data: filteredData  // Use the filtered data
    }).afterClosed().subscribe(data => {
      const reportStructure: TramitadorReportPDFDto = {
        idEnvio : +this.resumen.idEnvio,
        tipoEnvio : data.tipusReport,
        isComuna : true,
        isSicer: this.isSicer,
        nomFitxer : data.nomFitxer,
        tipoArchivo : data.tipoArchivo,
        tipoNoti: data.tipoNoti,
        esVersioPositiva: data.esVersioPositiva
      };

      
      if (data !== undefined && data !=='' ) {
        let usableDataStructure = Object.assign({}, reportStructure);
        for (let [key, value] of Object.entries(reportStructure)) {
            usableDataStructure[key] = reportStructure[key];
        }
        this.reportService.generateReport(usableDataStructure, this.URL_REPORT).subscribe((result: any) => {
          if (result && data.tipoArchivo!=1) {
              const objectUrl=URL.createObjectURL(result.body);
              window.open(objectUrl);
          }    
          else if (data.tipoArchivo === 1) {
            const name = data.nom + '.zip'; 
            const contentType = 'application/zip';
          
            if (contentType !== null && contentType !== undefined) {
              FileDownloader.downLoadFile([result.body], contentType + ';charset=utf-8', name.toString());
            } else {
              this.toastr.error(this.i18n("No s'ha pogut generar el fitxer"));
            }
          }
          
          else {
            // Solo report.
            const name = data.nom;
            const contentType = result.headers.get('Content-Type');
            if (contentType !== null && contentType !== undefined) {
                FileDownloader.downLoadFile([result.body],  contentType + ';charset=utf-8', name.toString());
            } 
            else {
            this.toastr.error(this.i18n('No s\'ha pogut generar el fitxer'));
            }
          }
        });
      }
    });
  }

  //I have all the data in the dataSource, so I can generate the csv without making a new request
  generateCsv(){
    //We could create a const with the column name and display name, but for the moment its not necessary
    let columnNames=['entidad','usuario','idNotificacion','direccion','poblacion','cp','provincia','juridica'];
    let csv='';
    for(let col=0;col<this.displayedColumns.length;col++){
      csv+=this.displayedColumns[col]+";";
      csv = csv.replace(/\n/g, '');
    }
    csv = csv.substring(0, csv.length - 1) + '\n';
    const rows=this.dataSource.data;
    for(let row=0;row<rows.length;row++){
      for(let col=0;col<this.displayedColumns.length;col++){
        csv+=rows[row][columnNames[col]]+";";
        //csv+=Object.values(rows[row])[col]+";";
      }
      csv = csv.substring(0, csv.length - 1) + '\n';
    }
    csv = csv.substring(0, csv.length - 1) + '\n';
    const docElement = document.createElement('a');
    const universalBOM = '\uFEFF';
    let filename = 'Notificaciones_'+ formatDate(new Date(), 'dd-MM-yyyy-', 'ca-ES')+'.csv';
    docElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(universalBOM + csv);
    docElement.target = '_blank';
    docElement.download = filename;
    docElement.click();
  }

}
  