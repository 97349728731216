import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class PropostaFilter extends FilterData {

    // Basic info
    proposta: string;
    tipusproposta: string;

    expediente: string;
    matricula: string;
    agente: string;
    dni: string;
    dniSearchType: string;
    infraccion: string;

    numeroDenuncia: string;
    carrer: string;
    nombreConductor: string;
    nombreSearchType: string;
    esJuridica: Boolean;


    fechaInfraccionInicio: Date;
    fechaInfraccionFin: Date;

    pais: string;
    aparell: string;


    constructor(sortField: string, sortDir: SortDirection) {
      super(sortField, sortDir);
        this.proposta = null;
        this.tipusproposta = '-1';

        this.expediente = '';
        this.matricula = '';
        this.agente = '';
        this.dni = '';
        this.dniSearchType = '';
        this.infraccion = '';

        this.numeroDenuncia = '';
        this.carrer = '';
        this.nombreConductor = '';
        this.nombreSearchType = '';
        this.esJuridica = null;

        this.fechaInfraccionInicio = null;
        this.fechaInfraccionFin = null;

        this.pais = null;
        this.aparell = null;


        this.labels['proposta'] = this.i18n('Proposta');
        this.labels['tipusproposta'] = this.i18n('Estat');

        this.labels['expediente'] = this.i18n('Expedient');
        this.labels['matricula'] = this.i18n('Matrícula');
        this.labels['agente'] = this.i18n('Agent');
        this.labels['dni'] = this.i18n('DNI');
        this.labels['dniSearchType'] = this.i18n('DNI Tipus');

        this.labels['numeroDenuncia'] = this.i18n('Número Denuncia');
        this.labels['codigoAnulacion'] = this.i18n('Codi Anul·lació');
        this.labels['carrer'] = this.i18n('Carrer');
        this.labels['nombreConductor'] = this.i18n('Conductor');
        this.labels['nombreSearchType'] = this.i18n('Tipus Cerca');
        this.labels['esJuridica'] = this.i18n('És Jurídica');

        this.labels['fechaInfraccionInicio'] = this.i18n('Data Infracció Inicial');
        this.labels['fechaInfraccionFin'] = this.i18n('Data Infracció Final');

        this.labels['pais'] = this.i18n('País');
        this.labels['aparell'] = this.i18n('Aparell');

        this.formatOutput['fechaInfraccionInicio'] = 'shortDate';
        this.formatOutput['fechaInfraccionFin'] = 'shortDate';

    }
}
