<div class="row justify-content-between" >
  <div class="col">
    <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="this.filter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
</div>
<div class="row">
  
<app-form-date-edit class="col-sm-2" i18n-label label="Fecha" type="calendar" [(value)]="this.SelectedDate" (valueChange)="onDateChange()"></app-form-date-edit>
</div>
<div class="table-sdw is-fixed">
  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">
  <!--displayedColimns = ['SomId','IdRuta','idHorario','idAgente','Dia','NombreRuta','NombreHorario','NombreAgente'];-->
   
    <ng-container matColumnDef="Dia">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Dia </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.Dia | shortDate}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="NombreRuta">
      <mat-header-cell *matHeaderCellDef> Ruta </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.NombreRuta}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="NombreHorario">
      <mat-header-cell *matHeaderCellDef> Horario </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.NombreHorario}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="NombreAgentes">
      <mat-header-cell *matHeaderCellDef >Agentes </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.NombreAgentes.join(", ")}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="flex-container">
        <button mat-icon-button color="primary" (click)="editPlanificacio(row)"><mat-icon>edit</mat-icon></button>
      </div>
    </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>''
  
  </mat-table>
</div>

