export const api = {
    endpoints: {
        'urldenunciesquery': '/denuncies',
        'urlVdenunciaIndividual': '/vdenuncies/single',
        'urldenunciesDetails': '/denunciesDetails',
        'urlalegacions': '/alegacions',
        'urlpaginationcount': '/denunciescount',
        'urlalegacionspaginationcount': '/alegacionscount',
        'urlrespondrealegacions':'/respondreAlegacions',
        'urldenuncia': '/getDenuncia/{nDenuncia}',
        'urldenunciesImages': '/images',
        'urldenunciesImagesBySomId': '/images-bySomId',
        'urldenunciesImagesNumDenIns': '/images-byNumDenIns',
        'urldenunciesImagesNumDen': '/imagesNumDen',
        'urlgetImage': '/getImage',
        'urlGetImageByRefInteg':'/get_image_by_refinteg',
        'urlDeleteImage' : '/file',
        'urlGetFile' : '/file/{somId}',
        'urlAddImages' : '/images',
        'urlAddImagesBySomId' : '/imagesBySomId',
        'urlAddImagesWithContenedor': '/imatgesCont/{somId}/conten/{contenedor}',
        'urlAddImagesGruaWithContenedor': '/imagesGruaContenedor/{numDenuncia}/conten/{contenedor}',
        'urlAddImagesGrua': '/imagesGrua/{numDenuncia}',
        'urlsaveDenuncia': '/saveDenuncia',
        'urlgenerateExcel': '/generateExcel',
        'urlgetTipoOperacions': '/operacio',
        'urlgetOperacionsForCombo' : '/operacionsCombo',
        'urlgetUsuarisOperacionsForCombo' : '/operacionsUsuarisCombo',
        'urlUploadFileAlegacions' : '/operacionsEntrarAlegacions',
        'urlTramitador': '/tramitador/proba',
        'urlConfigPaisos': '/startup/paisos',
        'urlConfigParametres': '/startup/parameters',
        'urlConfigMapsApiKey': '/startup/mapsApiKey',
        'urlGetPDAs' : '/PDAs',
        'urlAnularOperacio': '/denuncia/{idDenuncia}/operacio/revert',
        'urlAnularSingleOperacio': '/denunciaAnul/{idDenuncia}/operacio/revert/{idOperacio}/somDenun/{somIdDenuncia}',
        'urlGetExpedient' : '/expedient/{id}',
        'urlSaveProcediment' : '/procediment',
        'urlRemoveProcediment': '/procediment/{id}',
        'urlGetDenunciesFormaPago': '/denuncies/formaPago',
        'urlGetDenunciesCobros' : '/denuncies/cobros/{exp}',
        'urlGetDenunciesPagoPorcentajeAumento': '/denuncies/pagoPorcentajeAumento',
        'urlGetSomExpedient' : '/denuncia/expedient/{id}',
        'urlSetDenunciaCobrar': '/denuncia/cobrar',
        'urlSetDenunciaCobrarByC60': '/denuncia/cobrarByC60',
        'urlSetDenunciaCobrarSmartPol': '/denuncia/cobrarSmartPol',
        'urlGetCerrarPagosParciales': '/denuncia/cobrar/cerrarPagosParciales',
        'urlGetAcusos' : '/tramitar/acusos',
        'urlAcusosPaginationCount': '/tramitar/acusos/count',
        'urlInformarAcusos': '/tramitar/acusos/informar',
        'urlInfoTancaments' : '/tramitar/tancaments',
        'urlInfoPendents' : '/tramitar/pendents',
        'urlInfoNotificar' : '/tramitar/notificar',
        'urlInicioProcesoNotificar' : '/tramitar/notificar/fechas-notificacion',
        'urlContinuaProcesoNotificarDenuncias': '/tramitar/notificar/continuacion-proceso/denuncias',
        'urlFinalizaProcesoNotificarDenuncias': '/tramitar/notificar/finalizacion-proceso/denuncias',
        'urlContinuaProcesoNotificarPropuestasResolucion': '/tramitar/notificar/continuacion-proceso/propuestas-resolucion',
        'urlFinalizaProcesoNotificarPropuestasResolucion': '/tramitar/notificar/finalizacion-proceso/propuestas-resolucion',
        'urlProcesaResoluciones' : '/tramitar/notificar/inicia-proceso/resoluciones',
        'urlProcesaFinalizaResoluciones' : '/tramitar/notificar/finaliza-proceso/resoluciones',
        'urlContinuaProcesoNotificarNotificaciconesResoluciones': '/tramitar/notificar/continuacion-proceso/notificacion-resoluciones',
        'urlFinalizaProcesoNotificarNotificacionesResoluciones': '/tramitar/notificar/finalizacion-proceso/notificacion-resoluciones',
        'urlContinuaProcesoNotificarRecursosReposicion': '/tramitar/notificar/continuacion-proceso/recursos-reposicion',
        'urlFinalizaProcesoNotificarRecursosReposicion': '/tramitar/notificar/finalizacion-proceso/recursos-reposicion',
        'urlUndoEnvio': '/tramitar/notificar/deshacer-envio/{secureId}',
        'urlUpdateNotificaciones': '/tramitacion/actualizarNotificacionesEnvio',
        'urlPendents' : '/tramitar/pendentsall',
        'urlTancaments' : '/tramitar/tancamentsall',
        'urlSetTancaments' : '/tramitar/settancaments',
        'urlgetLimitarExecutiva': '/tramitar/getLimitarExecutiva',
        'urlgetComptadorsExecutiva': '/tramitar/ComptadorsaZero',
        'urlTramitarExOlot': '/tramitar/RevEjeOlot',
        'urlInfoTramitarExOlot': '/tramitar/RevEjeOlotTotal',
        'urlLoadG5': '/tramitar/LoadG5',
        'TraspassarExecutivaOlot': '/tramitar/traspassarExeOlot',
        'urlTraspassarExecutivaInforme': '/tramitar/traspassarExecutivesInforme',
        'urlTraspassarExecutivaExcel': '/tramitar/traspassarExecutivesExcel',
        'urlTraspassarExecutivaRecaptacio': '/tramitar/traspassarExecutivesRecaptacio',
        'urlGetExecutives': '/tramitar/getExecutives',
        'urlGetExecutivesCount': '/tramitar/getExecutivesCount',
        'urlTipusEnvioTramitar': '/tramitar/GetTipusEnvio',
        'urlGenerateReportEjecutivas': '/generateReport/tramitarexecutiva',
        'urlGetEnviosTramitacion': '/tramitacion/enviosGestionar',
        'urlGetEnviosTramitacionCount': '/tramitacion/enviosGestionar/count',
        'urlCargarRespuestasSicer': '/tramitacion/getRespuestasSicer',
        'urlCargarProcesarRespuestasSicer': '/tramitacion/procesarRespuestaSicer',
        'urlEnvioPuntosDGT': '/tramitacion/envioPuntosDGT',
        'urlEnvioDetallePuntosDGT': '/tramitacion/getEnvioPuntosDGT/{secureId}',
        'urlSendResponseFileDGT': '/tramitacion/sendResponseEnvioPuntosDGT',
        'urlGetNotificacionesPuntosDGT': '/tramitacion/getNotificacionesPuntosDGT',
        'urlGetNotificacionesPuntosDGTCount': '/tramitacion/getNotificacionesPuntosDGT/count',
        'urlGetEnviosPuntosDGT': '/tramitacion/getEnvioPuntosDGT',
        'urlGetEnviosPuntosDGTCount': '/tramitacion/getEnvioPuntosDGT/count',
        'urlreEnvioPuntosDGT': '/tramitacion/reenvioPuntosDGT',
        'urlGetRowsEnvio': '/tramitacion/enviosGestionar/getRows/{secureId}',
        'urlGetConfiguracionBoe': '/tramitacion/configuracionBOP',
        'urlGetDenunciasBoe': '/tramitacion/denunciasBoe',
        'urlGetConfirmacionBoeFilter': '/tramitacion/confirmacion',
        'urlGeneratePublishBoe': '/tramitacion/denunciasBoe/generatePublishBOE',
        'urlGetConfirmacionBoeFilterCount': '/tramitacion/confirmacion/count',
        'urlGetDetailConfirmacion': '/tramitacion/detailConfirmacion/getRows',
        'urlGetDetailConfirmacionCount': '/tramitacion/detailConfirmacion/getRows/count',
        // 'urlGetRowsEnvio': '/tramitacion/enviosGestionar/getRows/{secureId}',
        // 'aurlGetRowsEnvio': '/tramitacion/enviosGestionar/getRows/{secureId}',
        // 'urlGetRowsEnvio': '/tramitacion/enviosGestionar/getRows/{secureId}',
        'urlGetIdDenunByExp' : '/denuncies/GetIdDenunByExp',
        'getDenunciaAutoLogin': '/denuncies/Autologin/{tradesegurId}',
        'urlpropostesquery': '/propostes',
        'urlPropostesPaginationcount': '/propostes/count',
        'urlPropostesCount': '/propostes/count',
        'urlPropostesTipus': '/propostes/tipus',
        'urlPropostesAccept': '/propostes/accept',
        'urlPropostesReject': '/propostes/reject',
        'urlPropostesImages': '/propostes/{num}/images',
        'urlPropostesCounters': '/propostes/counters',
        'urlPropostesTipusCounters': '/propostes/types/counters',
        'urlPropostesPaisosCounters': '/propostes/countries/counters',
        'urlGetTipusPropostes': '/propostes/tipus',
        'urlPropostesMotiusDesestimar': '/propostes/motius_desestimar',
        'urlPropostesExcel': '/propostes/excel',
        'urlPropostesSave': '/propostes/save/{secure_id}',
        'urlPropostesNotificadaEnMa': '/propostes/notificadaEnMa',
        'urlPropostesCanviMatricula': '/propostes/{secure_id}/matricula',
        'urlReincidentsQuery': '/propostes/reincidents',
        'urlGetPropostaReincidencies': '/propostes/{secure_id}/reincidencies',
        'urlReincidentsPaginationcount': '/propostes/reincidents/count',
        'urlGetPropostesRelacionades': '/propostes/{secure_id}/relacionades',
        'urlPropostesRemoveImages' : '/propostes/{secure_id}/removeImages',
        'urlPropostesValidarMatriculaAutorizada' : '/propostes/validarMatriculaAutoritzada/{secureId}/{matricula}',

        'urlStoreOperacio': '/denuncia/{idDenuncia}/operacio/{idOperacion}',
        'urlStoreOperacioMasiva': '/denuncia/operacio/{idOperacion}',
        'urlStoreOperacioMasivaBySomId': '/denuncia/operacio/bySomId/{idOperacion}',

        'token': '/token',
        'urlCreateDenuncia': '/createdenuncia',
        'tiposdenuncia': '/startup/tiposdenuncia',
        'tiposdenunciante': '/startup/tiposdenunciante',
        'urlInstituciones': '/startup/instituciones',
        'urlInstitucion': '/startup/instituciones/{id}',
        'urlInstitucionesByUserAuthenticated': '/startup/institucionesbyauthuser',

        'urlUser': '/user',
        'urlUserLite' : '/userLite',
        'urlUserChangePwd' : '/user/changePwd',
        'urlUserEnable2fa': '/user/enable2fa',
        'urlUserGet2faSecret': '/user/get2faSecret',
        'urlUserVerify2faOtp' : '/user/verify2faOtp',
        'urlGetUsers': '/users',

        'urlGetPermissionItems': '/permissions/{code}',
        'urlSetPermissionItems': '/permission',
        'urlDeletePermissionItems': '/permissions/{secure_id}',
        'urlGetGroups': '/groups',

        'urlDashboard': '/dashboard',
        'urlDashboardTramitacion': '/dashboard/tramitacion',
        'urlDashboardResumenTramitacion': '/dashboard/resumentramitacion',
        'urlRecalcularDashboardTramitacion': '/dashboard/recalcularTramitacion',
        'urlDashBoardContadoresDup': '/dashboard/ContadoresDup',
        'urlDashBoardErrorFiles': '/dashboard/DashBoardErrorFiles',
        'urlDashBoardMoveContadoresDup': '/dashboard/MoveContadoresDup',
        'urlDashBoardFicherosPendienteImagen': '/dashboard/ficherosPendienteImagen',
        'urlDashBoardMoveFicherosPendienteImagen': '/dashboard/moveFicherosPendienteImagen',

        'urlmatriculapaginationcount': '/matricules/count',
        'urlgetmatriculas': '/matricules',
        'urlGetMatricula': '/matricules/{secure_id}',
        'urlDeleteMatricula': '/matricules/{secure_id}',
        'urlSetMatricula': '/matricules',
        'urlCreateMatricula': '/matricules/create',
        'urlMatriculesExcel': '/matricules/excel',
        'urlMatriculesLoad': '/matricules/load',
        'urlGetMatriculaHistoric': '/matricules-historic',
        'urlGetMatriculaHistoricCount': '/matricules-historic/count',

        'urlGetTipusMatricula': '/tipus-matricules/{secure_id}',
        'urlGetTipusMatricules': '/tipus-matricules',
        'urlTipusMatriculesPaginationCount': '/tipus-matricules/count',
        'urlSetTipusMatricula': '/tipus-matricules',
        'urlDeleteTipusMatricula': '/tipus-matricules/{secure_id}',
        'urlTipusMatriculesExcel': '/tipus-matricules/excel',
        'urlGetTipusMatriculaByInstitutuion': '/tipus-matricules/institution/{id}',

        'urlGetDeteccioMatricules': '/deteccio-matricules',
        'urlDeteccioMatriculesPaginationCount': '/deteccio-matricules/count',
        'urlDeteccioMatriculesExcel': '/deteccio-matricules/excel',
        'urlDeteccioMatriculesImatge': '/deteccio-matricules/{secure_id}/{n}/{suffix}',

        'urlGetAgent': '/agents/{secure_id}',
        'urlGetAgents': '/agents',
        'urlGetAllAgents': '/allAgents',
        'urlAgentsPaginationCount': '/agents/count',
        'urlDeleteAgent': '/agents/{secure_id}',
        'urlSetAgent': '/agents',
        'urlAgentsExcel': '/agents/excel',
        'urlUpladFileAgent': '/agents/{somId}/UploadFile',
        'urlGetActuacionsAgents': '/agents/actuacions',
        'urlGetActuacionsAgentsCount': '/agents/actuacionscount',

        'urlGetPersones': '/persones',
        'urlGetAllPersones': '/allPersones',
        'urlPersonesPaginationCount': '/persones/count',
        'urlGetPersona': '/persones/{secure_id}',
        'urlSetPersona': '/persones',
        'urlDeletePersona': '/persones/{secure_id}',
        'urlPersonesExcel': '/persones/excel',
        'urlGetDomicilis': '/persones/domicilis/{secure_id}',
        'urlSetDomicili': '/persones/domicili',
        'urlDeleteDomicili': '/persones/domicili/{secure_id}',
        // 'urlGetVehicles': '/persones/vehicles/{secure_id}',
        'urlGetPersonByNif': '/personesGetInfo/{nif}',
        'urlGetEmpresaByCif': '/EmpresesGetInfo/{cif}',
        // vehicle
        'urlGetVehicles': '/vehicles',
        'urlGetAllVehicles': '/allVehicles',
        'urlVehiclesPaginationCount': '/vehicles/count',
        'urlGetVehicle': '/vehicle/{matricula}',
        'urlSetVehicle': '/vehicle',
        'urlDeleteVehicle': '/vehicles/{matricula}',
        'urlGetMarcasResumen': '/vehicles/marques',
        'urlGetTipoVehiculo': '/vehicles/tipoVehiculos',
        'urlGetPaisesCombo': '/vehicles/paises',
        'urlVehiclesExcel': '/vehicles/excel',
        'urlcheckExistByMatricula': '/vehicles/exist/{matricula}',
        // end vehicle
        'urlGetDgt': '/UtilityDgt',
        'urlGetDgtMatricula': '/UtilityDgtMatricula/{matricula}',
        'urlGetDgtPersona': '/UtilityDgtPersona/{persona}',
        'urlGetDgtEucaris': '/UtilityDgtEucaris/{eucaris}',
        'urlGetDgtPropietario': '/UtilityDgtPropietario/{propietario}',
        'urlGetDgtXMLMatricula': '/UtilityDgtMatricula/excel/{matricula}',
        'urlGetDgtXMLPersona': '/UtilityDgtPersona/excel/{persona}',
        'urlGetDgtXMLEucaris': '/UtilityDgtEucaris/excel/{eucaris}',
        'urlGetDgtXMLPropietario': '/UtilityDgtPropietario/excel/{propietario}',
        'urlUpdateDgtPropietario': '/UtilityDgtUpdateVehiculoPersona',
        'urlTramitadorDenuncies': '/tramitador',
        'urlTramitadorInfo': '/tramitador/info',
        'urlTramitadorCount': '/tramitador/count',
        'urlTramitadorTramitar': '/tramitador/tramitar',
        'urlTramitadorGetExportacion': '/tramitador/exportar/{idHistorico}',
        'urlTramitadorHistoric': '/tramitador/historic',
        'urlTramitadorHistoricCount': '/tramitador/historic/count',
        'urlTramitadorHistoricDetall' : '/tramitador/historic/{id}/detall',
        'urlTramitadorHistoricDetallCount' : '/tramitador/historic/{id}/detall/count',
        'urlTramitadorHistoricAnular': '/tramitador/historic/anular/{id}',

        'urlGetNotification': '/notification',

        'urlGetAparells': '/aparell',
        'urlGetAparellsCombo' : '/aparell/all',
        'urlGetCountAparell': '/aparell/count',
        'urlSetAparell': '/aparell',
        'urlSetLocalizacion': '/addLocalizacion',
        'urlDeleteLocalizacion': '/deleteLocalizacion/{id}',
        'urlGetLocalizacion': '/localizaciones',
        'urlGetLocalizacionesAparell': '/localizaciones/aparell/{idAparell}',
        'urlGetLocalizacionPropuestas' : '/localizaciones/propuestas',
        'urlLocalizacionSetActiu' : '/localizaciones/setActiu/{id}',
        'urlDeleteAparell': '/aparell/{id}',
        'urlGetAparell': '/aparell',
        'urlAparellExcel': '/aparell/excel',
        'urlGetAparellTipusRadar': '/aparell/radar/tipus',
        'urlRTAI': '/aparell/RTAI',
        'urlRTAC': '/aparell/RTAC',

        'urlGetLegislacions': '/legislacio',
        'urlGetLegislacionsForCombo': '/legislacioIdDescripcio',
        'urlGetCountLegislacions': '/legislacio/count',
        'urlSetLegislacio': '/legislacio',
        'urlDeleteLegislacio': '/legislacio/{id}',
        'urlGetLegislacio': '/legislacio/{id}',
        'urlLegislacioExcel': '/legislacio/excel',


        'urlGetMotiusAnulacio': '/motius-anulacio',
        'urlGetCountMotiusAnulacio': '/motius-anulacio/count',
        'urlGetMotiuAnulacio': '/motius-anulacio/{secure_id}',
        'urlSetMotiuAnulacio': '/motius-anulacio',
        'urlDeleteMotiuAnulacio': '/motius-anulacio/{secure_id}',
        'urlMotiusAnulacioExcel': '/motius-anulacio/excel',
        'urlGetMotiuAnulacioCombo': '/motius-anulacio/combo',

        'urlGetMotiusDesestimar': '/motius-desestimar',
        'urlGetCountMotiusDesestimar': '/motius-desestimar/count',
        'urlGetMotiuDesestimar': '/motius-desestimar/{secure_id}',
        'urlSetMotiuDesestimar': '/motius-desestimar',
        'urlDeleteMotiuDesestimar': '/motius-desestimar/{secure_id}',
        'urlMotiusDesestimarExcel': '/motius-desestimar/excel',
        'urlGetMotiuDesestimarCanviMatricula': '/motius-desestimar/canvi_matricula',

        'urlGetAlarmsGroup': '/alarmes/group',
        'urlGetAlarmes': '/alarmes',
        'urlGetAlarmesHistoric': '/alarmesHistoric',
        'urlGetAlarmesByAparell': '/alarmes/{secureIdAparell}',
        'urlAlarmesPaginationCount': '/alarmes/count',
        'urlAlarmesHistoricPaginationCount': '/alarmesHistoric/count',
        'urlAckAlarma': '/alarmes/ack/{secure_id}',
        'urlAlarmesExcel': '/alarmes/excel',
        'urlGetAlarmsTipus': '/alarmes/tipus',
        'urlAckAlarms': '/alarmes/ack',
        'urlGetAlarmesGps': '/alarmesGps/{data}',
        'urlGetTipusAlarmesWithGps': '/alarmes/tipusWithGps',
        'urlSetAlarmaVista': '/alarmes/setAlarmaVista',
        'urlAlarmaEmail': '/alarmes/email',
        'urlGetLeidoAlarma': '/alarmes/leido/{id}',

        'urlSetSigla': '/siglas',
        'urlDeleteSigla': '/siglas/{secure_id}',
        'urlGetSigla': '/siglas/{secure_id}',
        'urlSiglasExcel': '/siglas/excel',
        'urlGetSiglas': '/siglas',
        'urlGetSiglasCount': '/siglas/count',

        'urlSetTipusVehicle': '/tipusVehicles',
        'urlDeleteTipusVehicle': '/tipusVehicles/{secure_id}',
        'urlGetTipusVehicle': '/tipusVehicles/{secure_id}',
        'urlTipusVehiclesExcel': '/tipusVehicles/excel',
        'urlGetTipusVehicles': '/tipusVehicles',
        'urlGetTipusVehiclesCombo': '/tipusVehiclesCombo',
        'urlGetTipusVehiclesCount': '/tipusVehicles/count',

        'urlSetColor': '/colors',
        'urlDeleteColor': '/colors/{secure_id}',
        'urlGetColor': '/colors/{secure_id}',
        'urlColorsExcel': '/colors/excel',
        'urlGetColors': '/colors',
        'urlGetColorsCount': '/colors/count',

        'urlGetMarques': '/marques-vehicles',
        // 'urlGetAllMarques': '/marques-vehicles/all',
        'urlGetCountMarques': '/marques-vehicles/count',
        'urlSetMarca': '/marques-vehicles',
        'urlDeleteMarca': '/marques-vehicles/{secure_id}',
        'urlGetMarca': '/marques-vehicles/{secure_id}',
        'urlMarcaExcel': '/marques-vehicles/excel',
        'urlSetModelo' : '/models-vehicles',
        'urlGetModelos': '/models-vehicles/{secure_id}',
        'urlDeleteModelo': '/models-vehicles/{secure_id}',
        'urlGetAllMarques': '/all-marques-vehicles',

        'urlGetInfraccions' : '/infraccions',
        'urlGetCountInfraccions' : '/infraccions/count',
        'urlGetInfraccio' : '/infraccions/{secure_id}',
        'urlSetInfraccio' : '/infraccions',
        'urlDeleteInfraccio' : '/infraccions/{secure_id}',
        'urlInfraccionsExcel' : '/infraccions/excel',
        'urlGetInfraccionsForCombo': '/infraccioIdDescripcio',
        'urlGetInfraccionsDenunciesCombo': '/infraccions/combo',
        'urlGetInfrLegi' : '/infraccionsLegi/{legi}',
        'urlGetInfraccioTipusGravetat' : '/infraccions/tipusGravetat',
        'urlGetInfraccioTipus' : '/infraccions/tipusInfraccio',
        'urlGetInfraccioDiesDescompte' : '/infraccions/diesDescompte',

        'urlGetPaisos': '/paisos',
        'urlGetPaisosCombo': '/GetPaisosCombo',
        'urlGetPaisosCount': '/paisos/count',
        'urlSetPais': '/paisos',
        'urlDeletePais': '/paisos/{secure_id}',
        'urlGetPais': '/paisos/{secure_id}',
        'urlPaisosExcel': '/paisos/excel',
        'urlGetProvinciasCombo': '/GetProvinciasCombo',
        'urlGetProvinciasComboByPais': '/GetProvinciasComboByPais/{secure_idPais}',
        'urlGetMunicipiosCombo': '/GetMunicipiosCombo',
        'urlGetMunicipiosComboByProv': '/GetMunicipiosComboByProv/{secure_idProv}',


        'urlGetZones' : '/zones',
        'urlGetDetalleZona' : '/zones/detalleZona/{secure_id}',
        'urlDeleteZona' : '/zones/deleteZona/{secure_id}',
        'urlGetCarrersZona' : '/carrersZona/{zona}',
        'urlGetZonesPaginationCount' : '/zones/count',
        'urlZonesExcel' : '/zones/excel',
        'urlGetZonesByCarrer' : '/zones/carrer/{secureIdCarrer}',
        'urlGetAllZonas': '/allZonas',
        'urlGetTarifasPaginationCount' : '/tarifas/count',
        'urlGetTiketPaginationCount' : '/tiket/count',
        'urlSetZones' : '/zones',
        'urlGetTarifas' : '/getTarifas',
        'urlGetTipotiket' : '/getTiket',

        'urlSetCarrer': '/carrers',
        'urlGetCarrer': '/carrers/{secure_id}',
        'urlGetCarrers': '/carrers',
        'urlGetCarrersPaginationCount': '/carrers/count',
        'urlDeleteCarrer': '/carrers/{secure_id}',
        'urlGetCarrersForCombo' : '/carrersCombo',

        'urlSetAparellTipus': '/aparellTipus',
        'urlDeleteAparellTipus': '/aparellTipus/{secure_id}',
        'urlGetAparellTipus': '/aparellTipus/{secure_id}',
        'urlAparellTipusExcel': '/aparellTipus/excel',
        'urlGetAparellsTipus': '/aparellTipus',
        'urlGetAparellsTipusCount': '/aparellTipus/count',

        'urlImportacioDenuncies': '/importacioDenuncia',
        'urlImportacioDenunciesUploadFile': '/importacioDenuncies/UploadFile/{formatImportacio}',

        'urlGetEstadisticaSetmanal': '/estadistiques/setmanal',
        'urlGetEstadisticaMensual': '/estadistiques/mensual',
        'urlGetAparellsEstadistiques': '/estadistiques/aparells',
        'urlGetEstadisticasAparcareReservas': '/estadistiques/aparcare/reserves',
        'urlGetEstadisticasAparcare': '/estadistiques/aparcareEstadisticas',
        'urlGetEstadisticasDenuncias': '/estadistiques/denunciasEstadisticas',
        'urlGetEstadisticasVarios': '/estadistiques/varios',
        'urlGetEstadisticasAgents': '/estadistiques/agents',
        'urlGetEstadisticasAparcareExcel': '/estadistiques/aparcareEstadisticas/excel',
        'urlGetEstadisticasDenunciasExcel': '/estadistiques/denunciasEstadisticas/excel',
        'urlGetTracking': '/tracking',
        'urlGetTrackingByDay': '/trackingByDay/{data}',
        'urlSincronitzar': '/sincronitzacio',
        'urlLastSyncDate': '/sincronitzacio/sync-dates',
        'urlSyncDiba': '/sincronitzacio/diba',
        'urlSyncGtwin': '/sincronitzacio/gtwin',
        'urlGetTerminals': '/comunicacioPda/terminals',
        'urlGetTerminalsChat': '/comunicacioPda/terminals/chat/{ultimIdChat}',
        'urlSetTerminalsChatMissatge': '/comunicacioPda/terminals/chat/missatge',
        'urlUploadFitxersTerminal': '/comunicacioPda/terminals/uploadFile',
        'urlGetFitxerTerminal': '/comunicacioPda/terminals/getFile/{idFitxer}',
        'urlSetMissatgesTerminalsLlegits': '/comunicacioPda/terminals/llegits',
        'urlGetTerminalChatMissatgesNous': '/comunicacioPda/terminals/chat/missatgesNous/{terminalId}',


        'urlAnulacionZonaAzulAuto': '/AnulAutoZB',
        'urlAnulacionZonaAzulAutoCount' : '/AnulAutoZB/count',
        'urlAnulacionZonaAzulAutoProcessar' : '/AnulAutoZB/processar',
        'urlAnulAutoZBHistoric' : '/AnulAutoZB/historic',
        'urlAnulAutoZBHistoricCount' : '/AnulAutoZB/historic/count',
        'urlAnulacionsHistoricDetall' : '/AnulAutoZB/historicDetall/{id}',
        'urlAnulacionsHistoricDetallCount' : '/AnulAutoZB/historicDetall/count/{id}',
        'urlGetAnulacio': '/GetAnulacio/{nDenuncia}',
        'urlGetAnulacionesFromFile': '/GetAnulacionesFromFile',
        'urlSetAnulacio': '/ProcessarAnulacio',
        'urlAnulacionValorada': '/ProcessarAnulacioValorada',
        'urlRecalculateAmount':'/anul/recalculateAmount',

        'urlGetPdaNotTracked': '/GetPdaNotTracked/{data}',
        'urlGetPdaConfig': '/GetPdaActives/{data}',
        'urlSetEstatPda': '/setEstatPda',
        'urlTrackPdaManual': '/TrackPdaManual',
        'urlTrackPdaAlways': '/TrackPdaAlways',
        'urlUnTrackPdaAlways': '/UnTrackPdaAlways',
        'urlUnTrackPdaManual': '/UnTrackPdaManual',
        'urlGetInfoByPoint': '/GetInfoByPoint',
        'urlSetParametro': '/parametro',

        'urlAvisos': '/Avisos',
        'urlGetAvisosPaginationCount' : '/AvisosCount',
        'urlNomFitxersAvis': '/Avisos/getFilesName/{idAvis}',
        'urlAvisosExcel': '/Avisos/Excel',
        'AvisosTipus': '/AvisosTipusConf',
        'urlDeleteAvisosTipus' : '/AvisosTipusConf/{idAvis}',
        'urlCarpetesAvisos' : '/Avisos/Carpetes',
        'urlGetFitxerAvisos' : '/Avisos/getFile/{idFitxer}',

        'urlReports' : '/reports/{page}',
        'urlGenerateReport' : '/generateReport',
        'urlGenerateReportDenuncias' : '/generateReport/denuncias',
        'urlGenerateReportDeposito' : '/generateReport/deposito/{secureId}/{reportId}',
        'urlGenerateReportTramitarExpedientsDenuncia' : '/generateReport/tramitar/expedients/denuncia',
        'urlGenerateReportDenunciaTramitacio' : '/generateReport/generateReportTramitacioDenuncia',
        'urlGenerateReportDenunciaInfo' : '/generateReport/denunciaInfo',
        'urlGenerateReportCartaPago':'/generateReport/denunciasCartaPago',

        'urlGetReportsPDF' : '/reportsPDF/reportsPDF/{isComuna}',
        'urlGenerateReportsPDF' : '/reportsPDF/generateReportsPDF',
        'urlGenerateReportPDF' : '/reportsPDF/generateReportPDF',
        
        'urlGetTiquets' : '/tiquets',
        'urlGetTiquetsCount' : '/tiquets/count',
        'urlGetTiquetsLeyenda' : '/tiquets/leyenda',
        'urlGetProveidorsTiquets': '/tiquets/proveidors',
        'urlGetReserves' : '/reserves',
        'urlGetReservesCount' : '/reserves/count',
        'urlGetResumenReservas' : '/reserves/resumen/{fechaConsulta}',
        'urlAnularReserva' : '/reserves/anular/{secure_id}',
        'urlReservesExcel' : '/reserves/excel',
        'urlGetDevolucions' : '/devolucions',
        'urlGetDevolucionsCount' : '/devolucions/count',
        'urlGetDevolucionsLeyenda' : '/devolucions/leyenda',
        'urlGetAnulacions' : '/anulacions',
        'urlGetAnulacionsCount' : '/anulacions/count',
        'urlGetAnulacionsLeyenda' : '/anulacions/leyenda',
        'urlTiquetsExcel' : '/tiquets/excel',
        'urlTiquetsDevolucionesExcel' : '/tiquets/devoluciones/excel',
        'urlTiquetsAnulacionesExcel' : '/tiquets/anulaciones/excel',

        'urlGetFestius' : '/festius',
        'urlGetFestiusCount' : '/festius/count',
        'urlSetFestiu' : '/festius',
        'urlGetFestiu' : '/festius/{secure_id}',
        'urlDeleteFestiu' : '/festius/{secure_id}',
        'urlPublicarFestiu' : '/festius/publicar',

        'urlGetTipusTiquets' : '/tipusTiquets',
        'urlGetTipusTiquetsCount' : '/tipusTiquets/count',
        'urlSetTipusTiquet' : '/tipusTiquets',
        'urlGetTipusTiquet' : '/tipusTiquets/{secure_id}',
        'urlDeleteTipusTiquet' : '/tipusTiquets/{secure_id}',
        'urlPublicarTipusTiquets' : '/tipusTiquets/publicar',
        'urlGetSeguretatUsuaris': '/seguretatUsuaris',
        'urlGetSegUsuari': '/segUsuaris',
        'urlGetldapConfig':'/seguretatUsuarisLDAP',
        'urlGetSeguretatUsuarisCount': '/seguretatUsuaris/count',
        'urlSetSeguretatUsuaris': '/seguretatUsuaris',
        'urlGetSeguretatUsuari': '/seguretatUsuaris/{secure_id}',
        'urlDeleteSeguretatUsuari' : '/seguretatUsuaris/{secure_id}',
        'urlSeguretatUsuarisExcel': '/seguretatUsuaris/excel',
        'urlGetSeguretatUsuarisPerfils': '/seguretatUsuaris/perfils',
        'urlGetSeguretatUsuarisGrups' : '/seguretatUsuaris/grups',
        'urlGetSeguretatUsuarisAgents' : '/seguretatUsuaris/agents',
        'urlGetSeguretatUsuarisInstitucioAssignada' : '/seguretatUsuaris/institucioAsignada',
        'urlDeleteSeguretatFormsUsuari' : '/seguretatUsuaris/deletePantallaUsuario/{secure_id}',
        'urlDeleteSeguretatGrupUsuari' : '/seguretatUsuaris/deleteGrupUsuario/{codiUser}/{secure_id}',

        'urlGetSeguretatGrups': '/seguretatGrups',
        'urlGetSeguretatGrupsCount': '/seguretatGrups/count',
        'urlGetSeguretatGrup': '/seguretatGrups/{secure_id}',
        'urlSeguretatGrupsExcel': '/seguretatGrups/excel',
        'urlSetSeguretatGrup' : '/seguretatGrups',
        'urlDeleteSeguretatGrup' : '/seguretatGrups/{secure_id}',
        'urlGetSeguretatPantallas' : '/seguretatGrups/pantallas',
        'urlDeleteSeguretatPantallaUsuario' : '/seguretatGrups/deletePantallaUsuario/{secure_id}',

        'urlGetComprovacioTiquets': '/comprovacioTiquets',
        'urlExcelConsutaTickets': '/comprovacioTiquetsExcel',

        'urlGetRecaudacions': '/zonaBlava/recaudacio',
        'urlGetRecaudacionsCount': '/zonaBlava/recaudacio/count',
        'urlGetRecaudacio': '/zonaBlava/recaudacio/{secure_id}',
        'urlSetRecaudacio' : '/zonaBlava/recaudacio',
        'urlDeleteRecaudacio': '/zonaBlava/recaudacio/{secure_id}',
        'urlGetRecaudacioExpenedorInfo': '/zonaBlava/recaudacio/expenedorInfo/{secure_id}/{expenedor}',
        'urlGetRecaudacioZones': '/zonaBlava/recaudacio/zones',
        'urlGetRecaudacioMaquinesParkeon': '/zonaBlava/recaudacio/maquinesParkeon',
        'urlRecaudacioExcel': '/zonaBlava/recaudacio/excel',

        'urlGetNotificacions': '/notificacions',
        'urlGetNotificacionsCount': '/notificacions/count',
        'urlGetNotificacio': '/notificacions/{secureId}',
        'urlImagesNotificacio' : '/images/{somId}',
        'urlRespostesNotificacio' : '/notificacions/respostes/{notificacioId}/{element}',

        'urlGetNotificacionsDenuncies': '/notificacions/notificacions-denuncies',
        'urlGetNotificacionsDenunciesCount': '/notificacions/notificacions-denuncies/count',

        'urlGetResoluciones':'api/notificacions/resoluciones/',
        'urlGetRseolucionesCount':'api/notificacions/resoluciones/count',


        // GRUA
        'urlGetPropostesPendents': '/grua/propostesPendents/{orden}',
        'urlGetAcceptProposta': '/grua/acceptPropostaPendent',
        'urlGetCreateProposta': '/grua/createProposta',
        'urlGetRejectProposta': '/grua/rejectPropostaPendent',
        'urlSaveRegistroGrua': '/grua/saveRegistroGrua',
        'urlDeleteRegistroGrua': '/grua/propostaGrua/{secure_id}',
        'urlGetMotiusDesestimarGrua': '/grua/allMotiusDesestimar',
        'urlGetAllTecnics': '/grua/allTecnics',
        'urlGetAllMotius':  '/grua/allMotius',
        'urlGetGruaTecnics' : '/gruaTecnics',
        'urlGetGruaTecnicsCount' : '/gruaTecnics/count',
        'urlSetGruaTecnics' : '/gruaTecnics',
        'urlDeleteGruaTecnics' : '/gruaTecnics/{secure_id}',
        'urlGetGruaTecnic' : '/gruaTecnics/{secure_id}',
        'urlGruaTecnicsExcel' : '/gruaTecnics/excel',
        'urlGetSomPolDipositGrua': '/grua/diposit',
        'urlGetSomPolDipositGruaCount': '/grua/diposit/count',
        'urlGetSomPolDipositGruaStock': '/grua/diposit/stock',
        'urlGetSomPolDipositGruaStockCount': '/grua/diposit/stock/count',
        'urlGetAllTipusVehicle': '/grua/allTipusVehicle',
        'urlSetVehiculoDepositoDto': '/grua/diposit/',
        'urlCobrarVehiculoDepositoDto': '/grua/dipositCobrar/',
        'urlGruaTramitadesExcel': '/grua/Tramitades/excel',
        'urlGruaDipositExcel': '/grua/Diposit/excel',
        'urlSetTarifaGrua': '/grua/Tarifa',
        'urlDeleteTarifaGrua': '/grua/Tarifa/{secure_id}',
        'urlGetTarifaGrua': '/grua/Tarifa/{secure_id}',
        'urlTarifaGruaExcel': '/grua/Tarifa/excel',
        'urlGetTarifesGrua' : '/grua/tarifes',
        'urlGetTarifesGruaCount' : '/grua/tarifes/count',

        'urlGetSomPolGruaPendents' : '/grua/somPolGruaPendents',
        'urlGetSomPolGruaPendentsCount': '/grua/somPolGruaPendents/count',

        'urlGetGruaMotiusDesestimar' : '/gruaMotiusDesestimar',
        'urlGetGruaMotiusDesestimarCount' : '/gruaMotiusDesestimar/count',
        'urlSetGruaMotiusDesestimar' : '/gruaMotiusDesestimar',
        'urlDeleteGruaMotiusDesestimar' : '/gruaMotiusDesestimar/{secure_id}',
        'urlGetGruaMotiuDesestimar' : '/gruaMotiusDesestimar/{secure_id}',
        'urlGruaMotiusDesestimarExcel' : '/gruaMotiusDesestimar/excel',
        'urlGetDiposits': '/grua/getDiposits',
        'urlGetTiposCobro': '/grua/getTiposPagoGrua',
        'urlGetDesperfectos': '/grua/GetDesperfectos/{secureId}',
        'urlGetDesperfectosV2': '/grua/GetDesperfectosV2/{codi}',
        'urlsetDesperfectos': '/grua/SetDesperfectos',
        'urlDeletetDesperfectos': '/grua/Desperfectos',
        'urlGetImages': '/grua/imagesConten/{somId}/conten/{contenedor}',

        'urlGetGruaMotiusEntrada' : '/gruaMotiusEntrada',
        'urlGetAllGruaMotiusEntrada' : '/allGruaMotiusEntrada',

        // 'urlGetGruaMotiusEntrada' : '/gruaMotiusEntrada',
        'urlGetGruaMotiusEntradaCount' : '/gruaMotiusEntrada/count',
        'urlSetGruaMotiusEntrada' : '/gruaMotiusEntrada',
        'urlDeleteGruaMotiusEntrada' : '/gruaMotiusEntrada/{secure_id}',
        'urlGetGruaMotiuEntrada' : '/gruaMotiusEntrada/{secure_id}',
        'urlGruaMotiusEntradaExcel' : '/gruaMotiusEntrada/excel',


        'urlGetAliesGruaForCombo' : '/aliesGruaForCombo',
        'urlGetGruaAliesCount' : '/gruaAlies/count',
        'urlSetGruaAlies' : '/gruaAlies',
        'urlDeleteGruaAlies' : '/gruaAlies/{secure_id}',
        'urlGetGruaAlies' : '/gruaAlies/{secure_id}',
        'urlGruaAliesExcel' : '/gruaAlies/excel',
        'calculatePriceDeposito' : '/gruaDeposito/calculatePriceDeposito',

        'urlGetSomPolGruaTramitades' : '/grua/somPolGruaTramitades',
        'urlGetSomPolGruaTramitadesCount': '/grua/somPolGruaTramitades/count',
        'UrlRotateImage' : '/images/rotate/{somId}',
        'UrlCropImage': '/images/crop/{somId}',
        'UrlSetBrightnessImage' : '/images/brightness/{somId}',
        'UrlSetMatriculasImage' : '/images/{somId}/denuncia/{numDenuncia}/matriculas/{matricula}',
        'UrlSetTodasMatriculasImage' : '/imagesTodas/denuncia/{numDenuncia}/matriculas/{matricula}',

        'urlGetTarifes' : '/tarifes',
        'urlGetTarifesCombo' : '/tarifes/combo',
        'urlGetTarifesCount' : '/tarifes/count',
        'urlSetTarifa' : '/tarifes',
        'urlGetTarifa' : '/tarifes/{secure_id}',
        'urlDeleteTarifa' : '/tarifes/{secure_id}',
        'urlPublicarTarifa' : '/tarifes/publicar',
        'urlDescargarDatosTarifa' : '/tarifes/descargar-datos',
        'urlGetDataTarifes' : '/tarifes/data',
        'urlDeleteTarifaTimeFrame' : '/tarifes/timeframe/{secure_id}',

        'urlGetZonesAparcare' : '/zones-aparcare',
        'urlGetZonesAparcareCount' : '/zones-aparcare/count',
        'urlSetZonaAparcare' : '/zones-aparcare',
        'urlGetZonaAparcare' : '/zones-aparcare/{secure_id}',
        'urlPublicarZonesAparcare' : '/zones-aparcare/publicar',
        'urlDescargarDatosZonesAparcare' : '/zones-aparcare/descargar-datos',
        'urlGetAllZonesAparcare': '/all-zones-aparcare',
        'GetImagesByContenedor' : '/imagesby/{contenedor}',
        'urlGetImageByPath' : '/get_image_by_path/',
        'urlAddImagesByContenedor' : '/imagesby/{contenedor}',
        'urlTramitadorGetRutaFichero' : '/tramitador/exportar/rutaFichero/{idHistorico}',
        'urlTramitadorGetTiposDenuncia' : '/tramitador/tiposDenuncias',
        'UrlGetFileRuta' : '/file/getFile',

        'urlUnreadReleasedNotes' : '/dashboard/releasedNotes',
        'urlUpdateLastReleasedNote' : '/dashboard/releasedNotes/{SomId}',

        'urlGetAlarmasSistemaBase': '/alarmes/base/{fechaConsulta}',
        'urlDesactivaAlarmasSistemaBase': '/alarmes/base',
        'urlGetAlarmasGpsBase': '/alarmesGps/base/{fechaConsulta}',
        'urlDesactivaAlarmasGpsBase': '/alarmesGps/base',
        'urlGetDenunciasBase': '/denuncies/base/{fechaConsulta}/{esPolicia}',
        'urlDesactivaDenunciasBase': '/denuncies/base/{esPolicia}',
        'urlGetPropuestasGruaBase': '/grua/propostes',
        'urlGetProposta': '/grua/propostes/{id}',
        'urlDesactivaPropuestasGruaBase': '/grua/propostes',
        'urlGetTrackingBase': '/tracking/base/{fechaConsulta}/{isPda}',
        'urlDesactivaTrackingBase': '/tracking/base/{isPda}',
        'urlGetPoligonosOcupacionByFecha': '/tramos/all/{fechaHora}',
        'urlGetTurnos': '/Turnos',
        'urlGetTurnosCount': '/Turnos/count',
        'urlGetTurno': '/turno/{secure_id}',
        'urlGetAllTurnos': '/turno/all',
        'urlDeleteTurno': '/turno/{secure_id}',
        'urlSetTurno': '/turno',
        'urlIniciarTurno': '/IniciaTurno',
        'urlGetLastTurnActive': '/turno/UltUser/{UserID}',
        'getRegistroTurnoUsuarioByID': '/turnoById/{idTurno}',
        'urlGetReportTurnoUsuarioByID': '/generateReport/gruaFinTurno/{secureId}',
        'urlFinalizarTurno': '/FinalizarTurno',

        'urlSetTramoTipo': '/tramosTipo',
        'urlDeleteTramoTipo': '/tramosTipos/{secure_id}',
        'urlGetTramoTipo': '/tramosTipo/{secure_id}',
        'urlGetTramosTipos': '/tiposTramos',
        'urlGetTramosTiposCount': '/tramosTipos/count',
        'urlTramosTiposExcel': '/tramosTipos/excel',
        'urlGetAllTramoTipo': '/tramosAllTipos',

        'urlSetTramo': '/tramos',
        'urlDeleteTramo': '/tramos/{secure_id}',
        'urlGetTramo': '/tramos/{secure_id}',
        'urlGetTramos': '/tramos',
        'urlGetTramosCount': '/tramos/count',
        'urlTramosExcel': '/tramos/excel',
        'urlGetAllTramos': '/AllTramos',
        'urlGetAllTramosByTemporada': '/AllTramosByTemporada/{secure_id}',
        'urlGetPublicarTramos': '/publishTramos',

        'urlSetMaquina': '/maquinas',
        'urlDeleteMaquina': '/maquinas/{secure_id}',
        'urlGetMaquina': '/maquinas/{secure_id}',
        'urlGetMaquinas': '/maquinas',
        'urlGetMaquinasCount': '/maquinas/count',
        'urlGetAllMarcaMaquina': '/MarcasMaquinas/all',
        'urlMaquinaExcel': '/maquinas/excel',
        'urlGetFicherosMaquina': '/maquinas/ficheros',
        'urlDeleteFilesMaquina': '/maquinas/ficheros/{ids}',
        'urlPostFileMaquina': '/maquinas/ficheros',
        'urlGetIncidenciesMaquina': '/maquinas/incidencies',
        'urlGetIncidenciesMaquinaCount': '/maquinas/incidencies/count',
        'urlGetIncidenciesMaquinaById': '/maquinas/incidencies/{id}',
        'urlGetActuacionsById': '/incidencies/actuacions/{id}',
        'urlNewActuacio': '/incidencies/actuacions/insert',
        'urlResoldreIncidenciesMaquina': '/maquinas/incidencies/resoldre',

        'urlGetAllTipoSenalesVerticales': '/senalesverticales/tipos/all',
        'urlGetTipoSenalesVerticales': '/senalesverticales/tipos',
        'urlGetTipoSenalesVerticalesCount': '/senalesverticales/tipos/count',
        'urlDeleteTipoSenalVertical': '/senalesverticales/tipos/{secureId}',
        'urlSetTipoSenalVertical': '/senalesverticales/tipos',
        'urlGetTipoSenalVertical': '/senalesverticales/tipos/{secureId}',
        'urlTipoSenalVerticalExcel': '/senalesverticales/tipos/excel',

        'urlGetSenalesVerticales': '/senalesverticales',
        'urlGetSenalesVerticalesCount': '/senalesverticales/count',
        'urlGetSenalVertical': '/senalesverticales/{secureId}',
        'urlSetSenalVertical': '/senalesverticales',
        'urlDeleteSenalVertical': '/senalesverticales/{secureId}',
        'urlSenalesVerticalesExcel': '/senalesverticales/excel',
        'urlGetFicherosSenalvertical': '/senalesverticales/ficheros',
        'urlDeleteFilesSenalVertical': '/senalesverticales/ficheros/{ids}',
        'urlPostFileSenalVertical': '/senalesverticales/ficheros',
        'urlGetAuditoriaActiva': '/auditoria/{name}',
        'urlSetAuditoriaActiva': '/auditoria/{name}/toggle/',
        'UrlGetTablaAuditoria': '/auditoria/tabla',
        'UrlGetTablasAsociadasAuditoria' : '/auditoria/tablasAsociadas',
        'UrlGetTablaAuditoriaCount': '/auditoria/tabla/count',
        'urlGetAparellsListasBlancas': '/aparell/listasBlancas',
        // 'urlPropostesAnulDesest' : '/propostes/anulDesest',

        'urlGetTemporadas': '/temporadas',
        'urlGetTemporadasCount': '/temporadas/count',
        'urlGetTemporada': '/temporadas/{secureId}',
        'urlSetTemporada': '/temporadas',
        'urlDeleteTemporada': '/temporadas',
        'urlGetTramosByTemporadaCount' : '/getTemporadasTramosCount/{secureId}',
        'urlTemporadaExcel': '/temporadas/excel',
        'urlClonarTemporada': '/temporadas/clonar',

        'urlGetBancCarreguesC60': '/banc/carreguesC60',
        'urlGetBancCarreguesC60Count': '/banc/carreguesC60/count',
        'urlGetBancCarregaC60': '/banc/carregaC60/{secure_id}',
        'urlSetBancCarregaC60': '/banc/carregaC60',
        'urlGetBancCarregaDetallC60': '/banc/carregaC60/detall',
        'urlGetBancCarregaDetallC60Count': '/banc/carregaC60/detall/count',
        'urlGetBancCarregaDetallResumC60Count': '/banc/carregaC60/detall/resum/{secure_id}',
        'urlBancCarreguesC60Excel': '/banc/carreguesC60/excel',
        'urlBancCarreguesProcesarRegistro': '/banc/carreguesC60/processar',
        'urlBancCarreguesProcesarV2': '/banc/carreguesC60/processarV2',
        'urlComprovarEstadoFichero': '/banc/carreguesC60/ComprovarFichero',
        'urlcheckRegistrosErorresC60': '/banc/carreguesC60/CheckRegistrosErorresC60',
        'urlFinalizarCargaC60':  '/banc/carreguesC60/finalizarCargaC60',
        'urlGetAllBancCarregaC60': '/banc/AllCarregaC60/{secure_id}',
        'urlGetAllBancCarregaParaReprocesarC60':  '/banc/AllCarregaReprocessarC60/{secure_id}',
        'urlGetAllBancCarregaParaReprocesarCerradosC60':  '/banc/AllCarregaReprocessarCerradosC60/{secure_id}',
        'urlBancCarreguesReprocesarRegistro': '/banc/carreguesC60/reprocessar',
        'urlBancCarreguesReprocesarRegistroCerrado': '/banc/carreguesC60/reprocessarCerrados',
        'urlGetIncidenciasBancCarregaC60': '/banc/carreguesC60/incidencias',
        'urlPropostesAnulDesest' : '/propostes/anulDesest',

        'urlGetTextosInformativos': '/textos-informativos',
        'urlGetTextosInformativosCount': '/textos-informativos/count',
        'urlSetTextoInformativo': '/textos-informativos',
        'urlGetSiglasTextos': '/textos-informativos/lenguajes',
        'urlGetTextosAviso': '/textos-aviso',
        'urlGetTextosAvisoCount': '/textos-aviso/count',
        'urlSetTextoAviso': '/textos-aviso',
        'urlTramitadorGetMidaExportsAntiguos': '/tramitador/getMidaExportsAntiguos',
        'urlTramitadorEliminarExportsAntiguos' : '/tramitador/eliminarExportsAntiguos/{id}',
        'urlSaveConfigurationFilters': '/shared/configurationFilters',
        'urlGetConfigurationFilters': '/shared/configurationFilters',


        'urlGetEscalaOcupacion': '/escalas-ocupacion',
        'urlGetEscalaOcuacionAll': '/escalas-ocupacion/all',
        'urlGetEscalaOcupacionCount': '/escalas-ocupacion/count',
        'urlGetColoresEscala': '/escalas-ocupacion/colores',
        'urlSetEscalaOcupacion': '/escalas-ocupacion',

        'urlImportacioDenunciesHistoric': '/importacioDenuncies/historic',
        'urlImportacioDenunciesHistoricCount': '/importacioDenuncies/historic/count',

        'urlGetAutomatitzacions': '/automatitzacions',
        'urlSetAutomatitzacions': '/automatitzacions',
        'urlGetMotiusNoNotificacio' : '/motius-notificacio',
        'urlGetMotiusNoNotifcacioCount' : '/motius-notificacio/count',
        'urlSetMotiuNoNotificacio' : '/motius-notificacio',
        'urlDeleteMotiuNoNotificacio' : '/motius-notificacio/{secure_id}',
        'urlGetMotiuNoNotificacio' : '/motius-notificacio/{secure_id}',
        'urlGetAllMotiuNoNotificacio' : '/motius-notificacio/combo',
        'urlMotiusNoNotificacioExcel' : '/motius-notificacio/excel',


        'urlGetExpedientesMatriculaSinProp': '/cargaMatriculas/expMatriculaSinProp',
        'urlGetExpedientesMatriculaSinPropCount': '/expmatriculescount',
        'urlCargaExpedientesMatriculaSinProp': '/cargaMatriculas/expMatriculaSinPropAndUpdate',
        'urlGetDgtErrorsMatricules': '/UtilityDgtMatricula/cargaMatriculas/errors',
        'urlGetExpedientesPredefinidas': '/expedientes/predefinidas',
        'urlGetExpedientesPredefinidasCount': '/expedientes/predefinidas/count',
        'urlTramitarMasivo': '/expedientes/predefinidas/tramitar',
        'urlTramitarMasivoSingle': '/expedientes/predefinidas/tramitar-single',

        'urlSetTipusEnviament': '/tipusEnviaments',
        'urlDeleteTipusEnviament': '/tipusEnviaments/{secure_id}',
        'urlGetTipusEnviament': '/tipusEnviaments/{secure_id}',
        'urlTipusEnviamentsExcel': '/tipusEnviaments/excel',
        'urlGetTipusEnviaments': '/tipusEnviaments',
        'urlGetTipusEnviamentsCount': '/tipusEnviaments/count',

        'urlSetResposta': '/respostes',
        'urlDeleteResposta': '/respostes/{secure_id}',
        'urlGetResposta': '/respostes/{secure_id}',
        'urlRespostesExcel': '/respostes/excel',
        'urlGetRespostes': '/respostes',
        'urlGetRespostesCount': '/respostes/count',

        'urlGestioResidentsCites': '/gestio-residents/cites',
        'urlGetBonosResidentes':    '/gestio-residents/bonos',
        'urlGetBonosResidentesPaginationCount':    '/gestio-residents/bonosCount',
        'urlGestioResidentsBonosFilterInfo': '/gestio-residents/bonos/info',
        'urlBonosResidentesExcel': '/gestio-residents/bonos/excel',
        'urlGestioResidentsComprovarPadro': '/gestio-residents/comprovar-padro',

        'urlSetObservacioPda': '/observacions-pda',
        'urlDeleteObservacioPda': '/observacions-pda/{secure_id}',
        'urlGetObservacioPda': '/observacions-pda/{secure_id}',
        'urlObservacionsPdaExcel': '/observacions-pda/excel',
        'urlGetObservacionsPda': '/observacions-pda',
        'urlGetObservacionsPdaCount': '/observacions-pda/count',

        'urlSendHelpEmail': '/ajuda/enviarEmail',
        'urlGetOrigensDenuncia': '/denuncies/origens',
        'urlCarregarExpedientsJustificant': '/denuncies/carregar/expedientsJustificant',
        'urlCarregarExpedientsExterns': '/denuncies/carregar/expedientsExterns',
        'urlGetEstadisticasReincidencias': '/estadistiques/reincidencies',
        'urlGetCodigosPostales': '/codigosPostales',
        'urlGetMarcasCombo' : '/marcas/combo',
        'urlGetModelosCombo' : '/modelos/combo',
        'urlGetColoresCombo' : '/colores/combo',

        'urlGetCobros' : '/banc/cobros',
        'urlCobrosExcel' : '/banc/cobros/excel',
        'urlOperacionGenerarC60': '/denuncies/operacionGeneraC60',
        'urlPasarOCR' : '/propostes/pasarOCR',

        'urlGetBonos': '/bonos',
        'urlBonosPaginationCount': '/bonos/count',
        'urlBonosExcel': '/bonos/excel',
        'urlGetDiasCaducidadPassword': '/diasCaducidadPassword/{idInstit}',
        'urlGetForzarContraseñaSuperSegura': '/forzarContraseñaSegura/{idInstit}',
        'urlSolicitarRetiradaGrua': '/grua/solRetiradaFromDenuncia/{secureId}',
        'urlHasGruaAgent' : '/grua/hasAgent',
        'urlallestasGrua' : '/allestasGrua',
        'urlGetSomPolDesplacatsGrua': '/grua/desplacats',
        'urlGetSomPolDesplacatsGruaCount': '/grua/desplacats/count',
        'urlGruaDesplacatsExcel': '/grua/desplacats/excel',
        'urlGetSomPolSenseRetiradaGrua': '/grua/sense-retirada',
        'urlGetSomPolSenseRetiradaGruaCount': '/grua/sense-retirada/count',
        'urlGruaSenseRetiradaExcel': '/grua/sense-retirada/excel',
        'urlGetMotivosNoRetirada': '/grua/no-retirada',
        'urlUpdatePropietarioDenuncia': '/dgt/updatePropietarioDenuncia',
        'urlGruaSaveComentarioDesperfecto':'/grua/desperfectos/save-comentario',
        'urlGetPropostesAnulacioZB':'/zonaBlava/propostesAnul',
        'urlGetPropostesAnulacioZBCount':'/zonaBlava/propostesAnul/count',
        'getImagesPropostaAnul' : '/zonaBlava/propostesAnul/images/{secure_id}',
        'updatePropouestAnul': '/zonaBlava/propostesAnul',

        'urlSector':'/deltacar-sector',
        'urlGetDeltacarHistorico':'/deltacar-historico',
        'urlDeltacarHistoricoPaginationCount':'/deltacar-historico/count',
        'urlDeltacarHistoricoExcel':'/deltacar-historico/excel',
        
        'urlGetTextosPredefinidos':'/textosPredefinidos',
        'urlGetTextosPredefinidosCount':'/textosPredefinidos/count',
        'urlSetTextoPredefinido': '/texto-predefinido',
        'urlDeleteTextoPredefinido':'/texto-predefinido/{secure_id}',
        'urlGetTextoPredefinido':'/texto-predefinido/{secure_id}',
        'urlTextosPredefinitsExcel':'/texto-predefinido/excel',

        //GestioAgents
        'urlGetZbHorarios': '/zbHorarios',
        'urlGetZbHorariosCount': '/zbHorarios/count',
        'urlSetZbHorario': '/addZbHorario',
        'urlSetZbRuta': '/addZbRuta',
        'urlGetZbRutas': '/zbRutas',
        'urlGetZbRutasCount': '/zbRutas/count',
        'urlGetPlanificacions': '/Planificacions',
        'urlGetPlanificacionsCount': '/Planificacions/count',
        'urlGetAllZbRutas': '/allZbRutas',
        'urlGetAllZbHorarios': '/allZbHorarios',
        'urlSetPlanificacio':'/addPlanificacio',
        'urlGetResolucions':'/notificacions/resoluciones/',
        'urlGetResolucionsCount':'/notificacions/resoluciones/count',
    }

};

