<app-toolbar> 
  <app-tool-button text="Llista" i18n-text (click)="this.goLlista()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
  <app-upload-tool-button-component text="Subir fichero" i18n-text (uploadFile)="this.uploadFile($event)"  color="has-color-blue" icon="fas fa-folder-open" ></app-upload-tool-button-component>
  <app-tool-button text="Reenviar puntos" i18n-text color="has-color-darkblue" src="assets/images/dgtBig.png" (click)="reenviarPuntos()" ></app-tool-button>
</app-toolbar>

<div class="row justify-content-between">
  <div class="col"><app-filter-selector [filter]="this.filter"></app-filter-selector></div>
  <div class="col">
    <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="this.filter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
</div>
<div class="row">
  <div class="col-4">
    <div class="table-sdw is-fixed">

      <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">
          <ng-container matColumnDef="select">
            <mat-header-cell style="width:10%" *matHeaderCellDef mat-sort-header i18n></mat-header-cell>
            <mat-cell style="width:10%" *matCellDef="let row"><app-form-check-edit [value]="row.selected" disabled></app-form-check-edit></mat-cell>
          </ng-container>
    
          <ng-container matColumnDef="remesa">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Remesa</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.remesa}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="fecha">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Fecha</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.fecha | longDate }}</mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" (click)="seleccionarFila(row)" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
        </mat-table>
    </div>
  </div>

  <div class="col-8">
    <div *ngIf="this.detail !== undefined && this.detail !== null">
      <div class="custom-box">
         <div class="row" style="width: 100%;">
          <div class="col-xl-12" style="padding-left: 15px; overflow-y: scroll;overflow-x: auto;height: 500px;">
            <mat-table #table [dataSource]="this.detail.registros" aria-label="Elements" class=" table" style="width: 100%;">
              
              <ng-container matColumnDef="expediente">
                <mat-header-cell *matHeaderCellDef>Expediente</mat-header-cell>                  
                <mat-cell class="cCell" *matCellDef="let element"> {{element.expediente}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="estado">
                <mat-header-cell *matHeaderCellDef>Estado</mat-header-cell>                  
                <mat-cell class="cCell" *matCellDef="let element"> {{element.estado}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="incidencia">
                <mat-header-cell *matHeaderCellDef>Incidencia</mat-header-cell>                  
                <mat-cell class="cCell" *matCellDef="let element"> {{element.incidencia}} </mat-cell>
              </ng-container>
    
          
              <mat-header-row *matHeaderRowDef="displayedColumnsDetail; sticky: true" ></mat-header-row>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsDetail;"></tr>
            </mat-table>
          </div>
      </div> 
    </div>
  </div>
</div>

