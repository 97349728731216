import {Component, OnInit} from '@angular/core';
import {MatTabsModule} from '@angular/material/tabs';

@Component({
  selector: 'app-gestionar',
  templateUrl: './gestionar.component.html',
  styleUrls: ['./gestionar.component.scss']
})
export class GestionarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
