import { Injectable } from '@angular/core';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { Planificacio } from '../planificacio';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';

@Injectable({
  providedIn: 'root'
})
export class PlanificacioService {
 

  constructor(
    private http: HttpSecureClient,
    private authGuard:AuthGuardService
  ) { }

  setPlanificacion(Planificacio: Planificacio) {
    if(this.authGuard.canActivate()){
      return this.http.post(config.urls['apiUrl']+api.endpoints['urlSetPlanificacio'],Planificacio);
    }
  }
}
