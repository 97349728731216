import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NotificacionsDenunciaFilter } from 'app/sections/notificacions/models/NotificacionDenuncia/notificacions-denuncia-filter';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notificaciones-denuncia-filter',
  templateUrl: './notificaciones-denuncia-filter.component.html',
  styleUrls: ['./notificaciones-denuncia-filter.component.scss']
})
export class NotificacionesDenunciaFilterComponent implements OnInit {
  subscription: Subscription;
  filter: NotificacionsDenunciaFilter;
  @Output() notify: EventEmitter<NotificacionsDenunciaFilter> = new EventEmitter<NotificacionsDenunciaFilter>();

  constructor( 
    public thisDialogRef: MatDialogRef<NotificacionesDenunciaFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.filter = data;
  }
  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.thisDialogRef.close();
    //this.notify.emit(this.filter);
  }

  
  ngOnInit() {
  }

}
