var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OperacionsComponent } from './components/operacions/operacions.component';
import { DenunciesImagesComponent } from '../denuncies-editor/components/denuncies-images/denuncies-images.component';
import { NovaOperacioComponent } from './components/nova-operacio/nova-operacio.component';
import { CarrersComponent } from '../../../configuracio/carrers/pages/carrers/carrers.component';
import { ExpedientsComponent } from '../../../expedients/expedients.component';
import { ConfirmationDialogService } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.service';
import { DenunciesService } from 'path-denuncies-services/denuncies.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { AgentsComponent } from 'app/sections/configuracio/agents/pages/agents/agents.component';
import { AgentsEditorComponent } from 'app/sections/configuracio/agents/components/agents-editor/agents-editor.component';
import { Builder } from 'path-shared/models/builder';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { CalleEditorComponent } from 'app/sections/configuracio/carrers/components/calle-editor/calle-editor.component';
import { InfraccionsEditorComponent } from 'app/sections/configuracio/Infraccions/components/infraccions-editor/infraccions-editor.component';
import { InfraccioComponent } from 'app/sections/configuracio/Infraccions/pages/infraccio.component';
import { PersonesEditorComponent } from 'app/sections/ciutat/persones/components/persones-editor/persones-editor.component';
import { PersonesComponent } from 'app/sections/ciutat/persones/pages/persones/persones.component';
import { PersonesService } from 'app/sections/ciutat/persones/services/persones.service';
import { PersonaFilter } from 'app/sections/ciutat/persones/models/persona-filter';
import { Dictionary } from 'path-shared/models/dictionary';
import { ComboBoxInputComponent } from 'path-shared/components/form/combo-box-input/combo-box-input.component';
import { ZonesService } from 'app/sections/ciutat/zones/services/zones.service';
import { VehiclesEditorComponent } from 'app/sections/ciutat/vehicles/components/vehicles-editor/vehicles-editor.component';
import { VehiclesComponent } from 'app/sections/ciutat/vehicles/pages/vehicles/vehicles.component';
import { XmlTreeViewerComponent } from 'app/sections/utilitats/dgt/components/xml-tree-viewer/xml-tree-viewer.component';
import { DgtFilterComponent } from 'app/sections/utilitats/dgt/components/dgt-filter/dgt-filter.component';
import { NotificacionsComponent } from 'app/sections/notificacions/components/notificacions.component';
import { StartupService } from 'path-shared/services/startup/startup.service';
import { InfraccioService } from 'app/sections/configuracio/Infraccions/services/infraccions-service.service';
import { FormService } from 'path-shared/services/form/form.service';
import { DenunciesCobrarService } from 'path-denuncies-services/denuncies-cobrar.service';
import { ComboBoxInputAutocompleteComponent } from 'path-shared/components/form/combo-box-input-autocomplete/combo-box-input-autocomplete.component';
import { CarrersService } from 'app/sections/configuracio/carrers/services/carrers-service.service';
import { ReportsComponent } from 'path-shared/components/reports/reports.component';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { ReportService } from 'path-shared/services/report/report.service';
import { MapComponent } from 'app/sections/livemap/components/map/map.component';
import { CustomDataMarker } from 'app/sections/livemap/models/custom-data-marker';
import { DenunciesTableComponent } from '../denuncies-table/denuncies-table.component';
import { DenunciaFilter } from '../../models/denuncia.filter';
import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute, Router } from '@angular/router';
var DenunciesEditorComponent = /** @class */ (function () {
    function DenunciesEditorComponent(router, toastr, confirmationDialogService, dialog, route, denunciesService, personesService, zonesService, startupService, denunciesCobrarService, formService, auth, i18n, data, thisDialogRef, carrersService, infraccionsService, reportService) {
        var _this = this;
        this.router = router;
        this.toastr = toastr;
        this.confirmationDialogService = confirmationDialogService;
        this.dialog = dialog;
        this.route = route;
        this.denunciesService = denunciesService;
        this.personesService = personesService;
        this.zonesService = zonesService;
        this.startupService = startupService;
        this.denunciesCobrarService = denunciesCobrarService;
        this.formService = formService;
        this.auth = auth;
        this.i18n = i18n;
        this.data = data;
        this.thisDialogRef = thisDialogRef;
        this.carrersService = carrersService;
        this.infraccionsService = infraccionsService;
        this.reportService = reportService;
        this.reports = [];
        this.URL_REPORT = 'urlGenerateReportPDF';
        this.URL_REPORT_CARTAPAGO = 'urlGenerateReportCartaPago';
        this.denunciesImages = [];
        this.secure_id_domiciliConductor = '';
        this.mapDisabled = true;
        // Emits
        this.deleted = new EventEmitter();
        this.updated = new EventEmitter();
        this.imatgesCount = 0;
        this.domicilisConductor = Array();
        this.domicilisPropietari = Array();
        this.domicilisTutorLegal = Array();
        this.hasGruaAgent = false;
        if (this.data !== undefined && this.data.id !== undefined) {
            this.dialogData = data;
            this.id = parseInt(this.dialogData.id, 10);
        }
        else {
            this.id = this.route.snapshot.params.id;
        }
        this.isReadOnly = true;
        this.denunciaDetail = Builder.newDenunciaDetail();
        this.reportService.getReportsHTML(true).subscribe(function (res) {
            _this.reports = res;
        });
        this.denunciesService.hasGruaAgent().subscribe(function (res) {
            _this.hasGruaAgent = res;
        });
    }
    DenunciesEditorComponent.prototype.ngOnInit = function () {
        this.refrescar();
    };
    DenunciesEditorComponent.prototype.refrescar = function () {
        this.valorAutoCompleteCalleDictionary = new BehaviorSubject('');
        this.valorAutoCompleteInfraccionesDictionary = new BehaviorSubject('');
        this.tipusDenunciesDictionary = new BehaviorSubject(new Array());
        this.tipusGravetatDictionary = new BehaviorSubject(new Array());
        this.domicilisConductorDictionary = new BehaviorSubject(new Array());
        this.domicilisTutorLegalDictionary = new BehaviorSubject(new Array());
        this.domicilisPropietariDictionary = new BehaviorSubject(new Array());
        this.zonesComboDictionary = new BehaviorSubject(new Array());
        this.getDenunciaDetall();
        // Aquest l'estem guardant al sessionStorage
        this.loadDiesDescompte();
    };
    DenunciesEditorComponent.prototype.getDenunciaDetall = function () {
        var _this = this;
        this.isReadOnly = true;
        var denuncia = this.denunciesService.getDenunciaDetails(this.id).subscribe(function (detailsdata) {
            if (detailsdata.hora !== undefined && detailsdata.hora != null) {
                var arrayHora = detailsdata.hora.split(':');
                if (arrayHora.length === 2) {
                    _this.timeStartAt = new Date();
                    _this.timeStartAt.setHours(arrayHora[0], arrayHora[1]);
                }
            }
            _this.denunciaDetail = detailsdata;
            if ((_this.denunciaDetail.latitud != '0' && _this.denunciaDetail.longitud != '0')
                && _this.denunciaDetail.latitud !== null && _this.denunciaDetail.longitud !== null) {
                _this.mapDisabled = false;
            }
            // this.imatgesCount = detailsdata.SOM_FICHEROS.length;
            _this.operaciones.dataSource.data = _this.denunciaDetail.Operaciones;
            _this.denunciesService.getDenunciaImagesBySomId(_this.id.toString()).subscribe(function (images) {
                _this.denunciesImages = images;
                _this.imatgesCount = images.length;
                _this.denunciesImages.forEach(function (element) {
                    element.NUM_DENUNCIA = _this.denunciaDetail.numeroDenuncia;
                });
            });
            _this.loadDomiciliosActuales();
            _this.loadZonesCarrer(_this.denunciaDetail.secure_id_carrer);
            // Aquest l'estem guardant al sessionStorage i quan anem al manteniment de infraccions, actualitzem la caché
            _this.loadInfracciones();
            // Aquest l'estem guardant al sessionStorage i quan anem al manteniment de carrers, actualitzem la caché
            _this.loadCalles();
            // Aquest l'estem guardant al sessionStorage
            _this.loadTipusDenuncies();
            // Aquest l'estem guardant al sessionStorage
            _this.loadTipusGravetat();
        });
    };
    DenunciesEditorComponent.prototype.loadTipusDenuncies = function () {
        var tipusDenunciaAux = new Array();
        this.startupService.GetTiposDenuncia().forEach(function (tipusDenuncia) {
            tipusDenunciaAux.push(new Dictionary(tipusDenuncia.CODI, tipusDenuncia.DESCRIPCIO));
        });
        this.tipusDenunciesDictionary.next(tipusDenunciaAux);
        this.comboTipusDenuncia.setSelect(this.denunciaDetail.tipusDenuncia);
    };
    DenunciesEditorComponent.prototype.loadTipusGravetat = function () {
        var tipusGravetatAux = new Array();
        this.startupService.GetInfraccioTipusGravetat().forEach(function (gravetat) {
            tipusGravetatAux.push(new Dictionary(gravetat.CODI, gravetat.DESCRIPCIO));
        });
        this.tipusGravetatDictionary.next(tipusGravetatAux);
        // this.comboTipusGravetat.markAsTouched();
        this.comboTipusGravetat.setSelect(this.denunciaDetail.qualificacio);
    };
    DenunciesEditorComponent.prototype.loadDiesDescompte = function () {
        this.diesDescompte = this.startupService.GetInfraccioDiesDescompte();
    };
    DenunciesEditorComponent.prototype.loadCalles = function () {
        var _this = this;
        this.callesDictionary = new Array();
        var json;
        var carrers;
        if (sessionStorage.getItem('carrersForCombo')) {
            json = sessionStorage.getItem('carrersForCombo');
            carrers = JSON.parse(json.toString());
            this.setCalles(carrers);
            this.setDefaultValueCalle();
        }
        else {
            this.carrersService.getAllForCombo().subscribe(function (carrersForCombo) {
                json = JSON.stringify(carrersForCombo);
                sessionStorage.setItem('carrersForCombo', json);
                _this.setCalles(carrersForCombo);
                _this.setDefaultValueCalle();
            });
        }
    };
    DenunciesEditorComponent.prototype.setCalles = function (carrers) {
        var _this = this;
        carrers.forEach(function (carrer) {
            if (carrer.SECURE_ID !== undefined && carrer.SECURE_ID !== null &&
                carrer.nomCarrer !== undefined && carrer.nomCarrer !== null) {
                _this.callesDictionary.push(new Dictionary(carrer.SECURE_ID, carrer.nomCarrer));
            }
        });
    };
    DenunciesEditorComponent.prototype.loadInfracciones = function () {
        var _this = this;
        this.infraccionesDictionary = new Array();
        var json;
        var infracciones;
        if (sessionStorage.getItem('infraccionsForCombo')) {
            json = sessionStorage.getItem('infraccionsForCombo');
            infracciones = JSON.parse(json.toString());
            this.setInfracciones(infracciones);
            this.setDefaultValueInfraccion();
        }
        else {
            this.infraccionsService.getAllForCombo().subscribe(function (infraccionesForCombo) {
                json = JSON.stringify(infraccionesForCombo);
                sessionStorage.setItem('infraccionsForCombo', json);
                _this.setInfracciones(infraccionesForCombo);
                _this.setDefaultValueInfraccion();
            });
        }
    };
    DenunciesEditorComponent.prototype.setInfracciones = function (infracciones) {
        var _this = this;
        infracciones.forEach(function (infraccion) {
            if (infraccion.secureId !== undefined && infraccion.secureId !== null &&
                infraccion.descCurta !== undefined && infraccion.descCurta !== null) {
                _this.infraccionesDictionary.push(new Dictionary(infraccion.secureId, infraccion.descCurta));
            }
        });
        this.infracciones = infracciones;
    };
    DenunciesEditorComponent.prototype.setDefaultValueCalle = function () {
        var _this = this;
        if (this.denunciaDetail.secure_id_carrer !== undefined && this.denunciaDetail.secure_id_carrer !== null && this.callesDictionary.length > 0) {
            var calleSelected = this.callesDictionary.find(function (x) { return x.key === _this.denunciaDetail.secure_id_carrer.toString(); });
            this.comboCalle.setForcedValueComb(calleSelected.value);
        }
    };
    DenunciesEditorComponent.prototype.setDefaultValueInfraccion = function () {
        var _this = this;
        if (this.denunciaDetail.secure_id_infraccio !== undefined && this.denunciaDetail.secure_id_infraccio !== null && this.infraccionesDictionary.length > 0) {
            var infraccioSelected = this.infraccionesDictionary.find(function (x) { return x.key === _this.denunciaDetail.secure_id_infraccio.toString(); });
            if (this.comboInfracciones !== undefined) {
                this.comboInfracciones.setForcedValueComb(infraccioSelected.value);
            }
        }
    };
    DenunciesEditorComponent.prototype.loadZonesCarrer = function (secure_id_carrer) {
        var _this = this;
        this.zonesService.getZonesByCarrer(secure_id_carrer).subscribe(function (zones) {
            var zonesComboAux = new Array();
            zones.forEach(function (zona) {
                zonesComboAux.push(new Dictionary(zona.SECURE_ID, zona.descripcio));
            });
            _this.zonesComboDictionary.next(zonesComboAux);
            if (_this.denunciaDetail.secure_id_zona !== undefined && _this.denunciaDetail.secure_id_zona != null) {
                _this.comboZonesCarrer.setSelect(_this.denunciaDetail.secure_id_zona);
            }
        });
    };
    DenunciesEditorComponent.prototype.onImagesClick = function () {
        var _this = this;
        this.denunciesImageDialogRef = this.dialog.open(DenunciesImagesComponent, {
            minWidth: '70%',
            minHeight: '90%',
            closeOnNavigation: false,
            autoFocus: true,
            data: { 'ficheros': this.denunciesImages, 'numDenuncia': this.denunciaDetail.numeroDenuncia },
        });
        this.denunciesImageDialogRef.componentInstance.somId = this.id;
        this.denunciesImageDialogRef.afterClosed().subscribe(function () {
            _this.denunciesService.getDenunciaImagesBySomId(_this.id.toString()).subscribe(function (images) {
                _this.denunciesImages = images;
                _this.imatgesCount = images.length;
                _this.denunciesImages.forEach(function (element) {
                    element.NUM_DENUNCIA = _this.denunciaDetail.numeroDenuncia;
                });
            });
        });
    };
    DenunciesEditorComponent.prototype.onMakeGridEditable = function () {
        this.isReadOnly = false;
        this.denunciaDetailOld = Object.assign({}, this.denunciaDetail);
        // this.loadCalles();
        // this.loadInfracciones();
        // // Aquest l'estem guardant al sessionStorage
        // this.loadTipusDenuncies();
        // // Aquest l'estem guardant al sessionStorage
        // this.loadTipusGravetat();
    };
    DenunciesEditorComponent.prototype.onMakeGridNonEditable = function (close) {
        if (close === void 0) { close = false; }
        this.isReadOnly = true;
        if (this.dialogData === undefined) {
            if (close) {
                this.router.navigate(['denuncies']);
            }
        }
        else {
            this.cancel();
        }
    };
    DenunciesEditorComponent.prototype.saveDenuncia = function () {
        var _this = this;
        if (this.formService.allFieldsValid(this.mainForm)) {
            this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Vol actualitzar la denúncia?'))
                .then(function (confirmed) {
                if (confirmed) {
                    var dataJson = JSON.stringify(_this.denunciaDetail);
                    var saved = _this.denunciesService.saveDenuncia(_this.denunciaDetail.secure_id_denuncia, dataJson).subscribe(function () {
                        _this.toastr.success(_this.i18n('Denúncia actualizada'));
                        _this.dialog.closeAll();
                        // propago un evento para avisar al datasource de que he eliminado un registro
                        _this.updated.emit(_this.denunciaDetail.secure_id_denuncia);
                        _this.onMakeGridNonEditable();
                    });
                }
            })
                .catch(function () { return console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'); });
        }
        else {
            this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
            if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
                this.mainForm.nativeElement.classList.add('displayErrors');
            }
        }
    };
    DenunciesEditorComponent.prototype.removeDenuncia = function () {
        var _this = this;
        this.confirmationDialogService.confirm(this.i18n('Eliminar denúncia'), this.i18n('La denúncia serà eliminada i no es podrà recuperar. Segur que vol eliminar la denúncia?'))
            .then(function (confirmed) {
            console.log('User confirmed:', confirmed);
            if (confirmed) {
                var dataJson = JSON.stringify(_this.denunciaDetail);
                var saved = _this.denunciesService.removeDenuncia(_this.id).subscribe(function () {
                    _this.toastr.info(_this.i18n('Denúncia eliminada'));
                    _this.dialog.closeAll();
                    // propago un evento para avisar al datasource de que he eliminado un registro
                    _this.deleted.emit(_this.denunciaDetail.secure_id_denuncia);
                    _this.router.navigate(['denuncies']);
                });
            }
        })
            .catch(function () { return console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'); });
    };
    DenunciesEditorComponent.prototype.novaOperacio = function () {
        var _this = this;
        var tipoOpe = this.denunciesService.getTipoOperacions().subscribe(function (novaOperacio) {
            _this.denunciesNovaOperacioRef = _this.dialog.open(NovaOperacioComponent, {
                height: '80%',
                width: '80%',
                closeOnNavigation: false,
                autoFocus: true,
                data: {
                    'operacions': novaOperacio,
                    'data': _this.denunciaDetail,
                    'operaciones': _this.operaciones,
                    'isMassiva': false
                },
            });
            _this.denunciesNovaOperacioRef.componentInstance.exp = _this.denunciaDetail.expedient;
            _this.denunciesNovaOperacioRef.componentInstance.newOperation.subscribe(function (data) {
                _this.operaciones.addOperation(data);
                _this.refrescar();
            });
        });
    };
    DenunciesEditorComponent.prototype.cobrarDenuncia = function () {
        var _this = this;
        this.denunciesService.getExpedient(this.denunciaDetail.expedient).subscribe(function (expedientsData) {
            _this.denunciesService.getSomExpedient(_this.denunciaDetail.expedient).subscribe(function (denunciaExpedient) {
                _this.denunciesCobrarService.cobrarDenuncia(_this.denunciaDetail, expedientsData, denunciaExpedient).subscribe(function (res) {
                    _this.getDenunciaDetall();
                });
            });
        });
    };
    DenunciesEditorComponent.prototype.getExpedients = function () {
        var _this = this;
        this.denunciesService.getExpedient(this.denunciaDetail.expedient).subscribe(function (expedientsData) {
            _this.expedientsDialog = _this.dialog.open(ExpedientsComponent, {
                height: '80%',
                width: '52%',
                closeOnNavigation: false,
                autoFocus: true,
                data: { 'expedient': expedientsData, 'numDenuncia': _this.denunciaDetail.numeroDenuncia, 'imatges': _this.denunciesImages }
            });
        });
    };
    DenunciesEditorComponent.prototype.calleSeleccionado = function (secureIdCalle) {
        this.denunciaDetail.secure_id_carrer = secureIdCalle;
        this.denunciaDetail.carrer = this.callesDictionary.find(function (x) { return x.key === secureIdCalle; }).value;
        // Carreguem les zones associades al carrer sel·leccionat
        this.denunciaDetail.secure_id_zona = null;
        this.denunciaDetail.descZona = null;
        this.loadZonesCarrer(this.denunciaDetail.secure_id_carrer);
    };
    DenunciesEditorComponent.prototype.seleccionarCarrer = function (current_id) {
        var _this = this;
        if (current_id === void 0) { current_id = null; }
        if (current_id !== null) {
            var data = Builder.newComponentDialog(true, this.isReadOnly, current_id);
            var dataAux = data;
            dataAux.editable = false;
            var ref = this.dialog.open(CalleEditorComponent, {
                width: '60%',
                closeOnNavigation: false,
                autoFocus: true,
                data: dataAux
            });
        }
        else {
            if (!Reflection.isNull(this.denunciaDetail) && !Reflection.empty(this.denunciaDetail.secure_id_carrer) && this.isReadOnly) {
                this.toastr.error(this.i18n('Carrer no trobat!'));
            }
            else {
                var data = Builder.newComponentDialog(true, this.isReadOnly, current_id);
                var ref = this.dialog.open(CarrersComponent, {
                    width: '90%',
                    closeOnNavigation: false,
                    autoFocus: true,
                    data: data
                });
                ref.afterClosed().subscribe(function (res) {
                    if (res.nomCarrer !== undefined && res.nomCarrer !== null && res.nomCarrer !== '') {
                        _this.denunciaDetail.carrer = res.nomCarrer;
                        _this.denunciaDetail.secure_id_carrer = res.secure_id;
                        _this.denunciaDetail.secure_id_zona = null;
                        _this.denunciaDetail.descZona = null;
                        _this.setDefaultValueCalle();
                        _this.loadZonesCarrer(_this.denunciaDetail.secure_id_carrer);
                    }
                });
            }
        }
    };
    DenunciesEditorComponent.prototype.cancelDenuncia = function () {
        this.isReadOnly = true;
        this.denunciaDetail = this.denunciaDetailOld;
    };
    DenunciesEditorComponent.prototype.seleccionarAgent = function (current_id) {
        var _this = this;
        if (current_id === void 0) { current_id = null; }
        if (current_id !== null) {
            var data = Builder.newComponentDialog(true, this.isReadOnly, current_id);
            var dataAux = data;
            dataAux.editable = false;
            var ref = this.dialog.open(AgentsEditorComponent, {
                width: '30%',
                closeOnNavigation: false,
                autoFocus: true,
                data: dataAux
            });
        }
        else {
            if (!Reflection.isNull(this.denunciaDetail) && !Reflection.empty(this.denunciaDetail.codiAgent) && this.isReadOnly) {
                this.toastr.error(this.i18n('Agent no trobat!'));
            }
            else {
                var data = Builder.newComponentDialog(true, this.isReadOnly, current_id);
                var ref = this.dialog.open(AgentsComponent, {
                    width: '90%',
                    closeOnNavigation: false,
                    autoFocus: true,
                    data: data
                });
                ref.afterClosed().subscribe(function (res) {
                    if (res.codi !== undefined && res.codi !== null && res.codi !== '') {
                        _this.denunciaDetail.codiAgent = res.codi;
                        _this.denunciaDetail.secure_id_agent = res.secure_id;
                    }
                });
            }
        }
    };
    DenunciesEditorComponent.prototype.seleccionarLegislacio = function (current_id) {
        var _this = this;
        if (current_id === void 0) { current_id = null; }
        if (current_id !== null) {
            var data = Builder.newComponentDialog(true, this.isReadOnly, current_id);
            var dataAux = data;
            dataAux.editable = false;
            var ref = this.dialog.open(InfraccionsEditorComponent, {
                width: '90%',
                closeOnNavigation: false,
                autoFocus: true,
                data: dataAux
            });
            ref.afterClosed().subscribe(function (res) {
                _this.refrescar();
            });
        }
        else {
            if (!Reflection.isNull(this.denunciaDetail) && !Reflection.empty(this.denunciaDetail.llei) && this.isReadOnly) {
                this.toastr.error(this.i18n('Legislacio no trobada!'));
            }
            else {
                var data = Builder.newComponentDialog(true, this.isReadOnly, current_id);
                var ref = this.dialog.open(InfraccioComponent, {
                    width: '90%',
                    closeOnNavigation: false,
                    autoFocus: true,
                    data: data
                });
                ref.afterClosed().subscribe(function (res) {
                    if (res !== undefined && res !== null) {
                        // Les infraccions de tipus velocitat no les podem denúnciar des d'aquí
                        if (res.infraccio.tipusId === 'V') {
                            _this.toastr.warning(_this.i18n('No es poden carregar infraccions de velocitat!'));
                        }
                        else {
                            _this.denunciaDetail.llei = res.infraccio.legislacio;
                            _this.denunciaDetail.article = res.infraccio.article;
                            _this.denunciaDetail.descripcioInfraccio = res.infraccio.descCurta;
                            _this.denunciaDetail.secure_id_infraccio = res.infraccio.secureId;
                            _this.denunciaDetail.import = res.infraccio.import;
                            _this.denunciaDetail.importDescompte = res.infraccio.importDescompte;
                            _this.denunciaDetail.lleiDesc = res.infraccio.lleiDesc;
                            _this.denunciaDetail.tipusIdInfraccio = res.infraccio.tipusId;
                            _this.denunciaDetail.porcentajeDescuento = res.infraccio.descompte;
                            _this.denunciaDetail.punts = res.infraccio.punts;
                            _this.denunciaDetail.qualificacio = res.infraccio.gravetat;
                            _this.denunciaDetail.tipusDenuncia = res.infraccio.tipusDenunciaId; // combo tipus denuncies
                            _this.comboTipusDenuncia.setSelect(_this.denunciaDetail.tipusDenuncia);
                            _this.comboTipusDenuncia.markAsTouched();
                            _this.denunciaDetail.qualificacio = res.infraccio.gravetat; // combo tipus gravetats
                            _this.comboTipusGravetat.setSelect(_this.denunciaDetail.qualificacio);
                            _this.comboTipusGravetat.markAsTouched();
                            _this.calcularDataDescompte();
                            _this.setDefaultValueInfraccion();
                        }
                    }
                });
            }
        }
    };
    DenunciesEditorComponent.prototype.infraccioSeleccionado = function (secureIdInfraccion) {
        var res = this.infracciones.find(function (x) { return x.secureId === secureIdInfraccion; });
        if (res !== undefined && res !== null) {
            // Les infraccions de tipus velocitat no les podem denúnciar des d'aquí
            if (res.tipusId === 'V') {
                this.toastr.warning(this.i18n('No es poden carregar infraccions de velocitat!'));
            }
            else {
                this.denunciaDetail.llei = res.legislacio;
                this.denunciaDetail.article = res.article;
                this.denunciaDetail.descripcioInfraccio = res.descCurta;
                this.denunciaDetail.secure_id_infraccio = res.secureId;
                this.denunciaDetail.import = res.import;
                this.denunciaDetail.importDescompte = res.importDescompte;
                this.denunciaDetail.lleiDesc = res.lleiDesc;
                this.denunciaDetail.tipusIdInfraccio = res.tipusId;
                this.denunciaDetail.porcentajeDescuento = res.descompte;
                this.denunciaDetail.punts = Number(res.punts);
                this.denunciaDetail.judicial = res.judicial;
                this.denunciaDetail.tipusDenuncia = res.tipusDenunciaId; // combo tipus denuncies
                this.comboTipusDenuncia.setSelect(this.denunciaDetail.tipusDenuncia);
                this.comboTipusDenuncia.markAsTouched();
                this.denunciaDetail.qualificacio = res.gravetat; // combo tipus gravetats
                this.comboTipusGravetat.setSelect(this.denunciaDetail.qualificacio);
                this.comboTipusGravetat.markAsTouched();
                this.calcularDataDescompte();
            }
        }
    };
    DenunciesEditorComponent.prototype.cancel = function () {
        this.thisDialogRef.close(false);
    };
    DenunciesEditorComponent.prototype.seleccionarPropietari = function (secureId) {
        var _this = this;
        if (secureId === void 0) { secureId = null; }
        if (secureId !== null) {
            var data = Builder.newComponentDialog(true, this.isReadOnly, secureId);
            var ref = this.dialog.open(PersonesEditorComponent, {
                width: '80%',
                height: '90%',
                closeOnNavigation: false,
                autoFocus: true,
                data: data,
                disableClose: true
            });
            ref.afterClosed().subscribe(function (dat) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (this.denunciaDetail.secure_id_propietari !== undefined && this.denunciaDetail.secure_id_propietari !== null && this.denunciaDetail.secure_id_propietari !== '') {
                        this.getDomicilis(this.denunciaDetail.secure_id_propietari, 'Propietari', this.denunciaDetail.secure_id_domiciliPropietari);
                    }
                    return [2 /*return*/];
                });
            }); });
        }
        else {
            if (!Reflection.isNull(this.denunciaDetail) && !Reflection.empty(this.denunciaDetail.secure_id_propietari) && this.isReadOnly) {
                this.toastr.error(this.i18n('Persona no trobada!'));
            }
            else {
                var data = Builder.newComponentDialog(true, this.isReadOnly, secureId);
                var ref = this.dialog.open(PersonesComponent, {
                    width: '80%',
                    height: '90%',
                    closeOnNavigation: false,
                    autoFocus: true,
                    data: data,
                    disableClose: true
                });
                ref.afterClosed().subscribe(function (res) {
                    if (res.codi !== undefined && res.codi !== null && res.codi !== '') {
                        _this.denunciaDetail.secure_id_propietari = res.secure_id;
                        _this.denunciaDetail.nom_propietari = res.nomComplet;
                        _this.denunciaDetail.dni_propietari = res.dni_pasp;
                        _this.denunciaDetail.secure_id_domiciliPropietari = res.secureIdDom;
                        _this.getDomicilis(_this.denunciaDetail.secure_id_propietari, 'Propietari', res.secureIdDom);
                    }
                });
            }
        }
    };
    DenunciesEditorComponent.prototype.seleccionarTutorLegal = function (secureId) {
        var _this = this;
        if (secureId === void 0) { secureId = null; }
        if (secureId !== null) {
            var data = Builder.newComponentDialog(true, this.isReadOnly, secureId);
            var ref = this.dialog.open(PersonesEditorComponent, {
                width: '80%',
                height: '90%',
                closeOnNavigation: false,
                autoFocus: true,
                data: data,
                disableClose: true
            });
            ref.afterClosed().subscribe(function (dat) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (this.denunciaDetail.secure_id_tutorLegal !== undefined && this.denunciaDetail.secure_id_tutorLegal !== null && this.denunciaDetail.secure_id_tutorLegal !== '') {
                        this.getDomicilis(this.denunciaDetail.secure_id_tutorLegal, 'TutorLegal', this.denunciaDetail.secure_id_domiciliTutorLegal);
                    }
                    return [2 /*return*/];
                });
            }); });
        }
        else {
            if (!Reflection.isNull(this.denunciaDetail) && !Reflection.empty(this.denunciaDetail.secure_id_tutorLegal) && this.isReadOnly) {
                this.toastr.error(this.i18n('Persona no trobada!'));
            }
            else {
                var data = Builder.newComponentDialog(true, this.isReadOnly, secureId);
                var ref = this.dialog.open(PersonesComponent, {
                    width: '80%',
                    height: '90%',
                    closeOnNavigation: false,
                    autoFocus: true,
                    data: data,
                    disableClose: true
                });
                ref.afterClosed().subscribe(function (res) {
                    if (res.codi !== undefined && res.codi !== null && res.codi !== '') {
                        _this.denunciaDetail.secure_id_tutorLegal = res.secure_id;
                        _this.denunciaDetail.nom_tutorLegal = res.nomComplet;
                        _this.denunciaDetail.dni_tutorLegal = res.dni_pasp;
                        _this.denunciaDetail.secure_id_domiciliTutorLegal = res.secureIdDom;
                        _this.getDomicilis(_this.denunciaDetail.secure_id_tutorLegal, 'TutorLegal', res.secureIdDom);
                    }
                });
            }
        }
    };
    DenunciesEditorComponent.prototype.seleccionarConductor = function (secureId) {
        var _this = this;
        if (secureId === void 0) { secureId = null; }
        if (secureId !== null) {
            var data = Builder.newComponentDialog(true, this.isReadOnly, secureId);
            var ref = this.dialog.open(PersonesEditorComponent, {
                width: '80%',
                height: '90%',
                closeOnNavigation: false,
                autoFocus: true,
                data: data,
                disableClose: true
            });
            ref.afterClosed().subscribe(function (dat) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (this.denunciaDetail.secure_id_conductor !== undefined && this.denunciaDetail.secure_id_conductor !== null && this.denunciaDetail.secure_id_conductor !== '') {
                        this.getDomicilis(this.denunciaDetail.secure_id_conductor, 'Infractor', this.denunciaDetail.secure_id_domiciliConductor);
                    }
                    return [2 /*return*/];
                });
            }); });
            /*const ref = this.dialog.open(PersonesEditorComponent, {
            width: '90%',
            closeOnNavigation: false,
            autoFocus: true,
            data:  {
              'secureId': secure_id
            }
          });*/
        }
        else {
            if (!Reflection.isNull(this.denunciaDetail) && !Reflection.empty(this.denunciaDetail.secure_id_conductor) && this.isReadOnly) {
                this.toastr.error(this.i18n('Persona no trobada!'));
            }
            else {
                var data = Builder.newComponentDialog(true, this.isReadOnly, secureId);
                var ref = this.dialog.open(PersonesComponent, {
                    width: '80%',
                    height: '90%',
                    closeOnNavigation: false,
                    autoFocus: true,
                    data: data,
                    disableClose: true
                });
                ref.afterClosed().subscribe(function (resp) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        if (resp.codi !== undefined && resp.codi !== null && resp.codi !== '') {
                            this.denunciaDetail.secure_id_conductor = resp.codi;
                            this.denunciaDetail.secure_id_conductor = resp.secure_id;
                            this.denunciaDetail.nom_conductor = resp.nomComplet;
                            this.denunciaDetail.dni_conductor = resp.dni_pasp;
                            this.denunciaDetail.secure_id_domiciliConductor = resp.secureIdDom;
                            // : nomComplet, dni_pasp: dni_pasp
                            this.getDomicilis(this.denunciaDetail.secure_id_conductor, 'Infractor', resp.secureIdDom);
                            // this.denunciaDetail.secure_id_domiciliConductor = resp.secureIdDom;
                        }
                        return [2 /*return*/];
                    });
                }); });
            }
        }
    };
    DenunciesEditorComponent.prototype.getDomicilis = function (secureIdPersona, persona, secureIdDom) {
        var _this = this;
        this.personesService.getDomicilis(secureIdPersona).subscribe(function (dom) {
            if (persona === 'Propietari') {
                if (_this.comboDomPropietari !== null && _this.comboDomPropietari !== undefined) {
                    _this.comboDomPropietari.resetShowingOptions();
                }
                if (dom === undefined || dom === null || dom.length === 0) {
                    _this.domicilisPropietariDictionary = new BehaviorSubject(new Array());
                }
                else {
                    // this.domicilisConductor = dom;
                    // this.comboDomConductor.resetCombo();
                    _this.domicilisPropietariDictionary = new BehaviorSubject(new Array());
                    var dict_1 = new Array();
                    dom.forEach(function (element) {
                        dict_1.push(new Dictionary(element.secure_id, element.domicili + ' (' + element.codiPostal + ' - ' + element.localitat + ')'));
                    });
                    _this.domicilisPropietariDictionary.next(dict_1);
                    if (secureIdDom !== undefined) {
                        if (_this.comboDomPropietari !== null && _this.comboDomPropietari !== undefined) {
                            _this.comboDomPropietari.markAsTouched();
                            _this.comboDomPropietari.setSelect(secureIdDom);
                            // this.comboDomPropietari.currentoption = dict.find(x => x.key === this.denunciaDetail.secure_id_domiciliConductor);
                        }
                    }
                }
                /*if (dom === undefined || dom === null || dom.length === 0){
                  this.domicilisPropietari = [];
                  this.comboDomPropietari.resetCombo();
                } else {
                  this.domicilisPropietari = dom;
                }
                if ( secureIdDom !== undefined && secureIdDom !== null) {
                  this.denunciaDetail.secure_id_domiciliPropietari = secureIdDom;
                  this.comboDomPropietari.setSelect(dom.find(x => x.secure_id === secureIdDom));
                } else {
                  this.denunciaDetail.secure_id_domiciliPropietari = '';
                }*/
            }
            else if (persona === 'Infractor') {
                _this.comboDomConductor.resetShowingOptions();
                if (dom === undefined || dom === null || dom.length === 0) {
                    _this.domicilisConductorDictionary = new BehaviorSubject(new Array());
                }
                else {
                    // this.domicilisConductor = dom;
                    // this.comboDomConductor.resetCombo();
                    _this.domicilisConductorDictionary = new BehaviorSubject(new Array());
                    var dict_2 = new Array();
                    dom.forEach(function (element) {
                        dict_2.push(new Dictionary(element.secure_id, element.domicili + ' (' + element.codiPostal + ' - ' + element.localitat + ')'));
                    });
                    _this.domicilisConductorDictionary.next(dict_2);
                    if (secureIdDom !== undefined) {
                        _this.comboDomConductor.markAsTouched();
                        _this.comboDomConductor.setSelect(secureIdDom);
                        // this.comboDomConductor.currentoption = dict.find(x => x.key === this.denunciaDetail.secure_id_domiciliConductor);
                    }
                }
            }
            else if (persona === 'TutorLegal') {
                _this.comboDomTutorLegal.resetShowingOptions();
                if (dom === undefined || dom === null || dom.length === 0) {
                    _this.domicilisTutorLegalDictionary = new BehaviorSubject(new Array());
                }
                else {
                    // this.domicilisConductor = dom;
                    // this.comboDomConductor.resetCombo();
                    _this.domicilisTutorLegalDictionary = new BehaviorSubject(new Array());
                    var dict_3 = new Array();
                    dom.forEach(function (element) {
                        dict_3.push(new Dictionary(element.secure_id, element.domicili + ' (' + element.codiPostal + ' - ' + element.localitat + ')'));
                    });
                    _this.domicilisTutorLegalDictionary.next(dict_3);
                    if (secureIdDom !== undefined) {
                        _this.comboDomTutorLegal.markAsTouched();
                        _this.comboDomTutorLegal.setSelect(secureIdDom);
                        // this.comboDomConductor.currentoption = dict.find(x => x.key === this.denunciaDetail.secure_id_domiciliConductor);
                    }
                }
            }
            /* if ( secureIdDom !== undefined && secureIdDom !== null) {
               this.denunciaDetail.secure_id_domiciliConductor = secureIdDom;
               this.comboDomConductor.setSelect(dom.find(x => x.secure_id === secureIdDom));
               }else{
                 this.denunciaDetail.secure_id_domiciliConductor = '';
               }*/
        });
    };
    DenunciesEditorComponent.prototype.consultarConductor = function () {
        var _this = this;
        if (this.denunciaDetail.dni_conductor !== undefined
            && this.denunciaDetail.dni_conductor !== null
            && this.denunciaDetail.dni_conductor !== '') {
            this.personesService.findPersonaByDniNif(this.denunciaDetail.dni_conductor).subscribe(function (x) {
                if (x !== undefined && x !== null && x.length > 0) {
                    if (x.length === 1) {
                        _this.denunciaDetail.secure_id_conductor = x[0].secure_id;
                        _this.denunciaDetail.nom_conductor = x[0].nomComplet;
                        // TODO: Domicili
                        _this.getDomicilis(x[0].secure_id, 'Infractor', x[0].SECURE_ID_DOMICILI_ACTUAL);
                    }
                    else if (x.length > 1) {
                        var filter = new PersonaFilter('id', 'asc');
                        filter.dni_pasp = _this.denunciaDetail.dni_conductor;
                        // this.filterService.saveFilter('persona-filter', filter);
                        // Carraguem la taula filtrada per dni
                        var data = Builder.newComponentDialog(true, _this.isReadOnly, '', filter);
                        var ref = _this.dialog.open(PersonesComponent, {
                            width: '80%',
                            height: '90%',
                            closeOnNavigation: false,
                            autoFocus: true,
                            data: data
                        });
                        ref.afterClosed().subscribe(function (resp) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (resp.codi !== undefined && resp.codi !== null && resp.codi !== '') {
                                    this.denunciaDetail.secure_id_conductor = resp.codi;
                                    this.denunciaDetail.secure_id_conductor = resp.secure_id;
                                    this.denunciaDetail.nom_conductor = resp.nomComplet;
                                    this.denunciaDetail.dni_conductor = resp.dni_pasp;
                                    this.denunciaDetail.secure_id_domiciliConductor = resp.secureIdDom;
                                    // : nomComplet, dni_pasp: dni_pasp
                                    this.getDomicilis(this.denunciaDetail.secure_id_conductor, 'Infractor', resp.secureIdDom);
                                    this.denunciaDetail.secure_id_domiciliConductor = resp.secureIdDom;
                                }
                                return [2 /*return*/];
                            });
                        }); });
                    }
                }
                else {
                    // Obrim dialeg per crear persona
                    _this.confirmationDialogService.confirm(_this.i18n('Confirmi'), _this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), _this.i18n('Si'), _this.i18n('No'))
                        .then(function (confirmed) {
                        if (confirmed) {
                            var data = Builder.newComponentDialog(true, _this.isReadOnly, null);
                            var ref = _this.dialog.open(PersonesEditorComponent, {
                                width: '80%',
                                height: '90%',
                                closeOnNavigation: false,
                                autoFocus: true,
                                data: {
                                    isDialog: true,
                                    isReadOnly: _this.isReadOnly,
                                    id: null,
                                    filter: null,
                                    dni: _this.denunciaDetail.dni_conductor
                                }
                            });
                            ref.afterClosed().subscribe(function (resp) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    if (resp.codi !== undefined && resp.codi !== null && resp.codi !== '') {
                                        this.denunciaDetail.secure_id_conductor = resp.codi;
                                        this.denunciaDetail.secure_id_conductor = resp.secure_id;
                                        this.denunciaDetail.nom_conductor = resp.nomComplet;
                                        this.denunciaDetail.dni_conductor = resp.dni_pasp;
                                        this.denunciaDetail.secure_id_domiciliConductor = resp.secureIdDom;
                                        // : nomComplet, dni_pasp: dni_pasp
                                        this.getDomicilis(this.denunciaDetail.secure_id_conductor, 'Infractor', resp.secureIdDom);
                                        this.denunciaDetail.secure_id_domiciliConductor = resp.secureIdDom;
                                    }
                                    return [2 /*return*/];
                                });
                            }); });
                        }
                        else {
                            _this.denunciaDetail.nom_conductor = '';
                            _this.domicilisConductorDictionary = new BehaviorSubject(new Array());
                        }
                    });
                }
            });
        }
        else {
            // Obrim dialeg per crear persona
            this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), this.i18n('Si'), this.i18n('No'))
                .then(function (confirmed) {
                if (confirmed) {
                    var data = Builder.newComponentDialog(true, _this.isReadOnly, null);
                    var ref = _this.dialog.open(PersonesEditorComponent, {
                        width: '80%',
                        height: '90%',
                        closeOnNavigation: false,
                        autoFocus: true,
                        data: {
                            isDialog: true,
                            isReadOnly: _this.isReadOnly,
                            id: null,
                            filter: null,
                            dni: _this.denunciaDetail.dni_conductor
                        }
                    });
                    ref.afterClosed().subscribe(function (resp) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            if (resp.codi !== undefined && resp.codi !== null && resp.codi !== '') {
                                this.denunciaDetail.secure_id_conductor = resp.codi;
                                this.denunciaDetail.secure_id_conductor = resp.secure_id;
                                this.denunciaDetail.nom_conductor = resp.nomComplet;
                                this.denunciaDetail.dni_conductor = resp.dni_pasp;
                                this.denunciaDetail.secure_id_domiciliConductor = resp.secureIdDom;
                                // : nomComplet, dni_pasp: dni_pasp
                                this.getDomicilis(this.denunciaDetail.secure_id_conductor, 'Infractor', resp.secureIdDom);
                                this.denunciaDetail.secure_id_domiciliConductor = resp.secureIdDom;
                            }
                            return [2 /*return*/];
                        });
                    }); });
                }
                else {
                    _this.denunciaDetail.nom_conductor = '';
                    _this.domicilisConductorDictionary = new BehaviorSubject(new Array());
                }
            });
        }
    };
    DenunciesEditorComponent.prototype.consultarPropietari = function () {
        var _this = this;
        if (this.denunciaDetail.dni_propietari !== undefined
            && this.denunciaDetail.dni_propietari !== null
            && this.denunciaDetail.dni_propietari !== '') {
            this.personesService.findPersonaByDniNif(this.denunciaDetail.dni_propietari).subscribe(function (x) {
                if (x !== undefined && x !== null && x.length > 0) {
                    if (x.length === 1) {
                        _this.denunciaDetail.secure_id_propietari = x[0].secure_id;
                        _this.denunciaDetail.nom_propietari = x[0].nomComplet;
                        _this.getDomicilis(x[0].secure_id, 'Propietari', x[0].SECURE_ID_DOMICILI_ACTUAL);
                    }
                    else if (x.length > 1) {
                        var filter = new PersonaFilter('id', 'asc');
                        filter.dni_pasp = _this.denunciaDetail.dni_propietari;
                        // Carraguem la taula filtrada per dni
                        var data = Builder.newComponentDialog(true, _this.isReadOnly, '', filter);
                        var ref = _this.dialog.open(PersonesComponent, {
                            width: '80%',
                            height: '90%',
                            closeOnNavigation: false,
                            autoFocus: true,
                            data: data
                        });
                        ref.afterClosed().subscribe(function (resp) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (resp.codi !== undefined && resp.codi !== null && resp.codi !== '') {
                                    this.denunciaDetail.secure_id_propietari = resp.codi;
                                    this.denunciaDetail.secure_id_propietari = resp.secure_id;
                                    this.denunciaDetail.nom_propietari = resp.nomComplet;
                                    this.denunciaDetail.dni_propietari = resp.dni_pasp;
                                    this.denunciaDetail.secure_id_domiciliPropietari = resp.secureIdDom;
                                    // : nomComplet, dni_pasp: dni_pasp
                                    this.getDomicilis(this.denunciaDetail.secure_id_conductor, 'Propietari', resp.secureIdDom);
                                    this.denunciaDetail.secure_id_domiciliPropietari = resp.secureIdDom;
                                }
                                return [2 /*return*/];
                            });
                        }); });
                    }
                }
                else {
                    // Obrim dialeg per crear persona
                    _this.confirmationDialogService.confirm(_this.i18n('Confirmi'), _this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), _this.i18n('Si'), _this.i18n('No'))
                        .then(function (confirmed) {
                        if (confirmed) {
                            var data = Builder.newComponentDialog(true, _this.isReadOnly, null);
                            var ref = _this.dialog.open(PersonesEditorComponent, {
                                width: '80%',
                                height: '90%',
                                closeOnNavigation: false,
                                autoFocus: true,
                                data: {
                                    isDialog: true,
                                    isReadOnly: _this.isReadOnly,
                                    id: null,
                                    filter: null,
                                    dni: _this.denunciaDetail.dni_propietari
                                }
                            });
                            ref.afterClosed().subscribe(function (resp) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    if (resp.codi !== undefined && resp.codi !== null && resp.codi !== '') {
                                        this.denunciaDetail.secure_id_propietari = resp.codi;
                                        this.denunciaDetail.secure_id_propietari = resp.secure_id;
                                        this.denunciaDetail.nom_propietari = resp.nomComplet;
                                        this.denunciaDetail.dni_propietari = resp.dni_pasp;
                                        this.denunciaDetail.secure_id_domiciliPropietari = resp.secureIdDom;
                                        // : nomComplet, dni_pasp: dni_pasp
                                        this.getDomicilis(this.denunciaDetail.secure_id_domiciliPropietari, 'Propietari', resp.secureIdDom);
                                        this.denunciaDetail.secure_id_domiciliPropietari = resp.secureIdDom;
                                    }
                                    return [2 /*return*/];
                                });
                            }); });
                        }
                        else {
                            _this.denunciaDetail.nom_propietari = '';
                            _this.domicilisPropietariDictionary = new BehaviorSubject(new Array());
                        }
                    });
                }
            });
        }
        else {
            // Obrim dialeg per crear persona
            this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), this.i18n('Si'), this.i18n('No'))
                .then(function (confirmed) {
                if (confirmed) {
                    // const data: ComponentDialog = Builder.newComponentDialog(true, this.isReadOnly, null);
                    var ref = _this.dialog.open(PersonesEditorComponent, {
                        width: '80%',
                        height: '90%',
                        closeOnNavigation: false,
                        autoFocus: true,
                        data: {
                            isDialog: true,
                            isReadOnly: _this.isReadOnly,
                            id: null,
                            filter: null,
                            dni: _this.denunciaDetail.dni_propietari
                        }
                    });
                    ref.afterClosed().subscribe(function (data) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
                                this.denunciaDetail.secure_id_propietari = data.codi;
                                this.denunciaDetail.secure_id_propietari = data.secure_id;
                                this.denunciaDetail.nom_propietari = data.nomComplet;
                                this.denunciaDetail.dni_propietari = data.dni_pasp;
                                this.denunciaDetail.secure_id_domiciliPropietari = data.secureIdDom;
                                // : nomComplet, dni_pasp: dni_pasp
                                this.getDomicilis(this.denunciaDetail.secure_id_propietari, 'Propietari', data.secureIdDom);
                                this.denunciaDetail.secure_id_domiciliPropietari = data.secureIdDom;
                            }
                            return [2 /*return*/];
                        });
                    }); });
                }
                else {
                    _this.denunciaDetail.nom_propietari = '';
                    _this.domicilisPropietariDictionary = new BehaviorSubject(new Array());
                }
            });
        }
    };
    DenunciesEditorComponent.prototype.loadDomiciliosActuales = function () {
        if (this.denunciaDetail.secure_id_propietari !== undefined && this.denunciaDetail.secure_id_propietari !== null) {
            var sIdDom = null;
            if (this.denunciaDetail.secure_id_domiciliPropietari !== undefined && this.denunciaDetail.secure_id_domiciliPropietari !== null) {
                sIdDom = this.denunciaDetail.secure_id_domiciliPropietari;
            }
            this.getDomicilis(this.denunciaDetail.secure_id_propietari, 'Propietari', sIdDom);
        }
        if (this.denunciaDetail.secure_id_conductor !== undefined && this.denunciaDetail.secure_id_conductor !== null) {
            var sIdDom = null;
            if (this.denunciaDetail.secure_id_domiciliConductor !== undefined && this.denunciaDetail.secure_id_domiciliConductor !== null) {
                sIdDom = this.denunciaDetail.secure_id_domiciliConductor;
            }
            this.getDomicilis(this.denunciaDetail.secure_id_conductor, 'Infractor', sIdDom);
        }
        if (this.denunciaDetail.secure_id_tutorLegal !== undefined && this.denunciaDetail.secure_id_tutorLegal !== null) {
            var sIdDom = null;
            if (this.denunciaDetail.secure_id_domiciliTutorLegal !== undefined && this.denunciaDetail.secure_id_domiciliTutorLegal !== null) {
                sIdDom = this.denunciaDetail.secure_id_domiciliTutorLegal;
            }
            this.getDomicilis(this.denunciaDetail.secure_id_tutorLegal, 'TutorLegal', sIdDom);
        }
    };
    DenunciesEditorComponent.prototype.domicilioConductorSeleccionado = function (secure_id) {
        this.denunciaDetail.secure_id_domiciliConductor = secure_id;
    };
    DenunciesEditorComponent.prototype.domicilioPropietarioSeleccionado = function (secure_id) {
        this.denunciaDetail.secure_id_domiciliPropietari = secure_id;
    };
    DenunciesEditorComponent.prototype.domicilioTutorLegalSeleccionado = function (secure_id) {
        this.denunciaDetail.secure_id_domiciliTutorLegal = secure_id;
    };
    DenunciesEditorComponent.prototype.zonaCarrerSeleccionado = function (secure_id) {
        this.denunciaDetail.secure_id_zona = secure_id;
    };
    DenunciesEditorComponent.prototype.tipusDenunciaSeleccionado = function (tipusDenunciaId) {
        this.denunciaDetail.tipusDenuncia = tipusDenunciaId;
    };
    DenunciesEditorComponent.prototype.tipusGravetatSeleccionado = function (gravetatId) {
        this.denunciaDetail.qualificacio = gravetatId;
    };
    DenunciesEditorComponent.prototype.seleccionarVehicle = function (matricula) {
        var _this = this;
        if (matricula === void 0) { matricula = null; }
        var modeLectura = this.isReadOnly;
        if (matricula !== null) {
            var data = Builder.newComponentDialog(true, this.isReadOnly, matricula);
            var dataAux = data;
            dataAux.editable = false;
            var ref = this.dialog.open(VehiclesEditorComponent, {
                width: '40%',
                // height: '90%',
                closeOnNavigation: false,
                autoFocus: true,
                data: dataAux,
                disableClose: true
            });
            ref.afterClosed().subscribe(function (resp) {
                if (!modeLectura && resp.matricula !== undefined && resp.matricula !== null && resp.matricula !== '') {
                    _this.denunciaDetail.secure_id_vehicle = resp.secure_id;
                    _this.denunciaDetail.matricula = resp.matricula;
                    _this.denunciaDetail.modelo = resp.model;
                    _this.denunciaDetail.marca = resp.marca;
                    _this.denunciaDetail.pais = resp.pais;
                    _this.denunciaDetail.paisDesc = resp.paisDesc;
                    _this.saveDenuncia();
                }
            });
        }
        else {
            if (!Reflection.isNull(this.denunciaDetail) && !Reflection.empty(this.denunciaDetail.matricula) && this.isReadOnly) {
                this.toastr.error(this.i18n('Vehicle no trobat!'));
            }
            else {
                var data = Builder.newComponentDialog(true, this.isReadOnly, this.denunciaDetail.matricula);
                var ref = this.dialog.open(VehiclesComponent, {
                    width: '80%',
                    // height: '90%',
                    closeOnNavigation: false,
                    autoFocus: true,
                    data: data
                });
                ref.afterClosed().subscribe(function (resp) {
                    if (!modeLectura && resp.matricula !== undefined && resp.matricula !== null && resp.matricula !== '') {
                        _this.denunciaDetail.secure_id_vehicle = resp.secure_id;
                        _this.denunciaDetail.matricula = resp.matricula;
                        _this.denunciaDetail.modelo = resp.model;
                        _this.denunciaDetail.marca = resp.marca;
                        _this.denunciaDetail.pais = resp.pais;
                        _this.denunciaDetail.paisDesc = resp.paisDesc;
                    }
                });
            }
        }
    };
    DenunciesEditorComponent.prototype.OpenDgt = function () {
        var _this = this;
        if (this.isReadOnly) {
            var ref = this.dialog.open(XmlTreeViewerComponent, {
                width: '75%',
                height: '75%',
                closeOnNavigation: true,
                autoFocus: true,
                data: {
                    'isDialog': true,
                    'isReadOnly': this.isReadOnly,
                    'matricula': '',
                    'persona': '',
                    'propietario': '',
                    'incorporaData': false,
                    'consulta': true
                }
            });
        }
        else {
            if (this.denunciaDetail.matricula !== undefined && this.denunciaDetail.matricula !== null && this.denunciaDetail.matricula !== '') {
                var conductor = this.denunciaDetail.dni_conductor !== undefined && this.denunciaDetail.dni_conductor !== null && this.denunciaDetail.dni_conductor !== '';
                var propietari = this.denunciaDetail.dni_propietari !== undefined && this.denunciaDetail.dni_propietari !== null && this.denunciaDetail.dni_propietari !== '';
                var ref = this.dialog.open(DgtFilterComponent, {
                    width: '30%',
                    height: '20%',
                    closeOnNavigation: true,
                    autoFocus: true,
                    data: {
                        'conductor': conductor,
                        'propietari': propietari
                    }
                });
                ref.afterClosed().subscribe(function (data) {
                    if (!data.filtre !== undefined && data.filtre !== null && data.filtre !== '') {
                        if (data.filtre === '1') {
                            _this.openXmlMatricula();
                        }
                        else if (data.filtre === '2') {
                            _this.openXmlPropietari();
                        }
                        else if (data.filtre === '3') {
                            _this.openXmlPersona();
                        }
                    }
                });
            }
        }
    };
    DenunciesEditorComponent.prototype.openXmlMatricula = function () {
        var _this = this;
        if (this.denunciaDetail.matricula !== undefined && this.denunciaDetail.matricula !== null && this.denunciaDetail.matricula !== '') {
            var ref = this.dialog.open(XmlTreeViewerComponent, {
                width: '75%',
                height: '75%',
                closeOnNavigation: true,
                autoFocus: true,
                data: {
                    'isDialog': true,
                    'isReadOnly': this.isReadOnly,
                    'matricula': this.denunciaDetail.matricula,
                    'persona': '',
                    'propietario': '',
                    'incorporaData': true,
                    'consulta': false,
                    'secureIdDenuncia': this.denunciaDetail.secure_id_denuncia,
                    'fechaDenuncia': this.denunciaDetail.data
                }
            });
            ref.afterClosed().subscribe(function (data) {
                if (data !== undefined && data !== null && data !== '') {
                    _this.refrescar();
                    _this.toastr.success(_this.i18n('Denuncia actualitzada correctament'));
                }
            });
            // if (data !== undefined && data !== null &&
            //   data.marca !== undefined &&  data.marca !== null &&  data.marca !== '' &&
            //   data.model !== undefined && data.model !== null && data.model !== '') {
            //     this.denunciaDetail.marca = data.marca;
            //     this.denunciaDetail.modelo = data.model;
            //     this.operaciones.addOperation(data.operacio);
            //     this.toastr.success(this.i18n('Denuncia actualitzada correctament'));
            //   }
            //   if (data !== undefined && data !== null &&
            //     data.persona !== undefined &&  data.persona !== null ) {
            //       this.denunciaDetail.secure_id_propietari =  data.persona.secure_id;
            //       this.denunciaDetail.nom_propietari =  data.persona.nomComplet;
            //       this.denunciaDetail.dni_propietari = data.persona.dni_pasp;
            //       this.getDomicilis (data.persona.secure_id, 'Propietari',  data.persona.SECURE_ID_DOMICILI_ACTUAL);
            //     }
        }
    };
    DenunciesEditorComponent.prototype.openXmlPersona = function () {
        var _this = this;
        if (this.denunciaDetail.matricula !== undefined && this.denunciaDetail.matricula !== null && this.denunciaDetail.matricula !== '') {
            var ref = this.dialog.open(XmlTreeViewerComponent, {
                width: '75%',
                height: '75%',
                closeOnNavigation: true,
                autoFocus: true,
                data: {
                    'isDialog': true,
                    'isReadOnly': this.isReadOnly,
                    'matricula': '',
                    'persona': this.denunciaDetail.dni_conductor,
                    'propietario': '',
                    'incorporaData': true,
                    'consulta': false,
                    'secureIdDenuncia': this.denunciaDetail.secure_id_denuncia,
                    'fechaDenuncia': this.denunciaDetail.data
                }
            });
            ref.afterClosed().subscribe(function (data) {
                if (data !== undefined && data !== null && data !== '') {
                    _this.refrescar();
                    _this.toastr.success(_this.i18n('Denuncia actualitzada correctament'));
                }
            });
        }
    };
    DenunciesEditorComponent.prototype.openXmlPropietari = function () {
        var _this = this;
        if (this.denunciaDetail.matricula !== undefined && this.denunciaDetail.matricula !== null && this.denunciaDetail.matricula !== '') {
            var ref = this.dialog.open(XmlTreeViewerComponent, {
                width: '75%',
                height: '75%',
                closeOnNavigation: true,
                autoFocus: true,
                data: {
                    'isDialog': true,
                    'isReadOnly': this.isReadOnly,
                    'matricula': '',
                    'persona': this.denunciaDetail.dni_propietari,
                    'propietario': '',
                    'incorporaData': true,
                    'consulta': false,
                    'secureIdDenuncia': this.denunciaDetail.secure_id_denuncia,
                    'fechaDenuncia': this.denunciaDetail.data
                }
            });
            ref.afterClosed().subscribe(function (data) {
                if (data !== undefined && data !== null && data !== '') {
                    _this.refrescar();
                    _this.toastr.success(_this.i18n('Denuncia actualitzada correctament'));
                }
            });
        }
    };
    DenunciesEditorComponent.prototype.OpenNotifications = function () {
        var ref = this.dialog.open(NotificacionsComponent, {
            width: '90%',
            height: '90%',
            data: { 'element': this.denunciaDetail.expedient }
        });
    };
    // Calculem la data de descompte de la denuncia
    DenunciesEditorComponent.prototype.calcularDataDescompte = function () {
        if (typeof (this.denunciaDetail.data) === 'object' && this.denunciaDetail.data !== null && this.denunciaDetail.data !== undefined) {
            this.denunciaDetail.dataDescompte = new Date();
            this.denunciaDetail.dataDescompte.setDate(this.denunciaDetail.data.getDate() + this.diesDescompte);
        }
    };
    DenunciesEditorComponent.prototype.calcularImportDescompte = function () {
        var _this = this;
        if (this.denunciaDetail.import < this.denunciaDetail.importMinim || this.denunciaDetail.import > this.denunciaDetail.importMaxim) {
            setTimeout(function () {
                _this.denunciaDetail.import = _this.denunciaDetailOld.import;
            }, 10);
            if (this.denunciaDetail.importMinim !== this.denunciaDetail.importMaxim) {
                this.toastr.warning(this.i18n("El valor del importe debe estar entre " + this.denunciaDetail.importMinim + " \u20AC y " + this.denunciaDetail.importMaxim + " \u20AC"));
            }
            return;
        }
        if (this.denunciaDetail.import !== undefined && this.denunciaDetail.import !== null
            && this.denunciaDetail.porcentajeDescuento !== undefined && this.denunciaDetail.porcentajeDescuento !== null) {
            this.denunciaDetail.importDescompte = Number((this.denunciaDetail.import * (1 - (this.denunciaDetail.porcentajeDescuento / 100))).toFixed(2));
        }
    };
    DenunciesEditorComponent.prototype.solicitarRetiradaGrua = function () {
        var _this = this;
        this.denunciesService.solicitarRetiradaGrua(this.denunciaDetail.secure_id_denuncia).subscribe(function (x) {
            _this.denunciaDetail.tieneRetiradaGrua = true;
            _this.toastr.success(_this.i18n('Solicitud generada correctamente'));
        });
    };
    DenunciesEditorComponent.prototype.report = function () {
        var _this = this;
        var somIdDenuncia = this.denunciaDetail.somId;
        var idPropietari = this.denunciaDetail.secure_id_propietari;
        var idConductor = this.denunciaDetail.secure_id_conductor;
        var ref = this.dialog.open(ReportsComponent, {
            width: '30%',
            height: '30%',
            closeOnNavigation: false,
            autoFocus: true,
            data: this.reports
        }).afterClosed().subscribe(function (data) {
            var reportInfo = {
                'id': somIdDenuncia,
                'tipusReport': data.tipusReport,
                'isComuna': true,
                'nomFitxer': data.nomFitxer,
                'nom': data.nom
            };
            var usableDataStructure = Object.assign({}, reportInfo);
            for (var _i = 0, _a = Object.entries(reportInfo); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], value = _b[1];
                usableDataStructure[key] = reportInfo[key];
            }
            if (data.nom != 'CARTA DE PAGO' || (data.nom == 'CARTA DE PAGO' && ((idPropietari != '' && idPropietari != null) || (idConductor != '' && idConductor != null)))) {
                _this.reportService.generateReport(usableDataStructure, _this.URL_REPORT).subscribe(function (result) {
                    if (result) {
                        var objectUrl = URL.createObjectURL(result.body);
                        window.open(objectUrl);
                    }
                    else {
                        var contentType = result.headers.get('Content-Type');
                        if (contentType !== null && contentType !== undefined) {
                            FileDownloader.downLoadFile([result.body], contentType + ';charset=utf-8', "CARTA");
                        }
                        else {
                            _this.toastr.error(_this.i18n('No s\'ha pogut generar el fitxer'));
                        }
                    }
                });
            }
            else {
                _this.toastr.warning('Falta informar propietario o conductor');
            }
        });
    };
    DenunciesEditorComponent.prototype.openMap = function () {
        var _this = this;
        var ref = this.dialog.open(MapComponent, {
            width: '50%',
            height: '50%'
        });
        ref.afterOpen().subscribe(function () {
            var customDataMarker = _this.crearMarker('1', 'DENUNCIA', null, +_this.denunciaDetail.latitud, +_this.denunciaDetail.longitud, '#fc0303', '\uf3c5');
            var arrayCustomDataMarker = new Array();
            arrayCustomDataMarker.push(customDataMarker);
            ref.componentInstance.appendNewMarkers(arrayCustomDataMarker);
        });
        ref.componentInstance.currentLocation.latitude = +this.denunciaDetail.latitud;
        ref.componentInstance.currentLocation.longitude = +this.denunciaDetail.longitud;
        ref.componentInstance.zoom = 17;
    };
    DenunciesEditorComponent.prototype.crearMarker = function (associatedId, featureType, text, latitude, longitude, color, icon) {
        return new CustomDataMarker(associatedId, featureType, text, latitude, longitude, 'google.maps.SymbolPath.CIRCLE', color, 0.15, icon, false, false, false);
    };
    /** Abre un diálogo con la tabla de denuncias filtrada por el infractor reincidente */
    DenunciesEditorComponent.prototype.openReincidencias = function () {
        var filter = new DenunciaFilter('', '');
        filter.convivencia = true;
        filter.dni = this.denunciaDetail.dni_conductor;
        var data = Builder.newComponentDialog(true, false, '', filter);
        var dlg = this.dialog.open(DenunciesTableComponent, {
            closeOnNavigation: false,
            autoFocus: true,
            width: '90%',
            data: data,
        });
        if (this.dialogData === undefined) {
            if (close) {
                this.router.navigate(['denuncies']);
            }
        }
    };
    DenunciesEditorComponent.prototype.getImatgeEtiqueta = function (nomEtiqueta) {
        var linea = "assets/images/";
        linea = linea + nomEtiqueta;
        return linea;
    };
    return DenunciesEditorComponent;
}());
export { DenunciesEditorComponent };
