<form [formGroup]="RutaForm" (ngSubmit)="this.submit()">
  <label for="Nombre" i18n>Nombre</label>
  <input type="text" formControlName="Nombre" id="Nombre" class="form-control" required />
  <div
    *ngIf="RutaForm.controls['Nombre'].invalid && (RutaForm.controls['Nombre'].dirty || RutaForm.controls['Nombre'].touched)"
    class="alert alert-danger">
    <div *ngIf="RutaForm.controls['Nombre'].errors.required" i18n>
      El nombre es un campo obligatorio.
    </div>
  </div>
  <label for="Descripcion" i18n>Descripción</label>
  <input type="text" formControlName="Descripcion" id="Descripcion" class="form-control" required />
  <div
    *ngIf="RutaForm.controls['Descripcion'].invalid && (RutaForm.controls['Descripcion'].dirty || RutaForm.controls['Descripcion'].touched)"
    class="alert alert-danger">
    <div *ngIf="RutaForm.controls['Descripcion'].errors.required" i18n>
      La descripcion es un campo obligatorio.
    </div>
  </div>
  <hr />
  <div style="display: flex;justify-content: center;">
    <button type='button' mat-button color="primary" (click)="this.addTramo()">
      <mat-icon>add</mat-icon>
    </button>
    <button type='button' mat-button color="primary" (click)="this.removeTramo()">
      <mat-icon>remove</mat-icon>
    </button>
  </div>
  <agm-map [latitude]="currentLocation.latitude" [longitude]="currentLocation.longitude" [zoom]="this.zoomMapa" style="height: 300px;">
    <agm-marker *ngFor="let m of markers; let i = index"
      [latitude]="m.lat"
      [longitude]="m.lng"
      [label]="m.label"
      [markerDraggable]="m.draggable"
      (dragEnd)="this.markerDragEnd(m, $event)">
    </agm-marker>
    <agm-polyline>          
        <agm-polyline-point *ngFor="let ma of markers;" [latitude]="ma.lat" [longitude]="ma.lng" editable="true">                          
        </agm-polyline-point>              
    </agm-polyline>
  </agm-map>
  <button type='submit' mat-button color="primary">
    <mat-icon>save</mat-icon>
  </button>
</form>








<!-- <mat-select>
  <mat-option *ngFor="let travelMode of travelModes" [value]="travelMode">
   <mat-icon>{{TravelMode2LabelMapping[travelMode]}}</mat-icon>
  </mat-option>
</mat-select> -->