/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../components/propostes.component.ngfactory";
import * as i2 from "../components/propostes.component";
import * as i3 from "../../../shared/services/http/httpSecureClient";
import * as i4 from "@angular/router";
import * as i5 from "../services/propostes.service";
import * as i6 from "ngx-toastr";
import * as i7 from "../../../shared/services/images/images.service";
import * as i8 from "@ngx-translate/i18n-polyfill";
import * as i9 from "@angular/material/dialog";
import * as i10 from "../../../shared/services/authorization/authorization-service";
import * as i11 from "../../../shared/services/notification/notification.service";
import * as i12 from "../../../shared/services/validation/validation.service";
import * as i13 from "../../../shared/services/startup/startup.service";
import * as i14 from "../../../shared/services/parameters/parameters.service";
import * as i15 from "./propostes-page.component";
var styles_PropostesPageComponent = [];
var RenderType_PropostesPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PropostesPageComponent, data: {} });
export { RenderType_PropostesPageComponent as RenderType_PropostesPageComponent };
export function View_PropostesPageComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { propostes: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-propostes", [], null, null, null, i1.View_PropostesComponent_0, i1.RenderType_PropostesComponent)), i0.ɵdid(2, 4308992, [[1, 4]], 0, i2.PropostesComponent, [i3.HttpSecureClient, i4.ActivatedRoute, i4.Router, i5.PropostesService, i6.ToastrService, i7.ImagesService, i8.I18n, i9.MatDialog, i10.AuthorizationService, i11.NotificationService, i12.ValidationService, i13.StartupService, i14.ParametersService, i11.NotificationService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_PropostesPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-propostes-page", [], null, null, null, View_PropostesPageComponent_0, RenderType_PropostesPageComponent)), i0.ɵdid(1, 114688, null, 0, i15.PropostesPageComponent, [i5.PropostesService, i4.Router, i10.AuthorizationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PropostesPageComponentNgFactory = i0.ɵccf("app-propostes-page", i15.PropostesPageComponent, View_PropostesPageComponent_Host_0, {}, {}, []);
export { PropostesPageComponentNgFactory as PropostesPageComponentNgFactory };
