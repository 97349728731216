import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ResolucionFilter } from 'app/sections/notificacions/models/NotificacionDenuncia/resolucion-filter';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-listado-resoluciones-filter',
  templateUrl: './listado-resoluciones-filter.component.html',
  styleUrls: ['./listado-resoluciones-filter.component.scss']
})
export class ListadoResolucionesFilterComponent implements OnInit {
  subscription:Subscription;
  filter: ResolucionFilter;
  @Output() notify: EventEmitter<ResolucionFilter> = new EventEmitter<ResolucionFilter>();

  constructor(
    public thisDialogRef: MatDialogRef<ListadoResolucionesFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { 
      this.filter = data;
    }
  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.thisDialogRef.close();
  }

  ngOnInit() {
  }

}
