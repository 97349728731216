import { ConfirmacionBoeFilterService } from './sections/tramitar/teu/services/confirmacion-boe-filter.service';
import { NotificacionsPuntsDGTFilterService } from './sections/tramitar/dgt/services/notificacions-punts-dgt-filter.service';
//#region imports
import { BonosResidentesFilterService } from './sections/ciutat/gestio-residents/services/bonos-residentes-filter.service';
import { PersonaParserService } from './sections/utilitats/dgt/services/personaParser.service';
import { VehiculoParserService } from './sections/utilitats/dgt/services/vehiculoParser.service';
import { DenunciaPagamentParcialComponent } from './sections/denuncies/components/denuncia-pagament-parcial/denuncia-pagament-parcial.component';
import { GoogleMapsService } from './shared/services/startup/google-maps.service';
import { TarifasGruaService } from './sections/configuracio/grua/tarifas-grua/services/tarifa-grua-service';
import { TarifasGruaFilterService } from './sections/configuracio/grua/tarifas-grua/services/tarifa-grua-filter-service';
import { TipoSenalesVerticalesService } from './sections/configuracio/tipos-senales-verticales/services/tipo-senales-verticales-service';
import { TipoSenalesVerticalesFilterService } from './sections/configuracio/tipos-senales-verticales/services/tipo-senales-verticales-filter-service';
import { CalendarMultiselectInputComponent } from './shared/components/form/calendar-multiselect-input/calendar-multiselect-input.component';
import { DipositGruaFilterService } from './sections/grua/services/diposit-grua-filter-service';
import { GruaPendentsService } from './sections/grua/services/grua-pendents.service';
import { PaisosService } from './sections/configuracio/paisos/services/paisos.service.ts.service';
import { ColorsService } from './sections/configuracio/colors/services/colors.service';
import { TipusVehiclesService } from './sections/configuracio/tipus-vehicles/services/tipus-vehicles.service';
import { SiglasService } from './sections/configuracio/siglas/services/siglas.service';
import { AlarmesService } from './sections/alarmes/services/alarmes.service';
import { MotiusDesestimarTableComponent } from './sections/configuracio/motius-desestimar/components/motius-desestimar-table/motius-desestimar-table.component';
import { MotiusDesestimarService } from './sections/configuracio/motius-desestimar/services/motius-desestimar.service';
import { MotiusAnulacioService } from './sections/configuracio/motius-anulacio/services/motius-anulacio.service';
import { LegislacionsComponent } from './sections/configuracio/legislacions/pages/legislacions.component';
import { LegislacioService } from './sections/configuracio/legislacions/services/legislacions-service.service';
import { AparellService } from './sections/configuracio/aparells/services/aparells-service.service';
import { InfraccioService } from './sections/configuracio/Infraccions/services/infraccions-service.service';
import { AparellsComponent } from './sections/configuracio/aparells/pages/aparells.component';
import { TrackingService } from './sections/utilitats/tracking/services/tracking.service';
import { TramitadorHistoricService } from './sections/denuncies/services/tramitador-historic.service';
import { TableFieldLabelComponent } from './shared/components/table-fields/table-field-label/table-field-label.component';
import { FormSingleToggleComponent } from './shared/components/form/form-single-toggle/form-single-toggle.component';
import { MatriculesService } from 'app/sections/ciutat/matricules/services/matricules.service';
import { AppComponent } from './app.component';

import { AppRoutingModule } from './/app-routing.module';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { DashComponent } from './sections/dash/components/dash/dash.component';

import { DenunciesPageComponent } from './sections/denuncies/pages/denunciespage.component';
import { DashboardPageComponent } from './sections/dash/pages/dashboardpage.component';
import { PropostesPageComponent } from './sections/propostes/pages/propostes-page.component';
import { NotificacionsPageComponent } from './sections/notificacions/pages/notificacions-page.component';
import { AparcarePageComponent } from './sections/aparcare/pages/aparcare-page.component';
import { UtilitatsPageComponent } from './sections/utilitats/pages/utilitats-page.component';
import { CiutatPageComponent } from './sections/ciutat/pages/ciutat-page.component';
import { UsuarisPageComponent } from './sections/usuaris/pages/usuaris-page.component';
import { PermisosPageComponent } from './sections/permisos/pages/permisos-page.component';
import { AparcareComponent } from './sections/aparcare/component/aparcare.component';
import { PropostesComponent } from './sections/propostes/components/propostes.component';
import { NotificacionsComponent } from './sections/notificacions/components/notificacions.component';
import { PermisosComponent } from './sections/permisos/components/permisos.component';
import { UsuarisComponent } from './sections/usuaris/components/usuaris.component';
import { UtilitatsComponent } from './sections/utilitats/components/utilitats.component';
import { LoginPageComponent } from './sections/login/pages/login-page/login-page.component';
import { LoginService } from './sections/login/services/login.service';
import { AuthService } from './shared/services/authentication/auth.service';
import { ImagesService } from './shared/services/images/images.service';
import { AuthGuardService } from './shared/services/authentication/auth-guard.service';
import { DenunciesEditorComponent } from './sections/denuncies/components/denuncies-editor/denuncies-editor.component';
import { DenunciesFilterComponent } from './sections/denuncies/components/denuncies-filter/denuncies-filter.component';

import { DialogDraggableDirective } from './dialog/dialog-draggable.directive';

// custom services
import { HttpSecureClient } from './shared/services/http/httpSecureClient';
import { DenunciesService } from './sections/denuncies/services/denuncies.service';
import { DenunciesFilterService } from './sections/denuncies/services/denuncies-filter.service';
import { TipusMatriculesService } from './sections/ciutat/tipus-matricules/services/tipus-matricules.service';
import { DenunciesTableComponent } from './sections/denuncies/components/denuncies-table/denuncies-table.component';
import { PropostesService } from './sections/propostes/services/propostes.service';
import { PropostesFilterService } from './sections/propostes/services/propostes-filter.service';
import { CurrentService } from './sections/denuncies/services/current-details.sevice';
import { AuthorizationService } from './shared/services/authorization/authorization-service';
import { DadesExpedientComponent } from './sections/denuncies/components/denuncies-editor/components/dades-expedient/dades-expedient.component';
import { RetiradaComponent } from './sections/denuncies/components/denuncies-editor/components/retirada/retirada.component';
import { OperacionsComponent } from './sections/denuncies/components/denuncies-editor/components/operacions/operacions.component';
import { RefC60Component } from './sections/denuncies/components/denuncies-editor/components/ref-c60/ref-c60.component';
import { ConfirmationDialogService } from './shared/components/confirmation-dialog/confirmation-dialog.service';
import { StartupService } from './shared/services/startup/startup.service';
import { UsuarisService } from './sections/usuaris/services/usuaris.service';

// tslint:disable-next-line:max-line-length
import { ExpRelacionatsComponent } from './sections/denuncies/components/denuncies-editor/components/exp-relacionats/exp-relacionats.component';
import { RecaptacioComponent } from './sections/denuncies/components/denuncies-editor/components/recaptacio/recaptacio.component';
import { DenunciantComponent } from './sections/denuncies/components/denuncies-editor/components/denunciant/denunciant.component';
import { DenunciesImagesComponent } from './sections/denuncies/components/denuncies-editor/components/denuncies-images/denuncies-images.component';
import { DenunciesNewComponent } from './sections/denuncies/components/denuncies-new/denuncies-new.component';
import { NovaOperacioComponent } from './sections/denuncies/components/denuncies-editor/components/nova-operacio/nova-operacio.component';
import { ConfirmationDialogComponent } from './shared/components/confirmation-dialog/confirmation-dialog.component';
import { PhotoBoxComponent } from './shared/components/photo-box/photo-box.component';
import { PhotoBoxViewerComponent } from 'path-shared/components/photo-box/components/photo-box-viewer/photo-box-viewer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { ExpedientsComponent } from './sections/expedients/expedients.component';
import { DialogListComponent } from './sections/dialog-list/dialog-list.component';
import { PhotoBoxGalleryComponent } from './shared/components/photo-box/components/photo-box-gallery/photo-box-gallery.component';
import { SingleImageViewerComponent } from './shared/components/photo-box/components/single-image-viewer/single-image-viewer.component';
import { DropdownInstitucionsComponent } from './shared/components/dropdown-institucions/dropdown-institucions.component';
import { DropdownInstitucionsService } from 'path-shared/components/dropdown-institucions/dropdown-institucions.service';


import localeCa from '@angular/common/locales/ca';
import localeEs from '@angular/common/locales/es';
import { TestComponent } from './shared/components/test/test.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { PropostesDesestimarComponent } from './sections/propostes/components/propostes-desestimar/propostes-desestimar.component';
import { PropostesListComponent } from './sections/propostes/components/propostes-list/propostes-list.component';
import { PropostesFilterComponent } from './sections/propostes/components/propostes-list/components/propostes-filter/propostes-filter.component';
import { PropostesTableComponent } from './sections/propostes/components/propostes-list/components/propostes-table/propostes-table.component';
import { TramitadorComponent } from './sections/denuncies/components/tramitador/tramitador.component';
import { PermissionDialogComponent } from './shared/components/permission-dialog/permission-dialog.component';
import { PermissionEditorComponent } from './shared/components/permission-dialog/components/permission-editor/permission-editor.component';
import { DashboardService } from './sections/dash/services/dashboard.service';
import { DashSquareComponent } from './sections/dash/components/dash-square/dash-square.component';
import { ToolButtonComponent } from './shared/components/toolbar/tool-button/tool-button.component';
import { ToolbarComponent } from './shared/components/toolbar/toolbar/toolbar.component';
import { DialogHeaderComponent } from './shared/components/dialog/dialog-header/dialog-header.component';
import { FilterHeaderComponent } from './shared/components/dialog/filter-header/filter-header.component';
import { FormTextEditComponent } from './shared/components/form/form-text-edit/form-text-edit.component';
import { FormTextCurrencyEditComponent } from './shared/components/form/form-text-currency-edit/form-text-currency-edit.component';
import { FormComboEditComponent } from './shared/components/form/form-combo-edit/form-combo-edit.component';
import { DenunciesEstatComponent } from './sections/denuncies/components/denuncies-estat/denuncies-estat.component';
import { PermissionStatusComponent } from './shared/components/permission-dialog/components/permission-status/permission-status.component';
import { MatriculesComponent } from './sections/ciutat/matricules/pages/matricules/matricules.component';
import { MatriculesTableComponent } from './sections/ciutat/matricules/components/matricules-table/matricules-table.component';
import { MatriculaFilterService } from './sections/ciutat/matricules/services/matricules-filter.service';
import { TipusMatriculaFilterService } from './sections/ciutat/tipus-matricules/services/tipus-matricules-filter.service';
import { TipusMatriculesComponent } from './sections/ciutat/tipus-matricules/pages/tipus-matricules.component';
import { TipusMatriculesTableComponent } from './sections/ciutat/tipus-matricules/components/tipus-matricules-table/tipus-matricules-table.component';
import { ShortDatePipe } from 'path-shared/pipes/short-date-pipe';
import { SeriePipe } from './shared/pipes/serie-pipe';
import { SingleEditDialogComponent } from './shared/components/dialogs/single-edit-dialog/single-edit-dialog.component';
import { MatriculesEditorComponent } from './sections/ciutat/matricules/components/matricules-editor/matricules-editor.component';
import { MatriculesFilterComponent } from './sections/ciutat/matricules/components/matricules-filter/matricules-filter.component';
import { TipusMatriculesEditorComponent } from './sections/ciutat/tipus-matricules/components/tipus-matricules-editor/tipus-matricules-editor.component';
import { MessagingService } from 'path-shared/services/messaging/messaging.service';
import { AppInjectorModule } from './app-injector/app-injector.module';
import { TipusMatriculesFilterComponent } from './sections/ciutat/tipus-matricules/components/tipus-matricules-filter/tipus-matricules-filter.component';
import { FormCheckEditComponent } from './shared/components/form/form-check-edit/form-check-edit.component';
import { PageTitleComponent } from './shared/components/helpers/page-title/page-title.component';
import { FilterSelectorComponent } from './shared/components/filter/filter-selector/filter-selector.component';
import { FormTriStateComponent } from './shared/components/form/form-tri-state/form-tri-state.component';
import { FormTriStateVerticalComponent } from './shared/components/form/form-tri-state-vertical/form-tri-state-vertical.component';
import { AgentsComponent } from './sections/configuracio/agents/pages/agents/agents.component';
import { AgentsTableComponent } from './sections/configuracio/agents/components/agents-table/agents-table.component';
import { AgentsFilterComponent } from './sections/configuracio/agents/components/agents-filter/agents-filter.component';
import { AgentsFilterService } from './sections/configuracio/agents/services/agents-filter-service';
import { AgentsService } from './sections/configuracio/agents/services/agents-service.service';
import { AgentsEditorComponent } from './sections/configuracio/agents/components/agents-editor/agents-editor.component';
import { NavItemComponent } from './shared/components/navbar/nav-item/nav-item.component';
import { NavDropdownItemComponent } from './shared/components/navbar/nav-dropdown-item/nav-dropdown-item.component';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { DialogBodyComponent } from './shared/components/dialog/dialog-body/dialog-body.component';
import { DialogFooterComponent } from './shared/components/dialog/dialog-footer/dialog-footer.component';
import { TramitadorService } from 'path-denuncies-services/tramitador.service';
import { PropostesTableEstatComponent } from './sections/propostes/components/propostes-list/components/propostes-table-estat/propostes-table-estat.component';
import { FormLiveStateComponent } from './shared/components/form/form-live-state/form-live-state.component';
import { DashInputFilesStatusComponent } from './sections/dash/components/dash-input-files-status/dash-input-files-status.component';
import { WaitDialogComponent } from './shared/components/dialogs/wait-dialog/wait-dialog.component';
import { TramitadorHistoricComponent } from './sections/denuncies/components/tramitador-historic/tramitador-historic.component';
import { ExpedienteService } from 'path-denuncies-services/expediente.service';
import { ExpedienteFilterService } from 'path-denuncies-services/expediente-filter.service';
import { ViewListIconComponent } from './shared/components/helpers/view-list-icon/view-list-icon.component';
import { ToolButtonPermissionsComponent } from './shared/components/toolbar/tool-button-permissions/tool-button-permissions.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { LoaderInterceptorService } from 'path-shared/components/loader/loader-interceptor.service';
import { MatriculesLoaderComponent } from './sections/ciutat/matricules/components/matricules-loader/matricules-loader.component';
import { FormFileUploadComponent } from './shared/components/form/form-file-upload/form-file-upload.component';
import { FormRadioGroupComponent } from './shared/components/form/form-radio-group/form-radio-group.component';
import { InstitucionsService } from 'path-shared/services/institucions/institucions.service';
import { SizePipe } from 'path-shared/pipes/size-pipe';
import { FilterTypeComponent } from './shared/components/filter/filter-type/filter-type.component';
import { FilterTextEditComponent } from './shared/components/filter/filter-text-edit/filter-text-edit.component';
import { PropostesEditorComponent } from './sections/propostes/components/propostes-editor/propostes-editor.component';
import { DownloadExcelDialogComponent } from './shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { ExcelDownloadService } from './shared/services/excel/excel-download.service';
import { FormatDatePipe } from 'path-shared/pipes/format-date-pipe';
import { LongDatePipe } from 'path-shared/pipes/long-date-pipe';
import { MoneyPipe } from 'path-shared/pipes/money-pipe';
import { NomPaisPipe } from 'path-shared/pipes/nom-pais-pipe';
import { SelectionManagerComponent } from './shared/components/helpers/selection-manager/selection-manager.component';
import { MatriculaComponent } from './shared/components/helpers/matricula/matricula.component';
import { AparellsTableComponent } from './sections/configuracio/aparells/components/aparells-table/aparells-table.component';
import { AparellsFilterComponent } from './sections/configuracio/aparells/components/aparells-filter/aparells-filter.component';
import { AparellsEditorComponent } from './sections/configuracio/aparells/components/aparells-editor/aparells-editor.component';
import { LegislacionsTableComponent } from './sections/configuracio/legislacions/components/legislacions-table/legislacions-table.component';
import { LegislacionsFilterComponent } from './sections/configuracio/legislacions/components/legislacions-filter/legislacions-filter.component';
import { LegislacionsEditorComponent } from './sections/configuracio/legislacions/components/legislacions-editor/legislacions-editor.component';
import { MotiusAnulacioComponent } from './sections/configuracio/motius-anulacio/pages/motius-anulacio/motius-anulacio.component';
import { MotiusAnulacioEditorComponent } from './sections/configuracio/motius-anulacio/components/motius-anulacio-editor/motius-anulacio-editor.component';
import { MotiusAnulacioFilterComponent } from './sections/configuracio/motius-anulacio/components/motius-anulacio-filter/motius-anulacio-filter.component';
import { MotiusAnulacioTableComponent } from './sections/configuracio/motius-anulacio/components/motius-anulacio-table/motius-anulacio-table.component';
import { PropostaViewerComponent } from './sections/propostes/components/proposta-viewer/proposta-viewer.component';
import { PropostaDialogComponent } from './sections/propostes/components/propostes-list/components/proposta-dialog/proposta-dialog.component';
import { MotiusDesestimarFilterComponent } from './sections/configuracio/motius-desestimar/components/motius-desestimar-filter/motius-desestimar-filter.component';
import { MotiusDesestimarEditorComponent } from './sections/configuracio/motius-desestimar/components/motius-desestimar-editor/motius-desestimar-editor.component';
import { MotiusDesestimarComponent } from './sections/configuracio/motius-desestimar/pages/motius-desestimar/motius-desestimar.component';
import { CookiePolicyComponent } from './pages/cookie-policy/cookie-policy.component';
import { PublicHeaderComponent } from './shared/components/public-header/public-header.component';
import { MatriculesHistoricComponent } from './sections/ciutat/matricules/pages/matricules-historic/matricules-historic.component';
import { MatriculesHistoricFilterComponent } from './sections/ciutat/matricules/components/matricules-historic-filter/matricules-historic-filter.component';
import { MatriculesHistoricTableComponent } from './sections/ciutat/matricules/components/matricules-historic-table/matricules-historic-table.component';
import { MatriculaHistoricFilterService } from './sections/ciutat/matricules/services/matricules-historic-filter.service';
import { FormTextAreaComponent } from './shared/components/form/form-text-area/form-text-area.component';
import { AlarmHeaderMenuComponent } from './shared/components/alarms/alarm-header-menu/alarm-header-menu.component';
import { AlarmesTableComponent } from './sections/alarmes/components/alarmes-table/alarmes-table.component';
import { AlarmesFilterComponent } from './sections/alarmes/components/alarmes-filter/alarmes-filter.component';
import { AlarmesComponent } from './sections/alarmes/pages/alarmes/alarmes.component';
import { DeteccioMatriculesComponent } from './sections/ciutat/deteccio-matricules/pages/deteccio-matricules/deteccio-matricules.component';
import { DeteccioMatriculesTableComponent } from './sections/ciutat/deteccio-matricules/components/deteccio-matricules-table/deteccio-matricules-table.component';
import { GalleryComponent } from './shared/components/gallery/gallery/gallery.component';
import { ParametersService } from 'path-shared/services/parameters/parameters.service';
import { CustomReuseStrategy } from 'path-shared/models/custom-reuse-strategy';
import { SiglasComponent } from './sections/configuracio/siglas/pages/siglas/siglas.component';
import { SiglasTableComponent } from './sections/configuracio/siglas/components/siglas-table/siglas-table.component';
import { SiglasFilterComponent } from './sections/configuracio/siglas/components/siglas-filter/siglas-filter.component';
import { SiglasEditorComponent } from './sections/configuracio/siglas/components/siglas-editor/siglas-editor.component';
import { TipusVehiclesTableComponent } from './sections/configuracio/tipus-vehicles/components/tipus-vehicles-table/tipus-vehicles-table.component';
import { TipusVehiclesFilterComponent } from './sections/configuracio/tipus-vehicles/components/tipus-vehicles-filter/tipus-vehicles-filter.component';
import { TipusVehiclesEditorComponent } from './sections/configuracio/tipus-vehicles/components/tipus-vehicles-editor/tipus-vehicles-editor.component';
import { TipusVehiclesComponent } from './sections/configuracio/tipus-vehicles/pages/tipus-vehicles/tipus-vehicles.component';
import { ColorsComponent } from './sections/configuracio/colors/pages/colors/colors.component';
import { ColorsEditorComponent } from './sections/configuracio/colors/components/colors-editor/colors-editor.component';
import { ColorsFilterComponent } from './sections/configuracio/colors/components/colors-filter/colors-filter.component';
import { ColorsTableComponent } from './sections/configuracio/colors/components/colors-table/colors-table.component';
import { CarrersTableComponent } from './sections/configuracio/carrers/components/carrers-table/carrers-table.component';
import { CarrersComponent } from './sections/configuracio/carrers/pages/carrers/carrers.component';
import { CarrersFilterComponent } from './sections/configuracio/carrers/components/carrers-filter/carrers-filter.component';
import { MarquesVehiclesTableComponent } from './sections/configuracio/marques-vehicles/components/marques-vehicles-table/marques-vehicles-table.component';
import { MarquesVehiclesComponent } from './sections/configuracio/marques-vehicles/pages/marques-vehicles.component';
import { MarquesVehiclesFilterComponent } from './sections/configuracio/marques-vehicles/components/marques-vehicles-filter/marques-vehicles-filter.component';
import { MarquesVehiclesFilterService } from './sections/configuracio/marques-vehicles/services/marques-vehicles-filter-service.service';
import { MarquesVehiclesService } from './sections/configuracio/marques-vehicles/services/marques-vehicles-service.service';
import { MarquesVehiclesEditorComponent } from './sections/configuracio/marques-vehicles/components/marques-vehicles-editor/marques-vehicles-editor.component';
import { InfraccioComponent } from './sections/configuracio/Infraccions/pages/infraccio.component';
import { InfraccionsEditorComponent } from './sections/configuracio/Infraccions/components/infraccions-editor/infraccions-editor.component';
import { InfraccionsFilterComponent } from './sections/configuracio/Infraccions/components/infraccions-filter/infraccions-filter.component';
import { InfraccionsTableComponent } from './sections/configuracio/Infraccions/components/infraccions-table/infraccions-table.component';
import { InfraccioFilterService } from './sections/configuracio/Infraccions/services/infraccions-filter-service';
import { FormCheckEditTriComponent } from './shared/components/form/form-check-edit-tri/form-check-edit-tri.component';
import { AliesGruaService } from './sections/grua/services/alies-grua.service';
import { AliesGruaFilterService } from './sections/grua/services/alies-grua-filter-service';
import { FileBoxComponent } from './shared/components/file-box/file-box.component';
import { FileBoxService } from 'path-shared/components/file-box/file-box-service';
import { FileBoxImageComponent } from './shared/components/file-box/file-box-image/file-box-image.component';
import { UploadToolButtonComponentComponent } from './shared/components/toolbar/upload-tool-button-component/upload-tool-button-component.component';
import { FileViewerComponent } from './shared/components/file-box/file-viewer/file-viewer.component';
import { PaisosComponent } from './sections/configuracio/paisos/pages/paisos/paisos.component';
import { PaisosEditorComponent } from './sections/configuracio/paisos/components/paisos-editor/paisos-editor.component';
import { PaisosTableComponent } from './sections/configuracio/paisos/components/paisos-table/paisos-table.component';
import { PaisosFilterComponent } from './sections/configuracio/paisos/components/paisos-filter/paisos-filter.component';
import { ZonesComponent } from './sections/ciutat/zones/pages/zones.component';
import { ZonesTableComponent } from './sections/ciutat/zones/components/zones-table/zones-table.component';
import { ZonesEditarComponent } from './sections/ciutat/zones/components/zones-editar/zones-editar.component';
import { TarifaFilterService } from './sections/ciutat/zones/services/tarifa-filter.service';
import { TarifaService } from './sections/ciutat/zones/services/tarifa-service';
import { TiketService } from './sections/ciutat/zones/services/tiket-service.';
import { TransformDataPipe } from './shared/pipes/transform-data.pipe';
import { CarrersService } from './sections/configuracio/carrers/services/carrers-service.service';
import { FormMultiSelectComponent } from './shared/components/form/form-multi-select/form-multi-select/form-multi-select.component';
import { CalleEditorComponent } from './sections/configuracio/carrers/components/calle-editor/calle-editor.component';
import { ZonesFilterComponent } from './sections/ciutat/zones/components/zones-filter/zones-filter.component';
import { LocalizacionService } from './sections/configuracio/aparells/services/localizacion.service';
import { LocalizacionFilterService } from './sections/configuracio/aparells/services/localizacion-filter.service';
import { AnulacionZonaAzulManualComponent } from './sections/anulacion-zona-azul-manual/page/anulacion-zona-azul-manual.component';
import { AnulacionZonaAzulAutoComponent } from './sections/anulacion-zona-azul-auto/page/anulacion-zona-azul-auto.component';
import { AnulacionZonaServiceAuto } from './sections/anulacion-zona-azul-auto/service/anulacion-zona.service';
import { AnulacionZonaServiceManual } from './sections/anulacion-zona-azul-manual/service/anulacion-zona.service';
import { AparellTipusService } from './sections/configuracio/aparells/services/aparellTipus.service';
import { AparellTipusFilterService } from './sections/configuracio/aparells/services/aparellTipus-filter.service';
import { TimeRangeInputComponent } from 'path-shared/components/form/form-time-range-input/time-range-input.component';
import { DateRangeInputComponent } from 'path-shared/components/form/form-date-range-edit/form-date-range-edit.component';
import { ImportacioDenunciesComponent } from './sections/denuncies/components/importacio/importacio-denuncies.component';
import { ImportacioDenunciaService } from './sections/denuncies/services/importacioDenuncia.service';
import { PropostesLlocEditorComponent } from './sections/propostes/components/propostes-lloc-editor/propostes-lloc-editor.component';
import { FormFileMultipleUploadComponent } from 'path-shared/components/form/form-file-multiple-upload/form-file-multiple-upload.component';
import { EstadistiquesSetmanalsComponent } from './sections/estadistiques/pages/estadistiques-setmanals/estadistiques-setmanals.component';
import { EstadistiquesMensualsComponent } from './sections/estadistiques/pages/estadistiques-mensuals/estadistiques-mensuals.component';
import { EstadistiquesService } from './sections/estadistiques/services/estadistiques.service';
import { ComunicacioPdaComponent } from './sections/comunicacio-pda/pages/comunicacio-pda-page.component';
import { ComunicacioPdaService } from './sections/comunicacio-pda/services/comunicacio-pda.service';
import { VisorAlarmesComponent } from './sections/alarmes/components/visor-alarmes/visor-alarmes.component';
import { AlarmesGpsService } from './sections/alarmes/services/alarmesGps.service';
import { AlarmaDetallComponent } from './sections/alarmes/components/alarma-detall/alarma-detall.component';
import { TrackingComponent } from './sections/utilitats/tracking/tracking.component';

import { SincronitzacioComponent } from './sections/configuracio/sincronitzacio/pages/sincronitzacio/sincronitzacio.component';

import { FormTabChatDynamicComponent } from 'path-shared/components/form/form-tab-chat-dynamic/form-tab-chat-dynamic.component';
import { AnulacionZonaAzulAutoFilterComponent } from './sections/anulacion-zona-azul-auto/components/anulacion-zona-azul-auto-filter/anulacion-zona-azul-auto-filter.component';
import { AnulacionZonaAzulAutoHistoricComponent } from './sections/anulacion-zona-azul-auto/components/anulacion-zona-azul-auto-historic/anulacion-zona-azul-auto-historic.component';
import { AvisosConfComponent } from './sections/configuracio/avisos/pages/avisos.component';
import { AvisosTableComponent } from './sections/configuracio/avisos/component/avisos-table/avisos-table.component';

import { AvisosAddNodeComponent } from './sections/configuracio/avisos/component/avisos-add-node/avisos-add-node.component';
import { AvisosComponent } from './sections/utilitats/avisos/avisos.component';
import { AvisosConfService } from './sections/configuracio/avisos/services/avisosConf.service';
import { AvisosService } from './sections/utilitats/avisos/services/avisos.service';
import { AvisosFilterComponent } from './sections/utilitats/avisos/components/avisos-filter/avisos-filter.component';
import { ChangePasswordComponent } from './sections/usuaris/components/change-password/change-password.component';
import { ReportsComponent } from './shared/components/reports/reports.component';
import { TiquetsPageComponent } from './sections/tiquets/pages/tiquets-page.component';
import { TiquetsTiquetsTableComponent } from './sections/tiquets/component/tiquets-table/tiquets-tiquets-table.component';
import { TiquetsDevolucionesTableComponent } from './sections/tiquets/component/tiquets-devoluciones-table/tiquets-devoluciones-table.component';
import { TiquetsAnulacionesTableComponent } from './sections/tiquets/component/tiquets-anulaciones-table/tiquets-anulaciones-table.component';
import { TiquetsFilterComponent } from './sections/tiquets/component/tiquets-filter/tiquets-filter.component';
import { TiquetsService } from './sections/tiquets/services/tiquets.service';
import { TiquetsDevolucionesService } from './sections/tiquets/services/tiquets-devoluciones.service';
import { TiquetsAnulacionesService } from './sections/tiquets/services/tiquets-anulaciones.service';
import { ModelsVehiclesComponent } from './sections/configuracio/marques-vehicles/components/models-vehicles/models-vehicles.component';
import { PersonesEditorComponent } from './sections/ciutat/persones/components/persones-editor/persones-editor.component';
import { PersonesService } from './sections/ciutat/persones/services/persones.service';
import { PersonesTableComponent } from './sections/ciutat/persones/components/persones-table/persones-table.component';
import { PersonesComponent } from './sections/ciutat/persones/pages/persones/persones.component';
import { PersonesFilterComponent } from './sections/ciutat/persones/components/persones-filter/persones-filter.component';
import { PersonesFilterService } from './sections/ciutat/persones/services/persones-filter-service';
import { DomicilisEditorComponent } from './sections/ciutat/persones/components/domicilis-editor/domicilis-editor.component';
import { ComboBoxInputComponent } from './shared/components/form/combo-box-input/combo-box-input.component';
import { FestiusTableComponent } from './sections/tiquets/festius/components/festius-table/festius-table.component';
import { FestiusEditorComponent } from './sections/tiquets/festius/components/festius-editor/festius-editor.component';
import { FestiusFilterComponent } from './sections/tiquets/festius/components/festius-filter/festius-filter.component';
import { FestiusComponent } from './sections/tiquets/festius/festius.component';
import { FestiusService } from './sections/tiquets/festius/services/festius.service';
import { TipusTiquetsComponent } from './sections/tiquets/tipus-tiquets/tipus-tiquets.component';
import { TipusTiquetsTableComponent } from './sections/tiquets/tipus-tiquets/components/tipus-tiquets-table/tipus-tiquets-table.component';
import { TipusTiquetsEditorComponent } from './sections/tiquets/tipus-tiquets/components/tipus-tiquets-editor/tipus-tiquets-editor.component';
import { TipusTiquetsFilterComponent } from './sections/tiquets/tipus-tiquets/components/tipus-tiquets-filter/tipus-tiquets-filter.component';
import { TipusTiquetsService } from './sections/tiquets/tipus-tiquets/services/tipus-tiquets.service';
import { DisplayTextDialogComponent } from './shared/components/dialogs/display-text-dialog/display-text-dialog.component';
import { VehiclesTableComponent } from './sections/ciutat/vehicles/components/vehicles-table/vehicles-table.component';
import { VehiclesComponent } from './sections/ciutat/vehicles/pages/vehicles/vehicles.component';
import { VehiclesFilterComponent } from './sections/ciutat/vehicles/components/vehicles-filter/vehicles-filter.component';
import { VehiclesEditorComponent } from './sections/ciutat/vehicles/components/vehicles-editor/vehicles-editor.component';
import { VehiclesService } from './sections/ciutat/vehicles/services/vehicles.service';
import { VehiclesFilterService } from './sections/ciutat/vehicles/services/vehicles-filter.service';
import { XmlTreeViewerComponent } from './sections/utilitats/dgt/components/xml-tree-viewer/xml-tree-viewer.component';
import { DgtService } from './sections/utilitats/dgt/services/dgtService.service';
import { DgtFilterComponent } from './sections/utilitats/dgt/components/dgt-filter/dgt-filter.component';

import { SeguretatUsuarisPageComponent } from './sections/seguretat/seguretat-usuaris/pages/seguretat-usuaris-page.component';
import { SeguretatGrupsPageComponent } from './sections/seguretat/seguretat-grups/pages/seguretat-grups-page.component';
import { SeguretatUsuarisService } from './sections/seguretat/seguretat-usuaris/services/seguretat-usuaris.service';
import { SeguretatUsuarisFilterService } from './sections/seguretat/seguretat-usuaris/services/seguretat-usuaris-filter.service';
import { SeguretatUsuarisTableComponent } from './sections/seguretat/seguretat-usuaris/components/seguretat-usuaris-table/seguretat-usuaris-table.component';
import { SeguretatUsuarisEditorComponent } from './sections/seguretat/seguretat-usuaris/components/seguretat-usuaris-editor/seguretat-usuaris-editor.component';
import { SeguretatGrupsTableComponent } from './sections/seguretat/seguretat-grups/components/seguretat-grups-table/seguretat-grups-table.component';
import { SeguretatGrupsService } from './sections/seguretat/seguretat-grups/services/seguretat-grups.service';
import { SeguretatGrupsFilterService } from './sections/seguretat/seguretat-grups/services/seguretat-grups-filter.service';
import { SeguretatGrupsEditorComponent } from './sections/seguretat/seguretat-grups/components/seguretat-grups-editor/seguretat-grups-editor.component';
import { SeguretatUsuarisFilterComponent } from './sections/seguretat/seguretat-usuaris/components/seguretat-usuaris-filter/seguretat-usuaris-filter.component';
import { ComprovacioTiquetsComponent } from './sections/zona-blava/comprovacio-tiquets/pages/comprovacio-tiquets.component';
import { ComprovacioTiquetsService } from './sections/zona-blava/comprovacio-tiquets/services/comprovacioTiquets.service';
import { RecaudacioPageComponent } from './sections/zona-blava/recaudacio/pages/recaudacio-page.component';
import { RecaudacioEditorComponent } from './sections/zona-blava/recaudacio/components/recaudacio-editor/recaudacio-editor.component';
import { RecaudacioTableComponent } from './sections/zona-blava/recaudacio/components/recaudacio-table/recaudacio-table.component';
import { RecaudacioService } from './sections/zona-blava/recaudacio/services/recaudacio.service';
import { RecaudacioFilterComponent } from './sections/zona-blava/recaudacio/components/recaudacio-filter/recaudacio-filter.component';
import { ElegantComboBoxInputComponent } from './shared/components/form/elegant-combo-box-input/elegant-combo-box-input.component';
import { NotificacionsEditorComponent } from './sections/notificacions/components/notificacions-editor/notificacions-editor.component';
import { NotificacionsService } from './sections/notificacions/services/notificacions.service';
import { NotificacionsFilterComponent } from './sections/notificacions/components/notificacions-filter/notificacions-filter.component';
import { RespostesComponent } from './sections/notificacions/components/respostes/respostes.component';
import { ChipsListInputComponent } from './shared/components/form/chips-list-input/chips-list-input.component';
import { DenunciaCobrarComponent } from './sections/denuncies/components/denuncies-editor/components/denuncia-cobrar/denuncia-cobrar.component';
import { DenunciesCobrarService } from 'path-denuncies-services/denuncies-cobrar.service';
import { ComboBoxInputAutocompleteComponent } from './shared/components/form/combo-box-input-autocomplete/combo-box-input-autocomplete.component';
import { PendentsComponent } from './sections/grua/components/pendents/pendents.component';
import { TramitadesComponent } from './sections/grua/components/tramitades/tramitades.component';
import { PendentsTableComponent } from './sections/grua/components/pendents-table/pendents-table.component';
import { GruaTecnicsComponent } from './sections/configuracio/grua/grua-tecnics/grua-tecnics.component';
import { GruaTecnicsTableComponent } from './sections/configuracio/grua/grua-tecnics/components/grua-tecnics-table/grua-tecnics-table.component';
import { GruaTecnicsService } from './sections/configuracio/grua/grua-tecnics/services/grua-tecnics.service';
import { GruaTecnicsFilterService } from './sections/configuracio/grua/grua-tecnics/services/grua-tecnics-filter.service';
import { GruaTecnicsEditorComponent } from './sections/configuracio/grua/grua-tecnics/components/grua-tecnics-editor/grua-tecnics-editor.component';
import { GruaTecnicsFilterComponent } from './sections/configuracio/grua/grua-tecnics/components/grua-tecnics-filter/grua-tecnics-filter.component';
import { GruaMotiusDesestimarEditorComponent } from './sections/configuracio/grua/grua-motius-desestimar/components/grua-motius-desestimar-editor/grua-motius-desestimar-editor.component';
import { GruaMotiusDesestimarComponent } from './sections/configuracio/grua/grua-motius-desestimar/grua-motius-desestimar.component';
import { GruaMotiusDesestimarTableComponent } from './sections/configuracio/grua/grua-motius-desestimar/components/grua-motius-desestimar-table/grua-motius-desestimar-table.component';
import { GruaMotiusDesestimarFilterComponent } from './sections/configuracio/grua/grua-motius-desestimar/components/grua-motius-desestimar-filter/grua-motius-desestimar-filter.component';
import { GruaMotiusDesestimarService } from './sections/configuracio/grua/grua-motius-desestimar/services/grua-motius-desestimar.service';
import { GruaMotiusDesestimarFilterService } from './sections/configuracio/grua/grua-motius-desestimar/services/grua-motius-desestimar-filter.service';
import { GruaMotiusEntradaComponent } from './sections/configuracio/grua/grua-motius-entrada/grua-motius-entrada.component';
import { GruaMotiusEntradaTableComponent } from './sections/configuracio/grua/grua-motius-entrada/components/grua-motius-entrada-table/grua-motius-entrada-table.component';
import { GruaMotiusEntradaEditorComponent } from './sections/configuracio/grua/grua-motius-entrada/components/grua-motius-entrada-editor/grua-motius-entrada-editor.component';
import { GruaMotiusEntradaFilterComponent } from './sections/configuracio/grua/grua-motius-entrada/components/grua-motius-entrada-filter/grua-motius-entrada-filter.component';
import { GruaMotiusEntradaService } from './sections/configuracio/grua/grua-motius-entrada/services/grua-motius-entrada.service';
import { GruaMotiusEntradaFilterService } from './sections/configuracio/grua/grua-motius-entrada/services/grua-motius-entrada-filter.service';
import { GruaAliesComponent } from './sections/configuracio/grua/grua-alies/grua-alies.component';
import { GruaAliesTableComponent } from './sections/configuracio/grua/grua-alies/components/grua-alies-table/grua-alies-table.component';
import { GruaAliesEditorComponent } from './sections/configuracio/grua/grua-alies/components/grua-alies-editor/grua-alies-editor.component';
import { GruaAliesFilterComponent } from './sections/configuracio/grua/grua-alies/components/grua-alies-filter/grua-alies-filter.component';
import { TramitadesTableComponent } from './sections/grua/components/tramitades-table/tramitades-table.component';
import { TramitadesFilterComponent } from './sections/grua/components/tramitades-filter/tramitades-filter.component';
import { DipositComponent } from './sections/grua/components/diposit/diposit.component';
import { DipositTableComponent } from './sections/grua/components/diposit-table/diposit-table.component';
import { DipositFilterComponent } from './sections/grua/components/diposit-filter/diposit-filter.component';
import { DipositEditorComponent } from './sections/grua/components/diposit-editor/diposit-editor.component';
import { DipositGruaService } from './sections/grua/services/diposit-grua-service';
import { DipositEditorGeneralComponent } from './sections/grua/components/diposit-editor-general/diposit-editor-general.component';
import { PendentsViewerComponent } from './sections/grua/components/pendents-viewer/pendents-viewer.component';

import { DateUtils } from 'path-shared/utils/date-utils';
import { NovaOperacioPropostesComponent } from './sections/propostes/components/propostes-list/components/nova-operacio-propostes/nova-operacio-propostes.component';
import { PhotoBoxService } from 'path-shared/components/photo-box/photo-box.service';
import { TarifesComponent } from './sections/tiquets/tarifes/tarifes.component';
import { TarifesTableComponent } from './sections/tiquets/tarifes/components/tarifes-table/tarifes-table.component';
import { TarifesService } from './sections/tiquets/tarifes/services/tarifes.service';
import { TarifesEditorComponent } from './sections/tiquets/tarifes/components/tarifes-editor/tarifes-editor.component';
import { TarifesFilterComponent } from './sections/tiquets/tarifes/components/tarifes-filter/tarifes-filter.component';
import { TarifesFilterService } from './sections/tiquets/tarifes/services/tarifes-filter.service';
import { GridImagesComponent } from 'path-shared/components/grid-images/grid-images/grid-images.component';
import { DashContadoresDuplicadosComponent } from './sections/dash/components/dash/dash-contadores-duplicados/dash-contadores-duplicados.component';
import { DipositEditorQuiRecullComponent } from './sections/grua/components/diposit-editor-qui-recull/diposit-editor-qui-recull.component';
import { PhotoBoxGruaComponent } from './sections/grua/components/photo-box-grua/photo-box-grua.component';
import { DipositGruaDesperfectosComponent } from './sections/grua/components/diposit-grua-desperfectos/diposit-grua-desperfectos.component';
import { DesperfectosEditorComponent } from './sections/grua/components/desperfectos-editor/desperfectos-editor.component';
import { FilesService } from 'path-shared/services/files/files.service';
import { ReleasedNotesComponent } from './sections/dash/components/released-notes/released-notes.component';
import { ReservesComponent } from './sections/tiquets/reserves/page/reserves.component';
import { ReservesFilterComponent } from './sections/tiquets/reserves/components/reserves-filter/reserves-filter.component';
import { ReservesCotxesTableComponent } from './sections/tiquets/reserves/components/reserves-cotxes-table/reserves-cotxes-table.component';
import { ReservesMotosTableComponent } from './sections/tiquets/reserves/components/reserves-motos-table/reserves-motos-table.component';
import { ReservesService } from './sections/tiquets/reserves/services/reserves.service';
import { ReservesPersonesTableComponent } from './sections/tiquets/reserves/components/reserves-persones-table/reserves-persones-table.component';
import { NuevaPropuestaGruaComponent } from './sections/grua/components/nueva-propuesta-grua/nueva-propuesta-grua.component';
import { EstadistiquesReservesAparcareComponent } from './sections/estadistiques/pages/estadistiques-reserves-aparcare/estadistiques-reserves-aparcare.component';
import { CalendarMultiselectInputModalComponent } from './shared/components/form/calendar-multiselect-input/calendar-multiselect-input-modal/calendar-multiselect-input-modal.component';
import { LivemapPageComponent } from './sections/livemap/livemap-page/livemap-page.component';
import { MapComponent } from './sections/livemap/components/map/map.component';
import { LeyendaMapComponent } from './sections/livemap/components/leyenda-map/leyenda-map.component';
import { MapService } from './sections/livemap/services/map.service';
import { TurnosComponent } from './sections/configuracio/grua/turnos/turnos.component';
import { TurnosService } from './sections/configuracio/grua/turnos/services/turnos-service';
import { TurnosFilterService } from './sections/configuracio/grua/turnos/services/turnos-filter-service';
import { TurnosTableComponentComponent } from './sections/configuracio/grua/turnos/components/turnos-table-component/turnos-table-component.component';
import { TurnosFilterComponent } from './sections/configuracio/grua/turnos/components/turnos-filter/turnos-filter.component';
import { TurnosEditorComponent } from './sections/configuracio/grua/turnos/components/turnos-editor/turnos-editor.component';
import { IniciarTurnoComponent } from './sections/configuracio/grua/turnos/components/iniciar-turno/iniciar-turno.component';
import { FinalizarTurnoComponent } from './sections/configuracio/grua/turnos/components/finalizar-turno/finalizar-turno.component';
import { EventEmitterService } from './shared/services/eventEmitter/event-emitter-service';
import { TarifasGruaComponent } from './sections/configuracio/grua/tarifas-grua/tarifas-grua/tarifas-grua.component';
import { TarifasGruaFilterComponent } from './sections/configuracio/grua/tarifas-grua/components/tarifas-grua-filter/tarifas-grua-filter.component';
import { TarifasGruaTableComponent } from './sections/configuracio/grua/tarifas-grua/components/tarifas-grua-table/tarifas-grua-table.component';
import { TarifasGruaEditorComponent } from './sections/configuracio/grua/tarifas-grua/components/tarifas-grua-editor/tarifas-grua-editor.component';
import { SenalesVerticalesPageComponent } from './sections/configuracio/senales-verticales/senales-verticales-page-component/senales-verticales-page.component';
import { TiposSenalesVerticalesPageComponent } from './sections/configuracio/tipos-senales-verticales/tipos-senales-verticales-page/tipos-senales-verticales-page.component';
import { TipoSenalesVerticalesTableComponent } from './sections/configuracio/tipos-senales-verticales/components/tipo-senales-verticales-table/tipo-senales-verticales-table.component';
import { TipoSenalesVerticalesEditorComponent } from './sections/configuracio/tipos-senales-verticales/components/tipo-senales-verticales-editor/tipo-senales-verticales-editor.component';
import { TipoSenalesVerticalesFilterComponent } from './sections/configuracio/tipos-senales-verticales/components/tipo-senales-verticales-filter/tipo-senales-verticales-filter.component';
import { SenalesVerticalesFilterComponent } from './sections/configuracio/senales-verticales/components/senales-verticales-filter/senales-verticales-filter.component';
import { SenalesVerticalesTableComponent } from './sections/configuracio/senales-verticales/components/senales-verticales-table/senales-verticales-table.component';
import { SenalesVerticalesEditorComponent } from './sections/configuracio/senales-verticales/components/senales-verticales-editor/senales-verticales-editor.component';
import { SenalesVerticalesService } from './sections/configuracio/senales-verticales/services/senales-verticales.service';
import { GenericFileDisplayerPageComponent } from './shared/components/generic-file-displayer/components/generic-file-displayer-page/generic-file-displayer-page.component';
import { GenericFileDisplayerTableComponent } from './shared/components/generic-file-displayer/components/generic-file-displayer-table/generic-file-displayer-table.component';
import { GenericFileDisplayerFrameComponent } from './shared/components/generic-file-displayer/components/generic-file-displayer-frame/generic-file-displayer-frame.component';
import { GenericFileViewerDialogComponent } from './shared/components/generic-file-displayer/components/generic-file-viewer-dialog/generic-file-viewer-dialog.component';
import { GenericFileService } from 'path-shared/components/generic-file-displayer/services/generic-file.service';
import { AuditoriaComponent } from 'path-shared/components/auditoria/auditoria.component';
import { AuditoriaService } from 'path-shared/services/auditoria/auditoria.service';
import { BancCarreguesC60Component } from './sections/bancs/carregues-c60/pages/banc-carregues-c60.component';
import { BancIncidenciesComponent } from './sections/bancs/incidencies/pages/banc-incidencies.component';
import { BancCarreguesC60TableComponent } from './sections/bancs/carregues-c60/components/banc-carregues-c60-table/banc-carregues-c60-table.component';
import { BancCarreguesC60FilterComponent } from './sections/bancs/carregues-c60/components/banc-carregues-c60-filter/banc-carregues-c60-filter.component';
import { BancCarreguesC60Service } from './sections/bancs/carregues-c60/services/banc-carregues-c60.service';
import { BancCarreguesC60EditorComponent } from './sections/bancs/carregues-c60/components/banc-carregues-c60-editor/banc-carregues-c60-editor.component';
import { BancCarreguesC60DetallTableComponent } from './sections/bancs/carregues-c60/components/banc-carregues-c60-table-detall/banc-carregues-c60-detall-table.component';
import { C60FechaCobroComponent } from './sections/bancs/carregues-c60/components/c60-fecha-cobro/c60-fecha-cobro.component';
import { agmConfigFactory } from './agm-config.factory';
import { TramitarExpedientspageComponent } from './sections/tramitar/expedients/pages/tramitar-expedientspage.component';
import { ExecutivaComponent } from './sections/tramitar/expedients/components/executiva/executiva.component';
import { TancamentsComponent } from './sections/tramitar/expedients/components/tancaments/tancaments.component';
import { PendentsExpedientsComponent } from './sections/tramitar/expedients/components/pendents/pendents.component';
import { TablependentsComponent } from './sections/tramitar/expedients/components/pendents/tablependents/tablependents.component';
import { TabletancamentsComponent } from './sections/tramitar/expedients/components/tancaments/tabletancaments/tabletancaments/tabletancaments.component';
import { PredefinidesPageComponent } from './sections/tramitar/predefinides/page/predefinides-page/predefinides-page.component';
import { PedefinidesTableComponent } from './sections/tramitar/predefinides/components/pedefinides-table/pedefinides-table.component';
import { TramitarPredefinidasService } from './sections/tramitar/predefinides/services/tramitar-predefinidas.service';
import { FormLargeComboEditComponent } from 'path-shared/components/form/form-large-combo-edit/form-large-combo-edit.component';
import { QrViewerComponent } from './shared/components/helpers/qr-viewer/qr-viewer.component';
import { TextoInformativoTableComponent } from './sections/aparcare/text-informatiu/components/texto-informativo-table/texto-informativo-table.component';
import { TextoInformativoEditorComponent } from './sections/aparcare/text-informatiu/components/texto-informativo-editor/texto-informativo-editor.component';
import { TextoInformativoPageComponent } from './sections/aparcare/text-informatiu/pages/texto-informativo-page/texto-informativo-page.component';
import { TextoInformativoFilterComponent } from './sections/aparcare/text-informatiu/components/texto-informativo-filter/texto-informativo-filter.component';
import { TextoInformativoService } from './sections/aparcare/text-informatiu/services/texto-informativo.service';
import { HtmlEditorComponent } from './shared/components/html-editor/components/html-editor/html-editor.component';
import { TextoAvisoPageComponent } from './sections/aparcare/text-avis/pages/texto-aviso-page/texto-aviso-page.component';
import { TextoAvisoTableComponent } from './sections/aparcare/text-avis/components/texto-aviso-table/texto-aviso-table.component';
import { TextoAvisoEditorComponent } from './sections/aparcare/text-avis/components/texto-aviso-editor/texto-aviso-editor.component';
import { TextoAvisoFilterComponent } from './sections/aparcare/text-avis/components/texto-aviso-filter/texto-aviso-filter.component';
import { TextoAvisoService } from './sections/aparcare/text-avis/services/texto-aviso.service';
import { ImportacioDenunciesHistoricComponent } from './sections/denuncies/components/importacio-denuncies-historic/importacio-denuncies-historic.component';
import { TableRevisioExecutivaOlotComponent } from './sections/tramitar/expedients/components/executiva/table-revisio-executiva-olot/table-revisio-executiva-olot.component';
import { TableTramitarExecutivaComponent } from './sections/tramitar/expedients/components/executiva/table-tramitar-executiva/table-tramitar-executiva.component';
import { TramitarDatesComponent } from './sections/tramitar/expedients/components/executiva/tramitar-dates/tramitar-dates.component';
import { TableTramitarExecutivaOlotComponent } from './sections/tramitar/expedients/components/executiva/table-tramitar-executiva-olot/table-tramitar-executiva-olot.component';
import { SelectorFechasNotificarComponent } from './sections/tramitar/expedients/components/notificar/components/selector-fechas-notificar/selector-fechas-notificar.component';
import { NotificarComponent } from './sections/tramitar/expedients/components/notificar/notificar-page-component/notificar.component';
import { ProgressBarComponent } from 'path-shared/components/progress-bar/progress-bar.component';
import { InputProgressBarComponent } from './shared/components/input-progress-bar/input-progress-bar.component';
import { ResultadCargaComponentComponent } from './sections/bancs/carregues-c60/components/resultad-carga-component/resultad-carga-component.component';
import { IncidenciesBancsTableComponent } from './sections/bancs/incidencies/components/incidencies-bancs-table/incidencies-bancs-table.component';
import { BancCarreguesIncidenciesService } from './sections/bancs/incidencies/services/banc-carregues-incidencies.service';
import { AutomatitzacioComponent } from './sections/configuracio/automatitzacio/pages/automatitzacio.component';
import { AutomatitzacioService } from './sections/configuracio/automatitzacio/services/automatitzacio-service.service';
import { MotiusNoNotificacioComponent } from './sections/configuracio/motius-notificacio/pages/motius-notificacio/motius-notificacio.component';
import { MotiusNoNotificacioTableComponent } from './sections/configuracio/motius-notificacio/components/motius-notificacio-table/motius-notificacio-table.component';
import { MotiusNoNotificacioEditorComponent } from './sections/configuracio/motius-notificacio/components/motius-notificacio-editor/motius-notificacio-editor.component';
import { MotiusNoNotificacioFilterComponent } from './sections/configuracio/motius-notificacio/components/motius-notificacio-filter/motius-notificacio-filter.component';
import { MotiusNoNotificacioService } from './sections/configuracio/motius-notificacio/services/motius-notificacio.service';
import { MotiusNoNotificacioFilterService } from './sections/configuracio/motius-notificacio/services/motius-notificacio-filter.service';
import { CargaMatriculasComponent } from './sections/denuncies/components/cargar-matriculas/components/carga-matriculas/carga-matriculas.component';
import { CargaMatriculasService } from './sections/denuncies/components/cargar-matriculas/services/carga-matriculas.service';
import { EmptyRowComponent } from './shared/components/empty-row/empty-row.component';
import { NotificarFilterService } from './sections/tramitar/expedients/services/notificar/notificar-filter.service';
import { ReportService } from 'path-shared/services/report/report.service';
import { ListadoNotificacionesComponent } from './sections/tramitar/expedients/components/notificar/components/listado-notificaciones/listado-notificaciones.component';
import { ListadoErroresNotificacionesComponent } from './sections/tramitar/expedients/components/notificar/components/listado-errores-notificaciones/listado-errores-notificaciones.component';
import { TipusEnviamentsComponent } from './sections/tramitar/tipus-enviaments/pages/tipus-enviaments.component';
import { TipusEnviamentsTableComponent } from './sections/tramitar/tipus-enviaments/components/tipus-enviaments-table/tipus-enviaments-table.component';
import { TipusEnviamentsEditorComponent } from './sections/tramitar/tipus-enviaments/components/tipus-enviaments-editor/tipus-enviaments-editor.component';
import { TipusEnviamentsFilterComponent } from './sections/tramitar/tipus-enviaments/components/tipus-enviaments-filter/tipus-enviaments-filter.component';
import { TipusEnviamentsService } from './sections/tramitar/tipus-enviaments/services/tipus-enviaments.service';
import { TipusEnviamentsFilterService } from './sections/tramitar/tipus-enviaments/services/tipus-enviaments-filter.service';
import { RespostesTableComponent } from './sections/tramitar/respostes/components/respostes-table/respostes-table.component';
import { RespostesEditorComponent } from './sections/tramitar/respostes/components/respostes-editor/respostes-editor.component';
import { RespostesFilterComponent } from './sections/tramitar/respostes/components/respostes-filter/respostes-filter.component';
import { RespostesService } from './sections/tramitar/respostes/services/respostes.service';
import { RespostesFilterService } from './sections/tramitar/respostes/services/respostes-filter.service';
import { RespostesPageComponent } from './sections/tramitar/respostes/pages/respostes.component';
import { CitesResidentsComponent } from './sections/ciutat/gestio-residents/components/cites-residents/cites-residents.component';
import { GestioResidentsService } from './sections/ciutat/gestio-residents/services/gestio-residents.service';
import { FileUploaderComponent } from './sections/anulacion-zona-azul-manual/components/file-uploader/file-uploader.component';
import { BonosResidentesTableComponent } from './sections/ciutat/gestio-residents/components/bonos-residentes-table/bonos-residentes-table.component';
import { BonosResidentsFilterComponent } from './sections/ciutat/gestio-residents/components/bonos-residents-filter/bonos-residents-filter.component';
import { GestioResidentsComponent } from './sections/ciutat/gestio-residents/components/gestio-residents/gestio-residents.component';
import { ComprovarPadroComponent } from './sections/ciutat/gestio-residents/components/comprovar-padro/comprovar-padro.component';
import { ObservacionsPdaService } from './sections/configuracio/observacions-pda/services/observacions-pda.service.ts.service';
import { ObservacionsPdaFilterService } from './sections/configuracio/observacions-pda/services/observacions-pda-filter.service.ts.service';
import { ObservacionsPdaComponent } from './sections/configuracio/observacions-pda/pages/observacions-pda/observacions-pda.component';
import { ObservacionsPdaTableComponent } from './sections/configuracio/observacions-pda/components/observacions-pda-table/observacions-pda-table.component';
import { ObservacionsPdaFilterComponent } from './sections/configuracio/observacions-pda/components/observacions-pda-filter/observacions-pda-filter.component';
import { ObservacionsPdaEditorComponent } from './sections/configuracio/observacions-pda/components/observacions-pda-editor/observacions-pda-editor.component';
import { AutocompleteComboComponent } from './shared/components/form/autocomplete-combo/autocomplete-combo.component';
import { InforInfractorComponent } from './sections/denuncies/components/denuncies-editor/components/nova-operacio/components/infor-infractor/infor-infractor.component';
import { MatriculesAddComponent } from './sections/ciutat/matricules/components/matricules-add/matricules-add.component';
import { AjudaPageComponent } from './sections/ajuda/pages/ajuda-page.component';
import { AjudaService } from './sections/ajuda/services/ajuda.service';
import { ZonesAparcareService } from './sections/tiquets/zones-aparcare/services/zones-aparcare.service';
import { ZonesAparcareFilterService } from './sections/tiquets/zones-aparcare/services/zones-aparcare-filter.service';
import { ZonesAparcareEditorComponent } from './sections/tiquets/zones-aparcare/components/zones-aparcare-editor/zones-aparcare-editor.component';
import { ZonesAparcareFilterComponent } from './sections/tiquets/zones-aparcare/components/zones-aparcare-filter/zones-aparcare-filter.component';
import { ZonesAparcareComponent } from './sections/tiquets/zones-aparcare/zones-aparcare.component';
import { ZonesAparcareTableComponent } from './sections/tiquets/zones-aparcare/components/zones-aparcare-table/zones-aparcare-table.component';
import { ToolDropdownButtonComponent } from 'path-shared/components/toolbar/tool-dropdown-button/tool-dropdown-button.component';
import { CustomMatPaginatorIntl } from 'path-shared/services/paginator/CustomMatPaginatorIntl';
import { ValoraAnulacionComponent } from './sections/anulacion-zona-azul-auto/components/valora-anulacion/valora-anulacion.component';
import { EstadistiquesReincidentsComponent } from './sections/estadistiques/pages/estadistiques-reincidents/estadistiques-reincidents.component';
import { AutoLoginComponent } from './sections/login/pages/auto-login/auto-login.component';
import { EstadisitcasAparcareComponent } from './sections/estadistiques/pages/estadisitcas-aparcare/estadisitcas-aparcare.component';
import { EstadisticasDenunciasComponent } from './sections/estadistiques/pages/estadisticas-denuncias/estadisticas-denuncias.component';
import { InforTitularComponent } from './sections/denuncies/components/denuncies-editor/components/nova-operacio/components/infor-titular/infor-titular.component';
import { CambioMatriculaComponent } from './sections/denuncies/components/denuncies-editor/components/nova-operacio/components/cambio-matricula/cambio-matricula.component';
import { AudioService } from 'path-shared/services/audio/audio.service';
import { ListadoResolucionesComponent } from './sections/tramitar/expedients/components/notificar/components/listado-resoluciones/listado-resoluciones.component';
import { TwoFactorAuthenticationComponent } from './sections/usuaris/components/2FA/2fa.component';
import { CobrosComponent } from './sections/bancs/cobros/cobros.component';
import { CobrosService } from './sections/bancs/cobros/services/cobros.service';
import { CartaPagoFechasComponent } from './sections/denuncies/components/denuncies-editor/components/nova-operacio/components/carta-pago-fechas/carta-pago-fechas.component';
import { EnviosComponent } from './sections/tramitar/notificaciones/pages/gestion/envios/envios.component';
import { RespuestasComponent } from './sections/tramitar/notificaciones/pages/gestion/respuestas/respuestas.component';
import { EnviosTableComponent } from './sections/tramitar/notificaciones/components/envios-table/envios-table.component';
import { EnvioDetailComponent } from './sections/tramitar/notificaciones/components/envio-detail/envio-detail.component';
import { EnviosFilterComponent } from './sections/tramitar/notificaciones/components/envios-filter/envios-filter.component';
import { EnviosService } from './sections/tramitar/notificaciones/services/envios-service';
import { EnviosFilterService } from './sections/tramitar/notificaciones/services/envios-filter-service';
import { RespuestasService } from './sections/tramitar/notificaciones/services/respuestas-service';
import { EnvioPuntosDgtComponent } from './sections/tramitar/dgt/pages/envio-puntos-dgt/envio-puntos-dgt.component';
import { EnvioPuntosDGTService } from './sections/tramitar/dgt/services/envio-puntos-dgt.service';
import { EnvioPuntosDgtTableComponent } from './sections/tramitar/dgt/components/envio-puntos-dgt-table/envio-puntos-dgt-table.component';
import { EnvioPuntosDgtFilterComponent } from './sections/tramitar/dgt/components/envio-puntos-dgt-filter/envio-puntos-dgt-filter.component';
import { BonosComponent } from './sections/tiquets/bonos/bonos.component';
import { BonosTableComponent } from './sections/tiquets/bonos/components/bonos-table.component';
import { BonosService } from './sections/tiquets/bonos/services/bonos.service';
import { BonosFilterService } from './sections/tiquets/bonos/services/bonos-filter.service';
import { ConfiguracionComponent } from './sections/tramitar/teu/pages/configuracion/configuracion.component';
import { BOEGeneralComponent } from './sections/tramitar/teu/components/configuracion/boe-general/boe-general.component';
import { BoeService } from './sections/tramitar/teu/services/boe.service';
import { EnvioBopComponent } from './sections/tramitar/teu/pages/envio-bop/envio-bop.component';
import { EnvioBoePageComponent } from './sections/tramitar/teu/components/envio/envio-boe-page/envio-boe-page.component';
import { EnvioBoeTableComponent } from './sections/tramitar/teu/components/envio/envio-boe-table/envio-boe-table.component';
import { ConfirmacionComponent } from './sections/tramitar/teu/pages/confirmacion/confirmacion.component';
import { ConfirmacionListadoComponent } from './sections/tramitar/teu/components/confirmacion/confirmacion-listado/confirmacion-listado.component';
import { ConfirmacionBoeFilterComponent } from './sections/tramitar/teu/components/confirmacion/confirmacion-boe-filter/confirmacion-boe-filter.component';
import { ConfirmacionDetalleBoeFilterService } from './sections/tramitar/teu/services/confirmacion-detalle-boe-filter.service';
import { RowsDetalleConfirmacionComponent } from './sections/tramitar/teu/components/confirmacion/confirmacion-boe-detalle/rows-detalle-confirmacion/rows-detalle-confirmacion.component';
import { DesplacatsGruaComponent } from './sections/grua/finalitzacio/desplacats/desplacats-page/desplacats.component';
import { SenseRetiradaGruaComponent } from './sections/grua/finalitzacio/sense-retirada/sense-retirada-page/sense-retirada.component';
import { DesplacatsGruaService } from './sections/grua/services/desplacats-grua-service';
import { DesplacatsGruaFilterService } from './sections/grua/services/desplacats-grua-filter-service';
import { SenseRetiradaGruaService } from './sections/grua/services/sense-retirada-grua-service';
import { SenseRetiradaGruaFilterService } from './sections/grua/services/sense-retirada-grua-filter-service';
import { DesplacatsFilterComponent } from './sections/grua/finalitzacio/desplacats/desplacats-filter/desplacats-filter.component';
import { SenseRetiradaGruaFilterComponent } from './sections/grua/finalitzacio/sense-retirada/sense-retirada-filter/sense-retirada-filter.component';
import { DesplacatsTableComponent } from './sections/grua/finalitzacio/desplacats/desplacats-table/desplacats-table.component';
import { SenseRetiradaGruaTableComponent } from './sections/grua/finalitzacio/sense-retirada/sense-retirada-table/sense-retirada-table.component';
import { CombosService } from 'path-shared/services/form/combos.service';
import { DashTramitacionComponent } from './sections/dash/components/dash-tramitacion/dash-tramitacion.component';
import { CompleteDashTramitacionComponent } from './sections/dash/components/complete-dash-tramitacion/complete-dash-tramitacion.component';
import { DipositGruaDesperfectosV2Component } from './sections/grua/components/diposit-grua-desperfectos-v2/diposit-grua-desperfectos-v2.component';
import { DesplaçatsEditorComponent } from './sections/grua/components/desplaçats-editor/desplaçats-editor.component';
import { PropostesAnulacioComponent } from './sections/zona-blava/propostes-anulacions/pages/propostes-anulacio/propostes-anulacio.component';
import { PropostesAnulacioTableComponent } from './sections/zona-blava/propostes-anulacions/components/propostes-anulacio-table/propostes-anulacio-table.component';
import { PropostesAnulacioEditorComponent } from './sections/zona-blava/propostes-anulacions/components/propostes-anulacio-editor/propostes-anulacio-editor.component';
import { PropostesAnulacioService } from './sections/zona-blava/propostes-anulacions/services/propostes-anulacio.service';
import { PropostesAnulacioFilterComponent } from './sections/zona-blava/propostes-anulacions/components/propostes-anulacio-filter/propostes-anulacio-filter.component';
import { PendentsListComponent } from './sections/grua/components/pendents-list/pendents-list.component';
import { PendentsListPageComponent } from './sections/grua/components/pendents-list-page/pendents-list-page.component';
import { DipositStockComponent } from './sections/grua/components/diposit-stock/diposit-stock.component';
import { DipositStockTableComponent } from './sections/grua/components/diposit-stock-table/diposit-stock-table.component';
import { ModalMultiSelectComponent } from './shared/components/modal-multi-select/modal-multi-select.component';
import { DeltacarHistoricoComponent } from './sections/zona-blava/deltacar-historico/pages/deltacar-historico.component';
import { DeltacarHistoricoTableComponent } from './sections/zona-blava/deltacar-historico/components/deltacar-historico-table/deltacar-historico-table.component';
import { DeltacarHistoricoFilterComponent } from './sections/zona-blava/deltacar-historico/components/deltacar-historico-filter/deltacar-historico-filter.component';
import { FormAlegacionesComponent } from './sections/denuncies/components/denuncies-editor/components/nova-operacio/components/form-alegaciones/form-alegaciones.component';
import { EstadisticasVariosComponent } from './sections/estadistiques/pages/estadisticas-varios/estadisticas-varios.component';
import { AlarmConfigurationComponent } from './sections/alarmes/components/alarm-configuration/alarm-configuration.component';
import { DenunciaCobrarByC60Component } from './sections/denuncies/components/denuncies-editor/components/denuncia-cobrar-by-c60/denuncia-cobrar-by-c60.component';
import { EstadisitcasAgenteComponent } from './sections/estadistiques/pages/estadisitcas-agente/estadisitcas-agente.component';
import { TirarUsuarisLdapComponent } from './sections/seguretat/seguretat-usuaris/components/tirar-usuaris-ldap/tirar-usuaris-ldap.component';
import { TramitadorConfigurationComponent } from './sections/denuncies/components/tramitador-configuration/tramitador-configuration.component';
import { FiltersConfigurationService } from 'path-shared/services/FiltersConfiguration/filters-configuration.service';
import { TriarC60ComponentComponent } from './sections/denuncies/components/denuncies-editor/components/triar-c60-component/triar-c60-component.component';
import { DenunciaCobrarSmartPolComponent } from './sections/denuncies/components/denuncies-editor/components/denuncia-cobrar-smart-pol/denuncia-cobrar-smart-pol.component';
import { TextosPredefinidosComponent } from './sections/configuracio/textos-predefinidos/pages/textos-predefinidos/textos-predefinidos.component';
import { TextosPredefinidosTableComponent } from './sections/configuracio/textos-predefinidos/components/textos-predefinidos-table/textos-predefinidos-table.component';
import { TextosPredefinidosFilterComponent } from './sections/configuracio/textos-predefinidos/components/textos-predefinidos-filter/textos-predefinidos-filter.component';
import { TextosPredefindiosService } from './sections/configuracio/textos-predefinidos/services/textos-prefedefinidos.service';
import { TextosPredefinidosFilterService } from './sections/configuracio/textos-predefinidos/services/textos-prefedefinidos-filter.service';
import { TextosPredefinidosEditorComponent } from './sections/configuracio/textos-predefinidos/components/textos-predefinidos-editor/textos-predefinidos-editor.component';
import { PropostesViewerAutologinComponent } from './sections/propostes/components/propostes-list/components/propostes-viewer-autologin/propostes-viewer-autologin.component';
import { ImageDisplayerComponent } from './image-displayer/image-displayer.component';
import { AcusosComponent } from './sections/tramitar/acusos/acusos.component';
import { AcusosTableComponent } from './sections/tramitar/acusos/acusos-table/acusos-table.component';
import { AcusosFilterComponent } from './sections/tramitar/acusos/acusos-filter/acusos-filter.component';
import { ReportViewerComponent } from './sections/tramitar/report-viewer/report-viewer.component';
import { ReportDisplayerComponent } from './report-displayer/report-displayer.component';
import { IntroAlegacionesComponent } from './sections/denuncies/components/denuncies-editor/components/nova-operacio/components/intro-alegaciones/intro-alegaciones.component';
import { DatePipe, registerLocaleData } from '@angular/common';
import { APP_INITIALIZER, LOCALE_ID, MissingTranslationStrategy, NO_ERRORS_SCHEMA, NgModule, TRANSLATIONS, TRANSLATIONS_FORMAT } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatDatepickerIntl, MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AgmCoreModule, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { I18n, MISSING_TRANSLATION_STRATEGY } from '@ngx-translate/i18n-polyfill';
import { RouteReuseStrategy } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { MatAutocompleteModule, MatBadgeModule, MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatExpansionModule, MatGridListModule, MatInputModule, MatListModule, MatNativeDateModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatSelectModule, MatSidenavModule, MatSliderModule, MatSortModule, MatStepperModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule, MatTreeModule } from '@angular/material';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { QuillModule } from 'ngx-quill';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule } from 'ng2-charts';
import { LayoutModule } from '@angular/cdk/layout';
import { ColorPickerModule } from 'ngx-color-picker';
import { FormDateEditComponent } from 'path-shared/components/form/form-date-edit/form-date-edit.component';
import { AlegacionsComponent } from './sections/tramitar/alegacions/alegacions.component';
import { RespondreAlegacionsComponent } from './sections/tramitar/alegacions/respondre-alegacions/respondre-alegacions.component';
import { AlegacionsFilterComponent } from './sections/tramitar/alegacions/alegacions-filter/alegacions-filter.component';
import { DateClickedDirective, MultipleDatePickerComponent } from 'multiple-date-picker-angular/dist';
import { SegundasAlegacionesComponent } from './sections/denuncies/components/denuncies-editor/components/nova-operacio/components/segundas-alegaciones/segundas-alegaciones.component';
import { InformarAcusosComponent } from './sections/tramitar/acusos/informar-acusos/informar-acusos.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CargaMatriculasFilterComponent } from './sections/denuncies/components/cargar-matriculas/components/carga-matriculas-filter/carga-matriculas-filter.component';
import { EnvioPuntsDGTFilterService } from './sections/tramitar/dgt/services/envio-punts-dgt-filter-service';
import { EnvioPuntosDgtHistoricoComponent } from './sections/tramitar/dgt/components/envio-puntos-dgt-historico/envio-puntos-dgt-historico.component';
import { ListadoNotificacionesDenunciaComponent } from './sections/tramitar/expedients/components/notificar/components/listado-notificaciones-denuncia/listado-notificaciones-denuncia.component';
import { NotificacionsDenunciaFilter } from './sections/notificacions/models/NotificacionDenuncia/notificacions-denuncia-filter';
import { NotificacionsDenunciesFilterService } from './sections/notificacions/services/notificacions-denuncies-filter.services';
import { NotificacionesDenunciaFilterComponent } from './sections/tramitar/expedients/components/notificar/components/listado-notificaciones-denuncia/notificaciones-denuncia-filter/notificaciones-denuncia-filter.component';
import { AlarmesHistoricComponent } from './sections/alarmes/components/alarma-historic/pages/alarmes-historic/alarmes-historic.component';
import { GestionarComponent } from './sections/configuracio/agents/pages/gestionar/gestionar.component';
import { ZbRutasComponent } from './sections/configuracio/agents/pages/gestionar/zb-rutas/zb-rutas.component';
import { ZbHorariosComponent } from './sections/configuracio/agents/pages/gestionar/zb-horarios/zb-horarios.component';
import { ZbHorariosTableComponent } from './sections/configuracio/agents/pages/gestionar/zb-horarios/zb-horarios-table/zb-horarios-table.component';
import { ZbHorarioEditorComponent } from './sections/configuracio/agents/pages/gestionar/zb-horarios/zb-horario-editor/zb-horario-editor.component';
import { ZbRutasTableComponent } from './sections/configuracio/agents/pages/gestionar/zb-rutas/zb-rutas-table/zb-rutas-table.component';
import { ZbRutaEditorComponent } from './sections/configuracio/agents/pages/gestionar/zb-rutas/zb-ruta-editor/zb-ruta-editor.component';
import { PlanificacioComponent } from './sections/configuracio/agents/pages/gestionar/planificacio/planificacio.component';
import { PlanificacioTableComponent } from './sections/configuracio/agents/pages/gestionar/planificacio/planificacio-table/planificacio-table.component';
import { PlanificacioEditorComponent } from './sections/configuracio/agents/pages/gestionar/planificacio/planificacio-editor/planificacio-editor.component';
import { TramosTiposComponent } from './sections/zona-blava/estacionament/tramos/tramos-tipos/tramos-tipos/tramos-tipos.component';
import { TramosTiposTableComponent } from './sections/zona-blava/estacionament/tramos/tramos-tipos/components/tramos-tipos-table/tramos-tipos-table.component';
import { TramosTiposFilterComponent } from './sections/zona-blava/estacionament/tramos/tramos-tipos/components/tramos-tipos-filter/tramos-tipos-filter.component';
import { EscalaOcupacionEditorComponent } from './sections/zona-blava/estacionament/escala-ocupacio/components/escala-ocupacion-editor/escala-ocupacion-editor.component';
import { EscalaOcupacionTableComponent } from './sections/zona-blava/estacionament/escala-ocupacio/components/escala-ocupacion-table/escala-ocupacion-table.component';
import { EscalaOcupacionPageComponent } from './sections/zona-blava/estacionament/escala-ocupacio/pages/escala-ocupacion-page/escala-ocupacion-page.component';
import { EscalaOcupacionService } from './sections/zona-blava/estacionament/escala-ocupacio/services/escala-ocupacion.service';
import { MaquinasEditorComponent } from './sections/zona-blava/maquinas/components/maquinas-editor/maquinas-editor.component';
import { MaquinasFilterComponent } from './sections/zona-blava/maquinas/components/maquinas-filter/maquinas-filter.component';
import { MaquinasTableComponent } from './sections/zona-blava/maquinas/components/maquinas-table/maquinas-table.component';
import { MaquinasComponent } from './sections/zona-blava/maquinas/maquinas/maquinas.component';
import { MaquinasFilterService } from './sections/zona-blava/maquinas/services/maquinas-filter.service';
import { MaquinasService } from './sections/zona-blava/maquinas/services/maquinas.service';
import { ClonarTemporadaComponent } from './sections/zona-blava/estacionament/temporada/components/clonar-temporada/clonar-temporada.component';
import { TemporadasEditorComponent } from './sections/zona-blava/estacionament/temporada/components/temporadas-editor/temporadas-editor.component';
import { TemporadasFilterComponent } from './sections/zona-blava/estacionament/temporada/components/temporadas-filter/temporadas-filter.component';
import { TemporadasTableComponent } from './sections/zona-blava/estacionament/temporada/components/temporadas-table/temporadas-table.component';
import { TemporadaPageComponent } from './sections/zona-blava/estacionament/temporada/pages/temporada-page/temporada-page.component';
import { TemporadaFilterService } from './sections/zona-blava/estacionament/temporada/services/temporada-filter.service';
import { TramosTiposEditorComponent } from './sections/zona-blava/estacionament/tramos/tramos-tipos/components/tramos-tipos-editor/tramos-tipos-editor.component';
import { TramosTiposFilterService } from './sections/zona-blava/estacionament/tramos/tramos-tipos/services/tramos-tipos-filter.service';
import { TramosTiposService } from './sections/zona-blava/estacionament/tramos/tramos-tipos/services/tramos-tipos.service';
import { TramosEditorComponent } from './sections/zona-blava/estacionament/tramos/tramos/components/tramos-editor/tramos-editor.component';
import { TramosFilterComponent } from './sections/zona-blava/estacionament/tramos/tramos/components/tramos-filter/tramos-filter.component';
import { TramosTableComponent } from './sections/zona-blava/estacionament/tramos/tramos/components/tramos-table/tramos-table.component';
import { TramosFilterService } from './sections/zona-blava/estacionament/tramos/tramos/services/tramos-filter.service';
import { TramosService } from './sections/zona-blava/estacionament/tramos/tramos/services/tramos.service';
import { TramosComponent } from './sections/zona-blava/estacionament/tramos/tramos/tramos/tramos.component';
import { TemporadaService } from './sections/zona-blava/estacionament/temporada/services/temporada.service';
import { IncidenciesComponent } from './sections/zona-blava/maquinas/components/incidencies/incidencies.component';
import { IncidenciesFilterComponent } from './sections/zona-blava/maquinas/components/incidencies-filter/incidencies-filter.component';
import { CobrosFiltreComponent } from './sections/bancs/cobros/components/cobros-filtre/cobros-filtre.component';
import { ActuacionsTableComponent } from './sections/zona-blava/maquinas/components/actuacions-table/actuacions-table.component';
import { ActuacionsNewComponent } from './sections/zona-blava/maquinas/components/actuacions-new/actuacions-new.component';
import { IncidenciesImagesComponent } from './sections/zona-blava/maquinas/components/incidencies-images/incidencies-images.component';
import { ActuacionsAgentsComponent } from './sections/agents/actuacions-agents/pages/actuacions-agents.component';
import { ListadoResolucionesFilterComponent } from './sections/tramitar/expedients/components/notificar/components/listado-resoluciones/listado-resoluciones-filter/listado-resoluciones-filter.component';

//#endregion imports

registerLocaleData(localeCa, 'ca', 'assets/i18n');
registerLocaleData(localeEs, 'es', 'assets/i18n');

let lang = 'ca';

declare var require: any;

@NgModule({
  declarations: [
    MultipleDatePickerComponent,
    DateClickedDirective,
    EstadisticasVariosComponent,
    AlarmConfigurationComponent,
    FormAlegacionesComponent,
    DeltacarHistoricoFilterComponent,
    DeltacarHistoricoTableComponent,
    DeltacarHistoricoComponent,
    DesplacatsGruaComponent,
    DesplacatsFilterComponent,
    DesplacatsTableComponent,
    SenseRetiradaGruaComponent,
    SenseRetiradaGruaFilterComponent,
    SenseRetiradaGruaTableComponent,
    CartaPagoFechasComponent,
    BonosComponent,
    BonosTableComponent,
    TwoFactorAuthenticationComponent,
    EstadistiquesReincidentsComponent,
    ObservacionsPdaComponent,
    ObservacionsPdaTableComponent,
    ObservacionsPdaFilterComponent,
    ObservacionsPdaEditorComponent,
    ComprovarPadroComponent,
    CitesResidentsComponent,
    AppComponent,
    NavbarComponent,
    DashComponent,
    DashboardPageComponent,
    PropostesPageComponent,
    NotificacionsPageComponent,
    AparcarePageComponent,
    UtilitatsPageComponent,
    CiutatPageComponent,
    UsuarisPageComponent,
    PermisosPageComponent,
    AparcareComponent,
    PropostesComponent,
    NotificacionsComponent,
    PermisosComponent,
    UsuarisComponent,
    UtilitatsComponent,
    LoginPageComponent,
    DadesExpedientComponent,
    DenunciesPageComponent,
    DenunciesEditorComponent,
    DenunciesFilterComponent,
    DenunciesTableComponent,
    TipoSenalesVerticalesEditorComponent,
    RetiradaComponent,
    OperacionsComponent,
    RefC60Component,
    ExpRelacionatsComponent,
    RecaptacioComponent,
    DenunciantComponent,
    DenunciesImagesComponent,
    DenunciesNewComponent,
    NovaOperacioComponent,
    DenunciaCobrarComponent,
    DenunciaPagamentParcialComponent,
    ConfirmationDialogComponent,
    PhotoBoxComponent,
    HeaderComponent,
    ExpedientsComponent,
    DialogDraggableDirective,
    DialogListComponent,
    CarrersComponent,
    PhotoBoxViewerComponent,
    PhotoBoxGalleryComponent,
    SingleImageViewerComponent,
    DropdownInstitucionsComponent,
    TestComponent,
    NotFoundComponent,
    PropostesDesestimarComponent,
    PropostesListComponent,
    PropostesFilterComponent,
    PropostesTableComponent,
    TramitadorComponent,
    PermissionDialogComponent,
    PermissionEditorComponent,
    DashSquareComponent,
    ToolButtonComponent,
    ToolDropdownButtonComponent,
    ToolbarComponent,
    DialogHeaderComponent,
    FilterHeaderComponent,
    FormDateEditComponent,
    FormTextEditComponent,
    FormTextCurrencyEditComponent,
    FormSingleToggleComponent,
    CalendarMultiselectInputComponent,
    CalendarMultiselectInputModalComponent,
    TimeRangeInputComponent,
    DateRangeInputComponent,
    FormComboEditComponent,
    FormLargeComboEditComponent,
    DenunciesEstatComponent,
    PermissionStatusComponent,
    MatriculesComponent,
    MatriculesTableComponent,
    TipusMatriculesComponent,
    TipusMatriculesTableComponent,
    PermissionStatusComponent,
    ShortDatePipe,
    MoneyPipe,
    NomPaisPipe,
    LongDatePipe,
    FormatDatePipe,
    SeriePipe,
    SizePipe,
    SingleEditDialogComponent,
    MatriculesEditorComponent,
    MatriculesFilterComponent,
    TipusMatriculesEditorComponent,
    TipusMatriculesFilterComponent,
    FormCheckEditComponent,
    FilterSelectorComponent,
    PageTitleComponent,
    FormTriStateComponent,
    FormTriStateVerticalComponent,
    AgentsComponent,
    AgentsTableComponent,
    AgentsFilterComponent,
    AgentsEditorComponent,
    NavItemComponent,
    NavDropdownItemComponent,
    DialogBodyComponent,
    DialogFooterComponent,
    PropostesTableEstatComponent,
    FormLiveStateComponent,
    DashInputFilesStatusComponent,
    WaitDialogComponent,
    TramitadorHistoricComponent,
    TableFieldLabelComponent,
    ViewListIconComponent,
    ToolButtonPermissionsComponent,
    LoaderComponent,
    MatriculesLoaderComponent,
    FormFileUploadComponent,
    FormFileMultipleUploadComponent,
    FormRadioGroupComponent,
    FilterTypeComponent,
    FilterTextEditComponent,
    PropostesEditorComponent,
    PropostesLlocEditorComponent,
    DownloadExcelDialogComponent,
    SelectionManagerComponent,
    MatriculaComponent,
    MatriculesHistoricComponent,
    AparellsTableComponent,
    AparellsComponent,
    AparellsFilterComponent,
    AparellsEditorComponent,
    LegislacionsTableComponent,
    LegislacionsComponent,
    LegislacionsFilterComponent,
    LegislacionsEditorComponent,
    MotiusAnulacioComponent,
    MotiusAnulacioEditorComponent,
    MotiusAnulacioFilterComponent,
    MotiusAnulacioTableComponent,
    MotiusDesestimarComponent,
    MotiusDesestimarEditorComponent,
    MotiusDesestimarFilterComponent,
    MotiusDesestimarTableComponent,
    PropostaViewerComponent,
    PropostaDialogComponent,
    CookiePolicyComponent,
    PublicHeaderComponent,
    MatriculesHistoricComponent,
    MatriculesHistoricFilterComponent,
    MatriculesHistoricTableComponent,
    FormTextAreaComponent,
    AlarmHeaderMenuComponent,
    AlarmesComponent,
    AlarmesTableComponent,
    AlarmesFilterComponent,
    DeteccioMatriculesComponent,
    DeteccioMatriculesTableComponent,
    GalleryComponent,
    ReportViewerComponent,
    SiglasComponent,
    SiglasTableComponent,
    SiglasFilterComponent,
    SiglasEditorComponent,
    TipusVehiclesTableComponent,
    TipusVehiclesFilterComponent,
    TipusVehiclesEditorComponent,
    TipusVehiclesComponent,
    ColorsComponent,
    ColorsEditorComponent,
    ColorsFilterComponent,
    ColorsTableComponent,
    CarrersTableComponent,
    CarrersFilterComponent,
    MarquesVehiclesTableComponent,
    MarquesVehiclesComponent,
    MarquesVehiclesFilterComponent,
    MarquesVehiclesEditorComponent,
    InfraccioComponent,
    InfraccionsEditorComponent,
    InfraccionsFilterComponent,
    InfraccionsTableComponent,
    FormTriStateVerticalComponent,
    FormCheckEditTriComponent,
    FileBoxComponent,
    FileBoxImageComponent,
    UploadToolButtonComponentComponent,
    FileViewerComponent,
    PaisosComponent,
    PaisosEditorComponent,
    PaisosTableComponent,
    PaisosFilterComponent,
    ZonesComponent,
    ZonesTableComponent,
    ZonesEditarComponent,
    TransformDataPipe,
    FormMultiSelectComponent,
    CalleEditorComponent,
    ZonesFilterComponent,
    AnulacionZonaAzulManualComponent,
    AnulacionZonaAzulAutoComponent,
    ImportacioDenunciesComponent,
    EstadistiquesSetmanalsComponent,
    EstadistiquesMensualsComponent,
    ComunicacioPdaComponent,
    FormTabChatDynamicComponent,
    VisorAlarmesComponent,
    AlarmaDetallComponent,
    TrackingComponent,
    SincronitzacioComponent,
    AnulacionZonaAzulAutoFilterComponent,
    AnulacionZonaAzulAutoHistoricComponent,
    AvisosConfComponent,
    AvisosTableComponent,
    AvisosAddNodeComponent,
    AvisosComponent,
    AvisosFilterComponent,
    ChangePasswordComponent,
    ReportsComponent,
    TiquetsPageComponent,
    TiquetsTiquetsTableComponent,
    TiquetsDevolucionesTableComponent,
    TiquetsAnulacionesTableComponent,
    TiquetsFilterComponent,
    ModelsVehiclesComponent,
    PersonesEditorComponent,
    PersonesTableComponent,
    PersonesComponent,
    PersonesFilterComponent,
    DomicilisEditorComponent,
    ComboBoxInputComponent,
    FestiusComponent,
    FestiusTableComponent,
    FestiusEditorComponent,
    FestiusFilterComponent,
    TipusTiquetsComponent,
    TipusTiquetsTableComponent,
    TipusTiquetsEditorComponent,
    TipusTiquetsFilterComponent,
    SeguretatUsuarisPageComponent,
    SeguretatUsuarisTableComponent,
    SeguretatGrupsPageComponent,
    SeguretatUsuarisEditorComponent,
    SeguretatUsuarisFilterComponent,
    DisplayTextDialogComponent,
    VehiclesTableComponent,
    VehiclesComponent,
    VehiclesFilterComponent,
    VehiclesEditorComponent,
    XmlTreeViewerComponent,
    DgtFilterComponent,
    SeguretatGrupsTableComponent,
    SeguretatGrupsEditorComponent,
    ComprovacioTiquetsComponent,
    RecaudacioPageComponent,
    RecaudacioTableComponent,
    RecaudacioEditorComponent,
    RecaudacioFilterComponent,
    ElegantComboBoxInputComponent,
    NotificacionsEditorComponent,
    NotificacionsFilterComponent,
    RespostesComponent,
    ChipsListInputComponent,
    ComboBoxInputAutocompleteComponent,
    PendentsComponent,
    TramitadesComponent,
    PendentsTableComponent,
    GruaTecnicsComponent,
    GruaTecnicsTableComponent,
    GruaTecnicsEditorComponent,
    GruaTecnicsFilterComponent,
    GruaMotiusDesestimarComponent,
    GruaMotiusDesestimarTableComponent,
    GruaMotiusDesestimarEditorComponent,
    GruaMotiusDesestimarFilterComponent,
    GruaMotiusEntradaComponent,
    GruaMotiusEntradaTableComponent,
    GruaMotiusEntradaEditorComponent,
    GruaMotiusEntradaFilterComponent,
    GruaAliesComponent,
    GruaAliesTableComponent,
    GruaAliesEditorComponent,
    GruaAliesFilterComponent,
    TramitadesTableComponent,
    TramitadesFilterComponent,
    DipositComponent,
    DipositTableComponent,
    DipositFilterComponent,
    DipositEditorComponent,
    DesplaçatsEditorComponent,
    DipositEditorGeneralComponent,
    PendentsViewerComponent,
    NovaOperacioPropostesComponent,
    TarifesComponent,
    TarifesTableComponent,
    TarifesEditorComponent,
    TarifesFilterComponent,
    GridImagesComponent,
    DashContadoresDuplicadosComponent,
    DipositEditorQuiRecullComponent,
    PhotoBoxGruaComponent,
    DipositGruaDesperfectosComponent,
    DipositGruaDesperfectosV2Component,
    DesperfectosEditorComponent,
    ReleasedNotesComponent,
    ReservesComponent,
    ReservesFilterComponent,
    ReservesCotxesTableComponent,
    ReservesMotosTableComponent,
    ReservesPersonesTableComponent,
    NuevaPropuestaGruaComponent,
    EstadistiquesReservesAparcareComponent,
    CalendarMultiselectInputModalComponent,
    LivemapPageComponent,
    MapComponent,
    LeyendaMapComponent,
    TurnosComponent,
    TurnosTableComponentComponent,
    TurnosFilterComponent,
    TurnosEditorComponent,
    IniciarTurnoComponent,
    FinalizarTurnoComponent,
    TarifasGruaComponent,
    TarifasGruaFilterComponent,
    TarifasGruaTableComponent,
    TarifasGruaEditorComponent,
    TramosTiposComponent,
    TramosTiposTableComponent,
    TramosTiposFilterComponent,
    TramosTiposEditorComponent,
    TramosComponent,
    TramosEditorComponent,
    TramosFilterComponent,
    TramosTableComponent,
    MaquinasComponent,
    MaquinasTableComponent,
    MaquinasEditorComponent,
    MaquinasFilterComponent,
    SenalesVerticalesPageComponent,
    TiposSenalesVerticalesPageComponent,
    TipoSenalesVerticalesTableComponent,
    TipoSenalesVerticalesFilterComponent,
    SenalesVerticalesFilterComponent,
    SenalesVerticalesTableComponent,
    SenalesVerticalesEditorComponent,
    GenericFileDisplayerPageComponent,
    GenericFileDisplayerTableComponent,
    GenericFileDisplayerFrameComponent,
    GenericFileViewerDialogComponent,
    AuditoriaComponent,
    TemporadaPageComponent,
    TemporadasEditorComponent,
    TemporadasFilterComponent,
    TemporadasTableComponent,
    ClonarTemporadaComponent,
    QrViewerComponent,
    TramitarExpedientspageComponent,
    NotificarComponent,
    ExecutivaComponent,
    TancamentsComponent,
    PendentsExpedientsComponent,
    TablependentsComponent,
    TabletancamentsComponent,
    PredefinidesPageComponent,
    PedefinidesTableComponent,
    BancCarreguesC60Component,
    BancCarreguesC60FilterComponent,
    BancCarreguesC60TableComponent,
    BancCarreguesC60EditorComponent,
    BancCarreguesC60DetallTableComponent,
    BancIncidenciesComponent,
    C60FechaCobroComponent,
    DenunciaPagamentParcialComponent,
    TextoInformativoPageComponent,
    TextoInformativoTableComponent,
    TextoInformativoEditorComponent,
    TextoInformativoFilterComponent,
    HtmlEditorComponent,
    TextoAvisoPageComponent,
    TextoAvisoTableComponent,
    TextoAvisoEditorComponent,
    TextoAvisoFilterComponent,
    EscalaOcupacionPageComponent,
    EscalaOcupacionTableComponent,
    EscalaOcupacionEditorComponent,
    ImportacioDenunciesHistoricComponent,
    TableRevisioExecutivaOlotComponent,
    TableTramitarExecutivaComponent,
    TramitarDatesComponent,
    TableTramitarExecutivaOlotComponent,
    SelectorFechasNotificarComponent,
    ProgressBarComponent,
    InputProgressBarComponent,
    ResultadCargaComponentComponent,
    IncidenciesBancsTableComponent,
    AutomatitzacioComponent,
    MotiusNoNotificacioComponent,
    MotiusNoNotificacioTableComponent,
    MotiusNoNotificacioEditorComponent,
    MotiusNoNotificacioFilterComponent,
    CargaMatriculasComponent,
    EmptyRowComponent,
    ListadoNotificacionesComponent,
    ListadoErroresNotificacionesComponent,
    TipusEnviamentsComponent,
    TipusEnviamentsTableComponent,
    TipusEnviamentsEditorComponent,
    TipusEnviamentsFilterComponent,
    RespostesPageComponent,
    RespostesTableComponent,
    RespostesEditorComponent,
    RespostesFilterComponent,
    FileUploaderComponent,
    BonosResidentesTableComponent,
    BonosResidentsFilterComponent,
    GestioResidentsComponent,
    AutocompleteComboComponent,
    InforInfractorComponent,
    MatriculesAddComponent,
    AjudaPageComponent,
    ZonesAparcareComponent,
    ZonesAparcareTableComponent,
    ZonesAparcareEditorComponent,
    ZonesAparcareFilterComponent,
    ValoraAnulacionComponent,
    AutoLoginComponent,
    EstadisitcasAparcareComponent,
    EstadisticasDenunciasComponent,
    InforTitularComponent,
    CambioMatriculaComponent,
    ListadoResolucionesComponent,
    CobrosComponent,
    EnviosComponent,
    RespuestasComponent,
    EnviosTableComponent,
    EnvioDetailComponent,
    EnviosFilterComponent,
    EnvioPuntosDgtComponent,
    EnvioPuntosDgtTableComponent,
    EnvioPuntosDgtFilterComponent,
    ConfiguracionComponent,
    BOEGeneralComponent,
    EnvioBopComponent,
    EnvioBoePageComponent,
    EnvioBoeTableComponent,
    ConfirmacionComponent,
    ConfirmacionListadoComponent,
    ConfirmacionBoeFilterComponent,
    RowsDetalleConfirmacionComponent,
    DashTramitacionComponent,
    CompleteDashTramitacionComponent,
    PropostesAnulacioComponent,
    PropostesAnulacioTableComponent,
    PropostesAnulacioEditorComponent,
    PropostesAnulacioFilterComponent,
    PendentsListComponent,
    PendentsListPageComponent,
    DipositStockComponent,
    DipositStockTableComponent,
    ModalMultiSelectComponent,
    AlarmConfigurationComponent,
    DenunciaCobrarByC60Component,
    EstadisitcasAgenteComponent,
    TirarUsuarisLdapComponent,
    TramitadorConfigurationComponent,
    TriarC60ComponentComponent,
    DenunciaCobrarSmartPolComponent,
    TextosPredefinidosComponent,
    TextosPredefinidosTableComponent,
    TextosPredefinidosFilterComponent,
    TextosPredefinidosEditorComponent,
    PropostesViewerAutologinComponent,
    ImageDisplayerComponent,
    AcusosComponent,
    AcusosTableComponent,
    AcusosFilterComponent,
    ReportViewerComponent,
    ReportDisplayerComponent,
    IntroAlegacionesComponent,
    AlegacionsComponent,
    RespondreAlegacionsComponent,
    AlegacionsFilterComponent,
    SegundasAlegacionesComponent,
    InformarAcusosComponent,
    CargaMatriculasFilterComponent,
    EnvioPuntosDgtHistoricoComponent,
    ListadoNotificacionesDenunciaComponent,
    NotificacionesDenunciaFilterComponent,
    AlarmesHistoricComponent,
    GestionarComponent,
    ZbRutasComponent,
    ZbHorariosComponent,
    ZbHorariosTableComponent,
    ZbHorarioEditorComponent,
    ZbRutasTableComponent,
    ZbRutaEditorComponent,
    PlanificacioComponent,
    PlanificacioTableComponent,
    PlanificacioEditorComponent,
    IncidenciesComponent,
    IncidenciesFilterComponent,
    CobrosFiltreComponent,
    ActuacionsTableComponent,
    ActuacionsNewComponent,
    IncidenciesImagesComponent,
    ActuacionsAgentsComponent,
    ListadoResolucionesFilterComponent,
  ],
  imports: [
    NgMultiSelectDropDownModule.forRoot(),
    ColorPickerModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatSelectModule,
    MatRadioModule,
    MatGridListModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    MatPaginatorModule,
    FormsModule,
    ChartsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LayoutModule,
    FormsModule,
    AngularFontAwesomeModule,
    HttpClientModule,
    MatTableModule, MatPaginatorModule, MatSortModule, MatInputModule, MatProgressSpinnerModule, MatIconModule, MatDatepickerModule,
    MatNativeDateModule, MatTooltipModule, OwlDateTimeModule, OwlNativeDateTimeModule, MatTabsModule, MatStepperModule, MatCheckboxModule, MatDialogModule,
    MatSelectModule, MatButtonToggleModule, MatRadioModule, MatAutocompleteModule, MatChipsModule,
    MatTreeModule, MatBadgeModule, MatSliderModule, MatProgressBarModule, MatExpansionModule, MatStepperModule, MatInputModule, MatButtonModule,
    QuillModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 30000,
      extendedTimeOut: 30000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      closeButton: true,
      tapToDismiss: false
    }),
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyDUbRz0ajOp3YdLg9aZIIYqSrxc20mymDI', libraries: ['drawing'] }),
    TranslateModule.forRoot(),
    AppInjectorModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    TextosPredefinidosFilterService,
    TextosPredefindiosService,
    FiltersConfigurationService,
    CombosService,
    DesplacatsGruaService,
    DesplacatsGruaFilterService,
    SenseRetiradaGruaService,
    SenseRetiradaGruaFilterService,
    BonosService,
    BonosFilterService,
    AudioService,
    CobrosService,
    ObservacionsPdaService,
    ObservacionsPdaFilterService,
    GestioResidentsService,
    BonosResidentesFilterService,
    GoogleMapsService,
    {
      provide: APP_INITIALIZER,
      useFactory: agmConfigFactory,
      deps: [GoogleMapsService, LAZY_MAPS_API_CONFIG],
      multi: true
    },
    CargaMatriculasService,
    NotificarFilterService,
    TramitarPredefinidasService,
    MatIconRegistry,
    EscalaOcupacionService,
    LoginService,
    AuthGuardService,
    AuthService,
    ImagesService,
    DenunciesService,
    DenunciesFilterService,
    NotificacionsDenunciesFilterService,
    PropostesService,
    PropostesFilterService,
    MatDatepickerIntl,
    CurrentService,
    AuthorizationService,
    HttpSecureClient,
    ConfirmationDialogService,
    DropdownInstitucionsService,
    UsuarisService,
    StartupService,
    MatriculesService,
    MatriculaFilterService,
    MatriculaHistoricFilterService,
    TipusMatriculesService,
    TipusMatriculaFilterService,
    AgentsFilterService,
    AgentsService,
    TramitadorService,
    TramitadorHistoricService,
    ExpedienteService,
    ExpedienteFilterService,
    ExcelDownloadService,
    AparellService,
    LegislacioService,
    MotiusAnulacioService,
    MotiusDesestimarService,
    MarquesVehiclesFilterService,
    PaisosService,
    MarquesVehiclesService,
    // GoogleMapsService,
    TarifaFilterService,
    TarifaService,
    TiketService,
    TransformDataPipe,
    AnulacionZonaServiceAuto,
    AnulacionZonaServiceManual,
    LocalizacionService,
    LocalizacionFilterService,
    AparellTipusService,
    AparellTipusFilterService,
    ImportacioDenunciaService,
    ComunicacioPdaService,
    TemporadaService,
    TemporadaFilterService,
    InfraccioService,
    InfraccioFilterService,
    AliesGruaService,
    AliesGruaFilterService,
    FileBoxService,
    EstadistiquesService,
    AlarmesGpsService,
    TrackingService,
    AvisosConfService,
    AvisosService,
    TiquetsService,
    ReservesService,
    MapService,
    TiquetsDevolucionesService,
    TiquetsAnulacionesService,
    PersonesService,
    PersonesFilterService,
    FestiusService,
    TipusTiquetsService,
    VehiclesService,
    VehiclesFilterService,
    DgtService,
    SeguretatUsuarisService,
    SeguretatUsuarisFilterService,
    SeguretatGrupsService,
    SeguretatGrupsFilterService,
    ComprovacioTiquetsService,
    RecaudacioService,
    PhotoBoxService,
    NotificacionsService,
    DenunciesCobrarService,
    GruaPendentsService,
    GruaTecnicsService,
    GruaTecnicsFilterService,
    GruaMotiusDesestimarService,
    GruaMotiusDesestimarFilterService,
    GruaMotiusEntradaService,
    GruaMotiusEntradaFilterService,
    DipositGruaService,
    DipositGruaFilterService,
    VehiculoParserService,
    PersonaParserService,
    DateUtils,
    TarifesService,
    TarifesFilterService,
    FilesService,
    TurnosService,
    TurnosFilterService,
    TipoSenalesVerticalesFilterService,
    TipoSenalesVerticalesService,
    SenalesVerticalesService,
    EventEmitterService,
    TarifasGruaFilterService,
    TarifasGruaService,
    TramosTiposService,
    TramosTiposFilterService,
    TramosService,
    TramosFilterService,
    MaquinasFilterService,
    MaquinasService,
    TextoInformativoService,
    TextoAvisoService,
    GenericFileService,
    AuditoriaService,
    ReportService,
    BancCarreguesC60Service,
    AjudaService,
    BancCarreguesIncidenciesService,
    EnviosService,
    BoeService,
    EnviosFilterService,
    RespuestasService,
    ConfirmacionBoeFilterService,
    ConfirmacionDetalleBoeFilterService,
    { provide: LOCALE_ID, useValue: lang },
    {
      provide: TRANSLATIONS,
      useFactory: (locale) => {
        console.log(locale);
        locale = locale || 'ca'; // default if no locale provided
        lang = locale;
        return require(`raw-loader!assets/i18n/messages.${locale}.xlf`);
      },
      deps: [LOCALE_ID]
    },
    { provide: TRANSLATIONS_FORMAT, useValue: 'xlf' },
    { provide: MISSING_TRANSLATION_STRATEGY, useValue: MissingTranslationStrategy.Ignore },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true, direction: 'ltr' } },
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    I18n,
    CookieService,
    DashboardService,
    DatePipe,
    SeriePipe,
    SizePipe,
    MessagingService,
    NotificationService,
    InstitucionsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true
    },
    LoaderComponent,
    AlarmesService,
    ParametersService,
    SiglasService,
    TipusVehiclesService,
    ColorsService,
    CarrersService,
    AutomatitzacioService,
    MotiusNoNotificacioService,
    MotiusNoNotificacioFilterService,
    TipusEnviamentsService,
    TipusEnviamentsFilterService,
    RespostesService,
    RespostesFilterService,
    EnvioPuntosDGTService,
    EnvioPuntsDGTFilterService,
    NotificacionsPuntsDGTFilterService,
    ZonesAparcareService,
    ZonesAparcareFilterService,
    PropostesAnulacioService
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
  entryComponents: [
    ListadoResolucionesFilterComponent,
    PlanificacioEditorComponent,
    ZbRutaEditorComponent,
    ZbHorarioEditorComponent,
    NotificacionesDenunciaFilterComponent,
    TextosPredefinidosEditorComponent,
    TextosPredefinidosFilterComponent,
    TramitadorConfigurationComponent,
    TriarC60ComponentComponent,
    TirarUsuarisLdapComponent,
    DenunciaCobrarByC60Component,
    DenunciaCobrarSmartPolComponent,
    AlarmConfigurationComponent,
    DeltacarHistoricoFilterComponent,
    ModalMultiSelectComponent,
    MapComponent,
    PropostesAnulacioFilterComponent,
    PropostesAnulacioEditorComponent,
    DesplaçatsEditorComponent,
    DesplacatsFilterComponent,
    SenseRetiradaGruaFilterComponent,
    CartaPagoFechasComponent,
    ConfirmacionBoeFilterComponent,
    EnvioDetailComponent,
    EnviosFilterComponent,
    EnvioPuntosDgtFilterComponent,
    TwoFactorAuthenticationComponent,
    CambioMatriculaComponent,
    DenunciesTableComponent,
    ResultadCargaComponentComponent,
    ObservacionsPdaEditorComponent,
    ObservacionsPdaFilterComponent,
    BonosResidentsFilterComponent,
    FileUploaderComponent,
    ListadoErroresNotificacionesComponent,
    ListadoNotificacionesComponent,
    ListadoNotificacionesDenunciaComponent,

    ListadoResolucionesComponent,
    SelectorFechasNotificarComponent,
    TextoAvisoFilterComponent,
    GenericFileViewerDialogComponent,
    PropostesListComponent,
    PropostesFilterComponent,
    DenunciesEditorComponent,
    DenunciesImagesComponent,
    DenunciesNewComponent,
    NovaOperacioComponent,
    DenunciaCobrarComponent,
    DenunciaPagamentParcialComponent,
    ConfirmationDialogComponent,
    PhotoBoxComponent,
    PhotoBoxViewerComponent,
    ExpedientsComponent,
    DialogListComponent,
    CarrersComponent,
    CalleEditorComponent,
    CarrersFilterComponent,
    PropostesDesestimarComponent,
    CalendarMultiselectInputModalComponent,
    SingleImageViewerComponent,
    PermissionDialogComponent,
    PermissionEditorComponent,
    DashSquareComponent,
    DialogHeaderComponent,
    DialogBodyComponent,
    DialogFooterComponent,
    FilterHeaderComponent,
    SingleEditDialogComponent,
    WaitDialogComponent,
    MatriculesFilterComponent,
    MatriculesHistoricFilterComponent,
    TipusMatriculesFilterComponent,
    AgentsFilterComponent,
    AgentsComponent,
    ToolButtonComponent,
    ToolDropdownButtonComponent,
    MatriculesLoaderComponent,
    PropostesEditorComponent,
    PropostesLlocEditorComponent,
    DownloadExcelDialogComponent,
    AparellsFilterComponent,
    AparellsEditorComponent,
    LegislacionsFilterComponent,
    LegislacionsEditorComponent,
    MotiusAnulacioFilterComponent,
    MotiusAnulacioEditorComponent,
    PropostaViewerComponent,
    PropostaDialogComponent,
    MotiusDesestimarFilterComponent,
    MotiusDesestimarEditorComponent,
    AlarmHeaderMenuComponent,
    AlarmesFilterComponent,
    GalleryComponent,
    ReportViewerComponent,
    SiglasFilterComponent,
    SiglasEditorComponent,
    TipusVehiclesFilterComponent,
    TipusVehiclesEditorComponent,
    ColorsFilterComponent,
    ColorsEditorComponent,
    MarquesVehiclesFilterComponent,
    MarquesVehiclesEditorComponent,
    InfraccionsFilterComponent,
    InfraccionsEditorComponent,
    FileBoxComponent,
    FileViewerComponent,
    PaisosFilterComponent,
    PaisosEditorComponent,
    ZonesEditarComponent,
    ZonesTableComponent,
    ZonesComponent,
    ZonesFilterComponent,
    AlarmaDetallComponent,
    AvisosAddNodeComponent,
    AvisosFilterComponent,
    ChangePasswordComponent,
    ReportsComponent,
    TiquetsFilterComponent,
    ModelsVehiclesComponent,
    PersonesEditorComponent,
    PersonesTableComponent,
    PersonesComponent,
    PersonesFilterComponent,
    DomicilisEditorComponent,
    FestiusEditorComponent,
    FestiusFilterComponent,
    TipusTiquetsEditorComponent,
    TipusTiquetsFilterComponent,
    SeguretatUsuarisEditorComponent,
    SeguretatUsuarisFilterComponent,
    DisplayTextDialogComponent,
    VehiclesTableComponent,
    VehiclesComponent,
    VehiclesFilterComponent,
    VehiclesEditorComponent,
    XmlTreeViewerComponent,
    DgtFilterComponent,
    SeguretatGrupsEditorComponent,
    RecaudacioEditorComponent,
    RecaudacioFilterComponent,
    NotificacionsComponent,
    NotificacionsEditorComponent,
    NotificacionsFilterComponent,
    PendentsComponent,
    GruaTecnicsEditorComponent,
    GruaTecnicsFilterComponent,
    GruaMotiusDesestimarEditorComponent,
    GruaMotiusDesestimarFilterComponent,
    GruaMotiusEntradaEditorComponent,
    GruaMotiusEntradaFilterComponent,
    GruaAliesEditorComponent,
    GruaAliesFilterComponent,
    TramitadesFilterComponent,
    DipositFilterComponent,
    DipositComponent,
    DipositEditorComponent,
    RespostesComponent,
    NovaOperacioPropostesComponent,
    TarifesEditorComponent,
    TarifesFilterComponent,
    GridImagesComponent,
    PhotoBoxGruaComponent,
    DesperfectosEditorComponent,
    ReleasedNotesComponent,
    ReservesFilterComponent,
    TurnosEditorComponent,
    TurnosFilterComponent,
    IniciarTurnoComponent,
    FinalizarTurnoComponent,
    TarifasGruaEditorComponent,
    TarifasGruaFilterComponent,
    TramosTiposEditorComponent,
    TramosTiposFilterComponent,
    TramosEditorComponent,
    TramosFilterComponent,
    MaquinasEditorComponent,
    MaquinasFilterComponent,
    TipoSenalesVerticalesEditorComponent,
    TipoSenalesVerticalesFilterComponent,
    SenalesVerticalesEditorComponent,
    SenalesVerticalesFilterComponent,
    GenericFileDisplayerPageComponent,
    AuditoriaComponent,
    TemporadasEditorComponent,
    TemporadasFilterComponent,
    ClonarTemporadaComponent,
    QrViewerComponent,
    TramitarExpedientspageComponent,
    NotificarComponent,
    ExecutivaComponent,
    TancamentsComponent,
    TablependentsComponent,
    TabletancamentsComponent,
    BancCarreguesC60EditorComponent,
    BancCarreguesC60FilterComponent,
    BancCarreguesC60DetallTableComponent,
    C60FechaCobroComponent,
    DenunciaPagamentParcialComponent,
    TextoInformativoFilterComponent,
    TramitadesComponent,
    MotiusNoNotificacioEditorComponent,
    MotiusNoNotificacioFilterComponent,
    TableTramitarExecutivaOlotComponent,
    TableTramitarExecutivaComponent,
    TramitarDatesComponent,
    TipusEnviamentsEditorComponent,
    TipusEnviamentsFilterComponent,
    RespostesEditorComponent,
    RespostesFilterComponent,
    MatriculesAddComponent,
    ZonesAparcareEditorComponent,
    ZonesAparcareFilterComponent,
    ValoraAnulacionComponent,
    AcusosFilterComponent,
    AlegacionsFilterComponent,
    InformarAcusosComponent,
    CargaMatriculasFilterComponent,
    IncidenciesComponent,
    IncidenciesFilterComponent,
    CobrosFiltreComponent,
    ActuacionsNewComponent,
    IncidenciesImagesComponent,
  ],
  exports: [
    DatePipe,
    ShortDatePipe,
    MoneyPipe,
    NomPaisPipe,
    LongDatePipe,
    FormatDatePipe,
    SeriePipe,
    SizePipe,
    DialogDraggableDirective,
    TransformDataPipe
  ]
})
export class AppModule {

  constructor(public matIconRegistry: MatIconRegistry) {
    matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
  }
}
