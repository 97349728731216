import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { ZbRuta } from '../models/zb-ruta';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { ZbRutasService } from '../services/zb-rutas.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ZbRutasFilter } from './zb-rutas-filter';
import { ZbRutasFilterService } from './zb-rutas-filter-service';
import { ZbRutaEditorComponent } from '../zb-ruta-editor/zb-ruta-editor.component';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';

@Component({
  selector: 'app-zb-rutas-table',
  templateUrl: './zb-rutas-table.component.html',
  styleUrls: ['./zb-rutas-table.component.scss']
})
export class ZbRutasTableComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort:MatSort;

  dataSource: CustomDataSource<ZbRuta,ZbRutasFilter>;
  filter: ZbRutasFilter= new ZbRutasFilter('Nombre','asc');
  displayedColumns = ['Nombre','Descripcion','actions'];
  
  constructor(
    private dialog:MatDialog,
    private auth: AuthorizationService,
    private zbRutasService:ZbRutasService
  ) { }
  
  ngAfterViewInit():void{
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort,this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();

    this.load();
  }
  load():void{
    this.filter.paginator.set(this.sort,this.paginator);
    this.dataSource.loadFilter(this.filter);
  }
  ngOnInit() {
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<ZbRuta, ZbRutasFilter>(this.paginator, new ZbRutasFilterService(), 'urlGetZbRutas', 'urlGetZbRutasCount');
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        console.log(item);
      });
    });
    
    this.filter.paginator.init(this.sort, this.paginator);

  this.filter.filterUpdated.subscribe(updated => {
    if (updated) {
      this.load();
    }
  });
  }

  editZbRuta(row):void{
    debugger;
    const zbRuta: ZbRuta = row;
    //const data: ComponentDialog = Builder.newComponentDialog(true, false, zbRuta.SomId.toString());
    const dialogRef = this.dialog.open(ZbRutaEditorComponent, {
      width: '500px',
      data:zbRuta
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.load();
      }
    });
  }
}
