import { Component, OnInit, ViewChild } from '@angular/core';
import { PropostesService } from '../services/propostes.service';
import { PropostesComponent } from '../components/propostes.component';
import { AuthorizationService } from '../../../shared/services/authorization/authorization-service';
import {Router} from '@angular/router';
import { Observable } from 'rxjs/Observable';
import {LoginService} from '../../login/services/login.service';


@Component({
  selector: 'app-propostes-page',
  templateUrl: './propostes-page.component.html'
})

export class PropostesPageComponent implements OnInit {
  @ViewChild(PropostesComponent) propostes: PropostesComponent;

  constructor(private propostesService: PropostesService, private router: Router, private authService: AuthorizationService) {

 }

  ngOnInit() {
    this.authService.CheckUserPermission(this.authService.Pages.Propostes);
  }

}
