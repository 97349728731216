<mat-tab-group>
  <mat-tab label="Horarios"> 
    <ng-template matTabContent>
      <app-zb-horarios></app-zb-horarios>
    </ng-template>
  </mat-tab>
  <mat-tab label="RutasZB">
    <ng-template matTabContent>
      <app-zb-rutas></app-zb-rutas>
    </ng-template>
  </mat-tab>
  <mat-tab label="Planificacio"> 
    <ng-template matTabContent><app-planificacio></app-planificacio>
    </ng-template>
  </mat-tab>
</mat-tab-group>